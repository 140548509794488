import { ReactNode, Fragment } from 'react'

// Jest does not seem to accept ESM modules, so we need to use require here
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { I18n } from 'i18n-js/dist/require/I18n'
import translations from '../generated/translations.json'

const i18n = new I18n(translations)
i18n.locale = 'nl'

export function tWithComponent(
	key: string,
	components: ReactNode[],
	options?: any
): ReactNode {
	let asString = i18n.t(key, options)
	const asArray: (string | ReactNode)[] = []

	components.forEach((component, index) => {
		const splitted = asString.split('{' + index + '}')
		asArray.push(splitted[0])
		asArray.push(component)
		asString = splitted[1]
	})
	asArray.push(asString)

	return (
		<>
			{asArray.map((item, index) => (
				<Fragment key={index}>{item}</Fragment>
			))}
		</>
	)
}

export function t(key: string, options?: any): string {
	return i18n.t(key, options)
}

export function tContext(prefix: string) {
	return (key: string, options?: any): string => {
		return i18n.t(`${prefix}.${key}`, options)
	}
}

export function tAsList(key: string, options?: any): Record<string, string>[] {
	const translation = i18n.t(key, options)

	if (typeof translation === 'string') {
		return []
	}

	return Object.values(translation) as Record<string, string>[]
}
