import { AvailableStep } from './stepUtil'
import { t } from './cmsTranslations'

export const getFaqUrlByStep = (step: AvailableStep) => {
	let url
	switch (step) {
		case AvailableStep.registrationStart:
		case AvailableStep.registrationEmail:
		case AvailableStep.registrationValidateEmail:
		case AvailableStep.registrationPhoneNumber:
		case AvailableStep.registrationValidatePhoneNumber:
		case AvailableStep.registrationExtension:
		case AvailableStep.registrationAgreement:
			url = t('registration.faq.url')
			break

		case AvailableStep.energyIntro:
		case AvailableStep.energyData:
			url = t('energy.faq.url')
			break

		case AvailableStep.householdIntro:
		case AvailableStep.inputHousehold:
			url = t('household.faq.url')
			break

		case AvailableStep.incomeIntro:
		case AvailableStep.people:
			url = t('income.faq.url')
			break

		case AvailableStep.finish:
		case AvailableStep.overview:
			url = t('overview.faq.url')
			break
	}

	if (url?.startsWith('[missing')) {
		console.log('Missing FAQ URL for step', step)
		url = null
	}

	if (url === null) {
		url = t('home.faq.url')
	}

	if (url?.startsWith('[missing')) {
		url = null
	}

	return url
}

export const getFaqButtonByStep = (step: AvailableStep) => {
	let url
	switch (step) {
		case AvailableStep.registrationStart:
		case AvailableStep.registrationEmail:
		case AvailableStep.registrationValidateEmail:
		case AvailableStep.registrationPhoneNumber:
		case AvailableStep.registrationValidatePhoneNumber:
		case AvailableStep.registrationExtension:
		case AvailableStep.registrationAgreement:
			url = t('registration.faq.buttonButton.title')
			break

		case AvailableStep.energyIntro:
		case AvailableStep.energyData:
			url = t('energy.faq.buttonButton.title')
			break

		case AvailableStep.householdIntro:
		case AvailableStep.inputHousehold:
			url = t('household.faq.buttonButton.title')
			break

		case AvailableStep.incomeIntro:
		case AvailableStep.people:
			url = t('income.faq.buttonButton.title')
			break

		case AvailableStep.finish:
		case AvailableStep.overview:
			url = t('overview.faq.buttonButton.title')
			break
	}

	if (url?.startsWith('[missing')) {
		console.log('Missing FAQ Button for step', step)
		url = null
	}

	if (url === null) {
		url = t('home.faq.buttonButton.title')
	}

	if (url?.startsWith('[missing')) {
		url = null
	}

	return url
}
