import React, { forwardRef } from 'react'
import clsx from 'clsx'

type Props = {
	title: string
	icon?: React.ReactNode
	iconPosition?: 'left' | 'right'
	variant?: 'default' | 'small'
	classNameOverride?: string
	disabled?: boolean
	onClick?: () => void
	id?: string
	buttonProps?: any
}

const SecondaryButton = forwardRef<HTMLButtonElement, Props>(
	(
		{
			title,
			icon,
			iconPosition = 'left',
			variant = 'default',
			classNameOverride,
			disabled,
			onClick,
			id,
			buttonProps,
		},
		ref
	) => (
		<button
			ref={ref}
			id={id}
			type="button"
			disabled={disabled}
			onClick={onClick}
			className={clsx(
				'bg-transparent px-2 font-semibold flex items-center justify-center',
				'focus:outline-none focus:ring-4 focus:ring-gray-950 focus:ring-offset-white',
				{
					'text-gray-950 hover:text-indigo-gray-900 active:text-indigo-gray-950':
						!classNameOverride?.includes('text-'),
					'rounded-[1rem] py-3 text-[18px] leading-8': variant === 'default',
					'rounded-xl py-1 text-sm leading-6 text-left': variant === 'small',
				},
				classNameOverride
			)}
			{...buttonProps}
		>
			{iconPosition === 'left' && icon && (
				<div className="mr-[13px]" aria-hidden="true">
					{icon}
				</div>
			)}

			{title}

			{iconPosition === 'right' && icon && (
				<div className="ml-[13px]" aria-hidden="true">
					{icon}
				</div>
			)}
		</button>
	)
)

SecondaryButton.displayName = 'SecondaryButton'

export { SecondaryButton }
