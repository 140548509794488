import { FC } from 'react'

type RadioButtonProps = {
	text: string
	id: string
	name: string
	value: string
	checked: boolean
	onChange: (value: string) => void
}

export const RadioButton: FC<RadioButtonProps> = ({
	text,
	id,
	name,
	value,
	checked,
	onChange,
}) => {
	const size = 'w-[25px] h-[25px]'
	const color =
		'checked:bg-indigo-blue-800 checked:hover:bg-indigo-blue-800 checked:active:bg-indigo-blue-800 checked:focus:bg-indigo-blue-800 focus:outline-none focus:ring-1 focus:ring-indigo-blue-800'

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		onChange(e.target.value)
	}

	return (
		<div className="grid grid-cols-[22px_1fr] gap-4 py-2">
			<input
				type="radio"
				name={name}
				value={value}
				id={id}
				checked={checked}
				onChange={handleChange}
				className={`${size} ${color}`}
			/>
			<label htmlFor={id} className="font-semibold">
				{text}
			</label>
		</div>
	)
}

export default RadioButton
