import { InferType, object, string } from 'yup'
import { ChevronRightIcon } from '../../assets/icons'
import { Button } from '../../components'
import { TextInputControlled } from '../../components/text-input/TextInputControlled'
import { IncomeUI } from '../../types/api'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { tContext, t as tFull } from '../../utils/cmsTranslations'
import { TableRow } from '../../components/info-table/TableRow'
import {
	formatAmount,
	formatDate,
	formatAsLongDate,
} from '../../utils/formatDate'
import {
	renderCurrentIncome,
	renderSivIncomes,
} from '../../components/info-table/UserInfoTable'
import { isSupportedCurrency } from '../../utils/currencyUtil'

const t = tContext('income')

type Props = {
	viewModel: IncomeUI
	onSave: (manualIncome: string) => void
}

const addIncomeSchema = object({
	manualIncome: string()
		.required(tFull('validation.validationGenericRequired'))
		.test(
			'isPositiveNumber',
			'Heb je een negatief inkomen? Vul dan 0 in.',
			(val) => !String(val).includes('-') && Number(parseInt(val)) > -1
		)
		.test(
			'isValidCurrency',
			'Alleen cijfers en optioneel tot maximaal twee cijfers achter de komma worden toegestaan in dit veld.',
			(val) => isSupportedCurrency(String(val))
		),
})

type FormValues = InferType<typeof addIncomeSchema>

export const AddIncomeForm = ({ viewModel, onSave }: Props) => {
	const { handleSubmit, register, formState } = useForm<FormValues>({
		resolver: yupResolver(addIncomeSchema),
		defaultValues: {
			manualIncome: '',
		},
	})

	const onSubmit = ({ manualIncome }: FormValues) => {
		onSave(manualIncome)
	}

	const {
		birthDate,
		grossIncome,
		grossIncomeYear,
		sivIncomes,
		currentIncomes,
	} = viewModel

	return (
		<form
			onSubmit={handleSubmit(onSubmit, console.log)}
			className="w-full mt-8"
		>
			<p className="font-semibold text-[20px] my-4">
				{t('check.sectionGrossIncome.title')}
			</p>

			<div className="mb-4">
				<TextInputControlled
					title={t('check.sectionGrossIncome.inputGrossIncome.label')}
					placeholder={t(
						'check.sectionGrossIncome.inputGrossIncome.placeholder'
					)}
					isMoney
					{...register('manualIncome')}
					hint={formState.errors.manualIncome?.message}
				/>
			</div>

			{currentIncomes && (
				<TableRow
					title={t('check.sectionCurrentIncome.title')}
					showBorder
					showTopBorder
				>
					{renderCurrentIncome(currentIncomes)}
				</TableRow>
			)}

			{sivIncomes && sivIncomes.length > 0 && (
				<TableRow title={t('check.sectionIncomeSources.title')} showBorder>
					{renderSivIncomes(sivIncomes)}
				</TableRow>
			)}

			{grossIncome && grossIncomeYear && (
				<TableRow
					title={`Brutoloon ${grossIncomeYear}`}
					data={formatAmount(Number(grossIncome))}
					showBorder
				/>
			)}

			<TableRow
				title={t('check.sectionDateOfBirth.title')}
				data={
					birthDate ? (
						<span>
							<span className="sr-only">
								{formatAsLongDate(new Date(birthDate))}
							</span>
							<span aria-hidden="true">
								{formatDate(new Date(birthDate)).toString()}
							</span>
						</span>
					) : (
						'-'
					)
				}
				showBorder
			/>

			<Button
				classNameOverride="mt-8"
				title={t('check.buttonConfirm.title')}
				icon={<ChevronRightIcon />}
				buttonProps={{ type: 'submit' }}
			/>
		</form>
	)
}
