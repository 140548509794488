import { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../context/app-context'

import { SinglePerson } from '../../pageComponents/Review/SinglePerson'
import { MultiplePeople } from '../../pageComponents/Review/MultiplePeople'
import RedirectFirstPage from '../../routes/RedirectFirstPage'
// import { checkAqopiAvailability, configs, machtigingenConfigs } from '../../utils/extension'

enum ViewType {
	SINGLE,
	MULTI,
}

export default function ReviewPage() {
	const { householdData } = useContext(AppContext)
	const [viewType, setViewType] = useState<ViewType>(ViewType.SINGLE)

	useEffect(() => {
		const people = householdData.personen
		setViewType(people && people.length > 1 ? ViewType.MULTI : ViewType.SINGLE)
	}, [])

	// useEffect(() => {
	// 	checkAqopiAvailability(isGemachtigd ? machtigingenConfigs : configs)
	// 		.then(setIsFailed)
	// }, [])

	const views: Record<ViewType, JSX.Element> = {
		[ViewType.SINGLE]: <SinglePerson />,
		[ViewType.MULTI]: <MultiplePeople />,
	}

	return (
		<RedirectFirstPage>
			<div className="w-full">{views[viewType]}</div>
		</RedirectFirstPage>
	)
}
