import { tContext } from './cmsTranslations'

const t = tContext('validation')

// this should be the only place we maintain the names of the providers (compared to the ones sent by the endpoint)
export const NAME_BUDGET_THUIS = 'Budget Thuis'
export const NAME_BUDGET_ENERGIE = 'Budget Energie'
export const NAME_VATTENFALL = 'Vattenfall'
export const NAME_GREENCHOICE = 'Greenchoice'
export const NAME_ENGIE = 'ENGIE'
export const NAME_ESSENT = 'Essent'
export const NAME_ENERGIE_DIRECT = 'Energiedirect'
export const NAME_SEPA_GREEN = 'SEPA Green Energy'
export const NAME_INNOVA_ENERGY = 'Innova Energie'
export const NAME_GEWOON_ENERGIE = 'Gewoon energie'
export const NAMES_ENECO_VARIANTS = ['Eneco', 'Oxxio', 'WoonEnergie']

export type ValidationMethod = (value: string) => boolean
export type ValidationResult = (
	value: string,
	provider: string
) => boolean | string

const hintTranslationKeys = {
	eneco: 'validationCustomerNumberEnecoInvalid',
	[NAME_VATTENFALL]: 'validationCustomerNumberVattenfallInvalid',
	[NAME_GREENCHOICE]: 'validationCustomerNumberGreenChoiceInvalid',
	[NAME_BUDGET_THUIS]: 'validationCustomerNumberBudgetInvalid',
	[NAME_BUDGET_ENERGIE]: 'validationCustomerNumberBudgetInvalid',
	[NAME_ENGIE]: 'validationCustomerNumberEngieInvalid',
	[NAME_ESSENT]: 'validationCustomerNumberEssentInvalid',
	[NAME_ENERGIE_DIRECT]: 'validationCustomerNumberEnergiedirectInvalid',
	[NAME_SEPA_GREEN]: 'validationCustomerNumberSepaGreenEnergyInvalid',
	[NAME_INNOVA_ENERGY]: 'validationCustomerNumberInnovaEnergieInvalid',
	[NAME_GEWOON_ENERGIE]: 'validationCustomerNumberGewoonEnergieInvalid',
}

export const checkCustomerNumber = (
	providers: Record<string, string>,
	provider: string,
	customerNumber: string
): boolean | string => {
	const providerCode = Object.keys(providers).find(
		(code) =>
			providers[code].toUpperCase().trim() === provider?.toUpperCase().trim()
	)

	if (!providerCode) return true

	if (NAMES_ENECO_VARIANTS.includes(providerCode)) {
		return checkEnecoVariant(provider, customerNumber)
	}

	if (Object.keys(supplierChecks).some((x) => x === providerCode)) {
		return supplierChecks[providerCode](customerNumber, provider)
	}

	return checkGenericSanity(customerNumber, provider)
}

const DEFAULT_PLACEHOLDER = '0000 0000 0000'

export const getPlaceholderForProvider = (
	providers: string[],
	provider: string,
	customerNumber: string
): string => {
	const matchingProviderName = providers.find((code) => code === provider)

	if (!matchingProviderName) return DEFAULT_PLACEHOLDER

	if (
		NAMES_ENECO_VARIANTS.includes(matchingProviderName) &&
		checkEnecoVariant(provider, customerNumber)
	) {
		return '000000 00'
	} else if (matchingProviderName === NAME_VATTENFALL) {
		return '10000000'
	} else if (matchingProviderName === NAME_GREENCHOICE) {
		return '0000'
	} else if (matchingProviderName === NAME_BUDGET_THUIS) {
		return '3000000'
	} else if (matchingProviderName.trim() === NAME_ENGIE) {
		return 'K00000000'
	}

	return DEFAULT_PLACEHOLDER
}

/**
 * Gets the hint text for a provider, optionally changing the supplier.
 * @param provider The provider to look up
 * @param language Which language to look up
 * @param supplier The supplier
 * @returns
 */
export const getProviderHint = (
	provider: string,
	supplier?: string
): string => {
	const hintTranslationKey = (hintTranslationKeys as Record<string, string>)[
		provider
	]

	if (!supplier) supplier = provider

	return hintTranslationKey
		? t(hintTranslationKey, { supplier })
		: t('validationCustomerNumberRequired', { supplier })
}

const patternCheck =
	(pattern: RegExp): ValidationMethod =>
	(value: string) =>
		pattern.test(value)

const rangeCheck =
	(min: number, max: number): ValidationMethod =>
	(value: string) =>
		parseInt(value, 10) >= min && parseInt(value, 10) <= max

const higherThanCheck =
	(min: number): ValidationMethod =>
	(value: string) =>
		parseInt(value, 10) >= min

const maxLengthCheck =
	(length: number): ValidationMethod =>
	(value: string) =>
		value.length <= length

const or =
	(...checks: ValidationMethod[]): ValidationMethod =>
	(value) =>
		checks.some((check) => check(value))

const and =
	(...checks: ValidationMethod[]): ValidationMethod =>
	(value) =>
		checks.every((check) => check(value))

const resultOrHint =
	(
		method: ValidationMethod,
		providerHint: (provider: string) => string = getProviderHint
	): ValidationResult =>
	(value, provider) =>
		method(value) || providerHint(provider)

// Applicable to the list of NAMES_ENECO_VARIANTS
export const checkEnecoVariant = resultOrHint(
	patternCheck(/^\d{5,8}(?:-\d{1,2})?$/),
	(provider: string) => getProviderHint('eneco', provider)
)

export const checkVattenfall = resultOrHint(patternCheck(/^[12]\d{7}$/))

export const checkGreenchoice = resultOrHint(patternCheck(/^\d{4,7}$/))

export const checkBudgetThuis = resultOrHint(
	and(patternCheck(/^[3-9]\d{6}$/), higherThanCheck(3129877))
)

export const checkEngie = resultOrHint(patternCheck(/^K0\d{7}$/))

export const checkEssent = resultOrHint(patternCheck(/^(?!013)[01]\d{9}$/))

export const checkEnergieDirect = resultOrHint(patternCheck(/^0?13\d{7}$/))

export const checkGenericSanity = resultOrHint(
	patternCheck(/^([A-Za-z0-9 _\/-]+)$/),
	() => t('validationCustomerNumberRequired')
)

export const checkSepaGreenEnergy = resultOrHint(
	or(
		and(rangeCheck(1012923, 1023861), maxLengthCheck(7)),
		and(higherThanCheck(1100000100), maxLengthCheck(10))
	)
)

export const checkInnovaEnergy = resultOrHint(
	or(
		and(rangeCheck(100065, 152746), maxLengthCheck(6)),
		and(rangeCheck(1012922, 2451182), maxLengthCheck(7)),
		and(higherThanCheck(1500003914), maxLengthCheck(10))
	)
)

export const checkGewoonEnergie = resultOrHint(
	or(
		and(rangeCheck(7000001, 7025462), maxLengthCheck(7)),
		and(higherThanCheck(1500007086), maxLengthCheck(10))
	)
)

export const supplierChecks: Record<string, ValidationResult> = {
	[NAME_VATTENFALL]: checkVattenfall,
	[NAME_BUDGET_THUIS]: checkBudgetThuis,
	[NAME_BUDGET_ENERGIE]: checkBudgetThuis,
	[NAME_ENGIE]: checkEngie,
	[NAME_GREENCHOICE]: checkGreenchoice,
	[NAME_ESSENT]: checkEssent,
	[NAME_ENERGIE_DIRECT]: checkEnergieDirect,
	[NAME_INNOVA_ENERGY]: checkInnovaEnergy,
	[NAME_GEWOON_ENERGIE]: checkGewoonEnergie,
	[NAME_SEPA_GREEN]: checkSepaGreenEnergy,
}
