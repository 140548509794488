import { useEffect, useMemo, useState } from 'react'

export function useStatusMessage(): { blockingStatusMessage: string | null } {
	const [blockingStatusMessage, setBlockinStatusMessage] = useState<
		string | null
	>(null)

	const handleStatusMessage = async () => {
		try {
			const fetchResult = await fetch(
				'https://s3.us-east-2.amazonaws.com/iwize.nl/noodfonds/status-message.json?' +
					Date.now()
			)
			const data = await fetchResult.json()

			if (!data.webapp.showMessage) {
				return
			}

			if (data.webapp.isBlocking !== true) {
				alert(data.webapp.message)
			} else {
				setBlockinStatusMessage(data.webapp.message)
			}
		} catch (e) {
			console.error('Could not handle status message: ', e)
		}
	}

	useEffect(() => {
		handleStatusMessage()
	}, [])

	return useMemo<ReturnType<typeof useStatusMessage>>(
		() => ({ blockingStatusMessage }),
		[blockingStatusMessage]
	)
}
