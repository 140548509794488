import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { AppContextProvider } from '../context/app-context'
import { LayoutContextProvider } from '../context/theme-context'
import { MainLayout } from '../layout/main/MainLayout'
import {
	EnergyDataPage,
	EnergyIntroPage,
	ExtensionAndAgreementPage,
	HouseholdIntroPage,
	IncomeIntroPage,
	InputPage,
	NotAvailablePage,
	StartPage,
	ResultPage,
	ReviewPage,
	FirstPage,
} from '../pages'
import DecisionPage from '../pages/result/Decision'
import { ChoosePeoplePage } from '../pages/household/ChoosePeople'
import PageWrapper from '../components/PageWrapper/PageWrapper'
import { getRoute } from '../utils/routeUtils'
import { AvailableStep } from '../utils/stepUtil'

const EnergyAppWrapper = ({
	children,
}: {
	children: React.ReactNode
}): JSX.Element => {
	return (
		<LayoutContextProvider>
			<AppContextProvider>
				<MainLayout>
					<PageWrapper>{children}</PageWrapper>
				</MainLayout>
			</AppContextProvider>
		</LayoutContextProvider>
	)
}

const WrapApp = ({
	component: Component,
}: {
	component: React.FC
}): JSX.Element => (
	<EnergyAppWrapper>
		<Component />
	</EnergyAppWrapper>
)

export const AppRoutes: React.FC = () => (
	<Routes>
		<Route
			path={getRoute(AvailableStep.registrationStart)}
			element={<WrapApp component={StartPage} />}
		/>
		<Route
			path={getRoute(AvailableStep.registrationExtension)}
			element={<WrapApp component={ExtensionAndAgreementPage} />}
		/>
		<Route
			path={getRoute(AvailableStep.incomeIntro)}
			element={<WrapApp component={IncomeIntroPage} />}
		/>
		<Route
			path={getRoute(AvailableStep.householdIntro)}
			element={<WrapApp component={HouseholdIntroPage} />}
		/>
		<Route
			path={getRoute(AvailableStep.inputHousehold)}
			element={<WrapApp component={InputPage} />}
		/>
		<Route
			path={getRoute(AvailableStep.energyIntro)}
			element={<WrapApp component={EnergyIntroPage} />}
		/>
		<Route
			path={getRoute(AvailableStep.people)}
			element={<WrapApp component={ChoosePeoplePage} />}
		/>
		<Route
			path={getRoute(AvailableStep.review)}
			element={<WrapApp component={ReviewPage} />}
		/>
		<Route
			path={getRoute(AvailableStep.energyData)}
			element={<WrapApp component={EnergyDataPage} />}
		/>
		<Route
			path={getRoute(AvailableStep.overview)}
			element={<WrapApp component={ResultPage} />}
		/>
		<Route
			path={getRoute(AvailableStep.finish)}
			element={<WrapApp component={DecisionPage} />}
		/>
		<Route
			path="/not-available"
			element={<WrapApp component={NotAvailablePage} />}
		/>
		<Route path="*" element={<WrapApp component={FirstPage} />} />
	</Routes>
)
