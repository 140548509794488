import React from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { string, object, ObjectSchema } from 'yup'

import { Button, Title, InfoBoxText } from '../../components'
import { TextInputControlled } from '../../components/text-input/TextInputControlled'
import { useTitle } from '../../hooks/useTitle'
import { tContext } from '../../utils/cmsTranslations'
import { ChevronRightIcon } from '../../assets/icons'
import { AvailableStep, useSetActiveStep } from '../../utils/stepUtil'

const t = tContext('registration.authorizedExtraDetails')
const tValidation = tContext('validation')

interface Props {
	onSubmit: (name: string, email: string, phoneNumber: string) => void
}

interface FormValues {
	name: string
	email: string
	phoneNumber: string
}

const extraDetailsSchema: ObjectSchema<FormValues> = object({
	name: string().required(tValidation('validationGenericRequired')),
	email: string().required(tValidation('validationGenericRequired')),
	phoneNumber: string().required(tValidation('validationGenericRequired')),
})

export const Substep_2_B: React.FC<Props> = ({ onSubmit }) => {
	useTitle('Extra details')
	useSetActiveStep(AvailableStep.registrationExtraDetails)

	const { handleSubmit, formState, register } = useForm<FormValues>({
		resolver: yupResolver(extraDetailsSchema),
	})

	const onSubmitInternal = async (fields: FormValues) => {
		const { name, email, phoneNumber } = fields

		onSubmit(name, email, phoneNumber)
	}

	return (
		<form
			className="flex flex-col items-start w-full h-full"
			onSubmit={handleSubmit(onSubmitInternal)}
		>
			<Title classNameOverride="mb-6">{t('title')}</Title>

			{formState.errors.root?.type === 'serverError' && (
				<InfoBoxText
					messageType="error"
					text={formState.errors.root.message}
					classNameOverride="max-w-xl mb-8"
				/>
			)}

			<p className="text-text mb-4">
				Het huishouden waar je een aanvraag voor doet, krijgt een e-mail van
				ons. Ze worden op de hoogte gesteld van het feit dat je een aanvraag
				doet via DigiD machtiging. Ze kunnen de aanvraag op elk gewenst moment
				afbreken als ze dat willen.
			</p>

			<div className="w-full my-4">
				<TextInputControlled
					{...register('name')}
					aria-required={true}
					title={t('inputName.label')}
					placeholder={t('inputName.placeholder')}
					autoComplete="off"
					hint={
						formState.errors.name ? formState.errors.name.message : undefined
					}
					hintVariant={'error'}
				/>
			</div>

			<div className="w-full my-4">
				<TextInputControlled
					{...register('email')}
					aria-required={true}
					title={t('inputEmail.label')}
					placeholder={t('inputEmail.placeholder')}
					autoComplete="off"
					hint={
						formState.errors.email ? formState.errors.email.message : undefined
					}
					hintVariant={'error'}
				/>
			</div>

			<div className="w-full my-4">
				<TextInputControlled
					{...register('phoneNumber')}
					aria-required={true}
					title={t('inputPhoneNumber.label')}
					placeholder={t('inputPhoneNumber.placeholder')}
					autoComplete="off"
					hint={
						formState.errors.phoneNumber
							? formState.errors.phoneNumber.message
							: undefined
					}
					hintVariant={'error'}
				/>
			</div>

			<Button
				data-testid="submit-button"
				title={t('buttonSubmit.title')}
				buttonProps={{ type: 'submit' }}
				classNameOverride="mt-6"
				icon={<ChevronRightIcon />}
			/>
		</form>
	)
}
