import { Dialog as HeadlessDialog } from '@headlessui/react'
import { Dispatch, ReactNode, SetStateAction } from 'react'
import { CrossIcon } from '../../assets/icons'
import { useFocusRef } from '../../hooks/useFocusRef'

interface Props {
	open: boolean
	setOpen: Dispatch<SetStateAction<any>>
	children: ReactNode
	title: string
	description?: string
	disableAutoClose?: boolean
	showCloseButton?: boolean
}

function Dialog({
	open,
	setOpen,
	children,
	title,
	description,
	disableAutoClose = false,
	showCloseButton = false,
}: Props) {
	const titleRef = useFocusRef()

	return (
		<HeadlessDialog
			open={open}
			onClose={disableAutoClose ? () => null : () => setOpen(false)}
			className="fixed z-10 inset-0"
			aria-hidden="false"
			aria-modal="true"
		>
			<HeadlessDialog.Overlay />
			<div className="fixed inset-0 bg-modal-backdrop pointer-events-none" />

			<div className="flex items-center justify-center py-24 px-36">
				<div className="relative bg-white w-full max-w-4xl mx-auto p-8 max-h-[80vh] overflow-y-auto rounded-2xl">
					<div className="w-full flex flex-row items-center ">
						{showCloseButton && (
							<div className="-mb-2 order-2">
								<button
									aria-label="Sluit dialoog"
									onClick={() => setOpen(false)}
								>
									<CrossIcon className="cursor-pointer" />
								</button>
							</div>
						)}

						<HeadlessDialog.Title
							tabIndex={0}
							ref={titleRef}
							className="font-semibold text-text text-[28px] order-1 flex-1"
						>
							{title}
						</HeadlessDialog.Title>
					</div>

					{description && (
						<HeadlessDialog.Description>
							<span className="text-xl font-normal">{description}</span>
						</HeadlessDialog.Description>
					)}

					<div className="mt-4">{children}</div>
				</div>
			</div>
		</HeadlessDialog>
	)
}

export default Dialog
