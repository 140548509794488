import React from 'react'

type Props = React.SVGProps<SVGSVGElement> & { color?: string }

export const ChevronDownIcon: React.FC<Props> = ({ color, ...props }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		fill="none"
		{...props}
	>
		<path
			fill={color ?? '#323439'}
			fillRule="evenodd"
			d="M5.293 8.293a1 1 0 0 1 1.414 0L12 13.586l5.293-5.293a1 1 0 1 1 1.414 1.414l-6 6a1 1 0 0 1-1.414 0l-6-6a1 1 0 0 1 0-1.414Z"
			clipRule="evenodd"
		/>
	</svg>
)
