import React from 'react'
import clsx from 'clsx'

type Props = {
	line1: string
	line2: string
	accessibilityLabel: string
	icon: React.ReactNode
	classNameOverride?: string
	onClick?: () => void
	disabled?: boolean
}

export const MultilineButton: React.FC<Props> = ({
	line1,
	line2,
	accessibilityLabel,
	icon,
	classNameOverride,
	onClick,
	disabled,
}) => {
	return (
		<button
			onClick={onClick}
			className={clsx(
				'flex gap-3 flex-row rounded-[0.75rem] w-[340px] text-gray-900 items-center justify-start py-3 px-4 bg-white',
				{
					'opacity-50 bg-gray-200': disabled,
					'border-[1px] border-gray-500': !disabled,
				},
				classNameOverride
			)}
			disabled={disabled}
		>
			<div
				className="flex items-center shrink-0 justify-center rounded-full bg-indigo-blue-800 w-[48px] h-[48px]"
				aria-hidden="true"
			>
				{icon}
			</div>

			<div className="flex flex-col items-start justify-center">
				<span className="sr-only">{accessibilityLabel}</span>
				<p
					aria-hidden="true"
					className={clsx('text-base font-semibold', {
						'text-gray-700': disabled,
						'text-gray-950': !disabled,
					})}
				>
					{line1}
				</p>
				<p
					aria-hidden="true"
					className={clsx('text-[14px] font-semibold', {
						'text-gray-700': disabled,
						'text-indigo-blue-800': !disabled,
					})}
				>
					{line2} <span>&gt;</span>
				</p>
			</div>
		</button>
	)
}
