import { useSearchParams } from 'react-router-dom'
import Success from '../../components/success/Success'
import Fail from '../../components/fail/Fail'
import Undecided from '../../components/undecided/Undecided'
import { ApplicationStatus } from '../../types/api'
import { useTitle } from '../../hooks/useTitle'
import { useDisableBackButton } from '../../hooks/useDisableBackButton'
import { AvailableStep, useSetActiveStep } from '../../utils/stepUtil'
import { CURRENT_PERIOD } from '../../pageComponents/EnergyDataForm/helpers'

export default function DecisionPage() {
	useTitle('Resultaat')
	useDisableBackButton()
	useSetActiveStep(AvailableStep.finish)

	const [params] = useSearchParams()
	const status = params.get(CURRENT_PERIOD) as ApplicationStatus

	if (status === ApplicationStatus.GOEDGEKEURD) {
		return <Success />
	}

	if (status === ApplicationStatus.AFGEKEURD) {
		return <Fail />
	}

	if (status === ApplicationStatus.ONBEKEND) {
		return <Undecided />
	}

	return <Fail />
}
