import { useContext } from 'react'
import StepExtension from '../../pageComponents/Registration/StepExtension'
import StepAgreement from '../../pageComponents/Registration/StepAgreement'
import RedirectFirstPage from '../../routes/RedirectFirstPage'
import { AppContext } from '../../context/app-context'
import { AvailableStep, useSetActiveStep } from '../../utils/stepUtil'

export default function ExtensionAndAgreementPage() {
	useSetActiveStep(AvailableStep.registrationExtension)
	const { activeStep } = useContext(AppContext)

	return (
		<RedirectFirstPage>
			<div className="flex items-center justify-center w-full h-full">
				<div className="flex flex-col items-center w-full h-full">
					{activeStep === AvailableStep.registrationExtension && (
						<StepExtension />
					)}
					{activeStep === AvailableStep.registrationAgreement && (
						<StepAgreement />
					)}
				</div>
			</div>
		</RedirectFirstPage>
	)
}
