import { Dispatch, SetStateAction } from 'react'
import AgreementContent from './AgreementContent'
import Dialog from '../modal/Dialog'

interface Props {
	open: boolean
	setOpen: Dispatch<SetStateAction<boolean>>
	onAccepted: () => void
	onCancel?: () => void
}

function AgreementModal({ open, setOpen, onAccepted, onCancel }: Props) {
	return (
		<Dialog
			title="Overeenkomst Noodfonds Energie"
			open={open}
			setOpen={setOpen}
			showCloseButton
		>
			<AgreementContent
				onAccept={onAccepted}
				onCancel={() => {
					setOpen(false)
					if (onCancel) onCancel()
				}}
			/>
		</Dialog>
	)
}

export default AgreementModal
