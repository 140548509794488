import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { string, object, ObjectSchema } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { ChevronRightIcon, SyncIcon } from '../../assets/icons'
import {
	Button,
	Title,
	Notice,
	TextLight,
	SecondaryButton,
} from '../../components'
import { useTitle } from '../../hooks/useTitle'
import { tAsList, tContext, t as tAll } from '../../utils/cmsTranslations'
import { TextInputControlled } from '../../components/text-input/TextInputControlled'
import { AvailableStep, useSetActiveStep } from '../../utils/stepUtil'
import { numbersOnlyRegex } from '../../utils/regexpHelpers'
import { IS_DEVELOPMENT_ENV } from '../../utils/utils'

const t = tContext('registration.phoneNumberVerification')
const tValidation = tContext('validation')

type Props = {
	onSubmit: (code: string) => Promise<any>
	onRetry: () => void
}

type FormValues = {
	code: string
}

const smsCodeSchema: ObjectSchema<FormValues> = object({
	code: string()
		.required(tValidation('validationCodeRequired'))
		.test(
			'len',
			tValidation('validationCodeLength'),
			(val) => val.toString().length === 6
		)
		.test('isNumber', tValidation('validationCodeInvalid'), (val) =>
			numbersOnlyRegex.test(val)
		),
})

const RetryArea: React.FC<{ onClick: () => void }> = ({ onClick }) => {
	const MAX_SECONDS = 60
	const [startTime, setStartTime] = useState(Date.now())
	const [secondsPassed, secondsPassedSet] = useState(0)

	useEffect(() => {
		const intervalId = setInterval(() => {
			const timePassed = Date.now() - startTime
			secondsPassedSet(Math.min(MAX_SECONDS, Math.floor(timePassed / 1000)))

			if (timePassed > MAX_SECONDS * 1000) {
				clearInterval(intervalId)
			}
		}, 1000)

		return () => {
			clearInterval(intervalId)
		}
	}, [startTime])

	const onClickInternal = () => {
		secondsPassedSet(0)
		setStartTime(Date.now())
		onClick()
	}

	const isCountingDown = secondsPassed < MAX_SECONDS

	return (
		<>
			<TextLight>{t('buttonResend.superscript')}</TextLight>

			{isCountingDown ? (
				<>
					<p className="text-text my-4 sr-only">
						{tAll('generic.countDown', { seconds: MAX_SECONDS })}
					</p>
					<p className="text-text my-4" aria-hidden="true">
						{tAll('generic.countDown', {
							seconds: MAX_SECONDS - secondsPassed,
						})}
					</p>
				</>
			) : (
				<SecondaryButton
					onClick={onClickInternal}
					title={t('buttonResend.title')}
					icon={<SyncIcon color="currentColor" />}
				/>
			)}
		</>
	)
}

export const Substep_4: React.FC<Props> = ({ onSubmit, onRetry }) => {
	useTitle('sms code invoeren')
	useSetActiveStep(AvailableStep.registrationValidatePhoneNumber)

	const { handleSubmit, formState, register, setError } = useForm<FormValues>({
		resolver: yupResolver(smsCodeSchema),
		defaultValues: IS_DEVELOPMENT_ENV ? { code: '000000' } : {},
	})

	function onSubmitInternal(fields: FormValues) {
		onSubmit(fields.code).catch(() => {
			setError('root', {
				type: 'serverError',
				message: t('serverError'),
			})
		})
	}

	useEffect(() => {
		const input = document.querySelector<HTMLInputElement>('input[name="code"]')
		if (input) {
			input.focus()
		}
	}, [])

	return (
		<div className="flex flex-col items-start w-full h-full">
			<form onSubmit={handleSubmit(onSubmitInternal)}>
				<Title classNameOverride="mb-6">{t('title')}</Title>

				{formState.errors.root?.type === 'serverError' && (
					<Notice
						noticeType="error"
						classNameOverride="mb-8"
						text={formState.errors.root?.message}
					/>
				)}

				<p className="font-semibold mb-2">{t('list.title')}</p>
				<ol className="max-w-[80%] list-decimal list-outside pl-6 mb-8">
					{tAsList('registration.phoneNumberVerification.list.items').map(
						({ text }, index) => (
							<li key={index} className="pb-2">
								{text}
							</li>
						)
					)}
				</ol>

				<div className="w-full md:w-1/3">
					<TextInputControlled
						{...register('code')}
						aria-required={true}
						title={t('inputCode.label')}
						placeholder={t('inputCode.placeholder')}
						autoComplete="off"
						type="number"
						min="0"
						hint={
							formState.errors.code ? formState.errors.code.message : undefined
						}
						hintVariant={'error'}
					/>
				</div>

				<Button
					data-testid="submit-button"
					title={t('buttonSubmit.title')}
					icon={<ChevronRightIcon />}
					classNameOverride="my-9"
					buttonProps={{ type: 'submit' }}
				/>

				<RetryArea onClick={onRetry} />
			</form>
		</div>
	)
}
