import React from 'react'

type Props = React.SVGProps<SVGSVGElement> & { color?: string; title?: string }

export const CheckIcon: React.FC<Props> = ({ color, title, ...props }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 48 48"
		fill="none"
		role={title ? 'img' : undefined}
		{...props}
	>
		{title && <title>{title}</title>}
		<path
			stroke="none"
			fill={color ?? '#166534'}
			d="M33.674 17.33c.44.441.44 1.154 0 1.59L21.3 31.3a1.12 1.12 0 0 1-1.589 0l-6.38-6.376a1.124 1.124 0 0 1 1.589-1.589l5.579 5.578L32.08 17.331a1.12 1.12 0 0 1 1.59 0h.004Z"
		/>
	</svg>
)
