import React from 'react'

type Props = React.SVGProps<SVGSVGElement> & { color?: string }

export function DocumentIcon({ color, ...props }: Props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 16 17"
			fill="none"
			{...props}
		>
			<path
				fill={color || '#36369D'}
				fillRule="evenodd"
				d="M9.333 1.833c.369 0 .667.299.667.667v2.667h2.667a.667.667 0 1 1 0 1.333H10a1.333 1.333 0 0 1-1.333-1.333V2.5c0-.368.298-.667.666-.667Z"
				clipRule="evenodd"
			/>
			<path
				fill={color || '#36369D'}
				fillRule="evenodd"
				d="M4.667 3.167A.667.667 0 0 0 4 3.833v9.334a.667.667 0 0 0 .667.666h6.666a.667.667 0 0 0 .667-.666V6.11L9.057 3.167h-4.39Zm-1.415-.748a2 2 0 0 1 1.415-.586h4.666c.177 0 .347.07.472.196l3.333 3.333a.667.667 0 0 1 .195.471v7.334a2 2 0 0 1-2 2H4.667a2 2 0 0 1-2-2V3.833a2 2 0 0 1 .585-1.414Z"
				clipRule="evenodd"
			/>
			<path
				fill={color || '#36369D'}
				fillRule="evenodd"
				d="M5.333 11.833c0-.368.299-.666.667-.666h4a.667.667 0 1 1 0 1.333H6a.667.667 0 0 1-.667-.667ZM5.333 9.167c0-.368.299-.667.667-.667h4a.667.667 0 1 1 0 1.333H6a.667.667 0 0 1-.667-.666Z"
				clipRule="evenodd"
			/>
		</svg>
	)
}
