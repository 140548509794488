import React, { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ObjectSchema, object, string } from 'yup'
import { ChevronRightIcon } from '../../assets/icons'
import { Button, InfoBoxText, Notice, Title } from '../../components'
import { useTitle } from '../../hooks/useTitle'
import { tContext } from '../../utils/cmsTranslations'
import { getEmailRegex } from '../../utils/emailRegex'
import { TextInputControlled } from '../../components/text-input/TextInputControlled'
import { AvailableStep, useSetActiveStep } from '../../utils/stepUtil'
import { AppContext } from '../../context/app-context'

const t = tContext('registration.email')
const tValidation = tContext('validation')

type Props = {
	onSubmit: (email: string) => Promise<any>
	email: string
}

type FormValues = {
	email: string
}

const EMAIL_REGEXP = getEmailRegex()

const emailSchema: ObjectSchema<FormValues> = object({
	email: string()
		.required(tValidation('validationEmailRequired'))
		.matches(EMAIL_REGEXP, tValidation('validationEmailInvalid')),
})

export const Substep_1: React.FC<Props> = ({ onSubmit, email }) => {
	const { setActiveStep } = useContext(AppContext)

	useTitle('E-mail')
	useSetActiveStep(AvailableStep.registrationEmail, () => {
		setActiveStep(AvailableStep.registrationStart)
	})

	const { handleSubmit, formState, register, setError } = useForm<FormValues>({
		resolver: yupResolver(emailSchema),
		defaultValues: { email },
	})

	function onSubmitInternal(fields: FormValues) {
		onSubmit(fields.email).catch(() => {
			setError('root', {
				type: 'serverError',
				message: t('serverError'),
			})
		})
	}

	return (
		<div className="flex flex-col items-start w-full h-full">
			{formState.errors.root?.type === 'serverError' && (
				<Notice
					noticeType="error"
					classNameOverride="mb-8"
					text={formState.errors.root.message}
				/>
			)}

			<Title classNameOverride="mb-6">{t('title')}</Title>
			<InfoBoxText
				title={t('notice.title')}
				text={t('notice.text')}
				classNameOverride="max-w-xl mb-8"
			/>

			<form onSubmit={handleSubmit(onSubmitInternal)}>
				<TextInputControlled
					{...register('email')}
					aria-required={true}
					title={t('inputEmail.label')}
					placeholder={t('inputEmail.placeholder')}
					type="email"
					hint={
						formState.errors.email ? formState.errors.email.message : undefined
					}
					hintVariant={'error'}
				/>

				<Button
					title={t('buttonSubmit.title')}
					icon={<ChevronRightIcon />}
					classNameOverride="mt-6"
					buttonProps={{ type: 'submit' }}
				/>
			</form>
		</div>
	)
}
