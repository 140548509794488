import React from 'react'

type Props = React.SVGProps<SVGSVGElement> & { color?: string }

export function StarFilledIcon({ color, ...props }: Props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="32"
			height="32"
			fill="none"
			{...props}
		>
			<path
				fill={color ?? '#B73E17'}
				d="M11.657 9.787 3.151 11.02 3 11.05a1.333 1.333 0 0 0-.587 2.246l6.163 5.999-1.453 8.473-.018.147a1.333 1.333 0 0 0 1.952 1.258l7.608-4 7.591 4 .133.062a1.335 1.335 0 0 0 1.803-1.467l-1.455-8.473 6.166-6 .104-.114a1.334 1.334 0 0 0-.844-2.16l-8.507-1.234-3.803-7.707a1.333 1.333 0 0 0-2.392 0l-3.804 7.707Z"
			/>
		</svg>
	)
}
