import React from 'react'

type Props = {
	color?: string
}

export const ChevronLeftIcon = ({ color }: Props) => (
	<svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M6.65101 12.166C7.14401 11.673 7.31001 11.044 6.81701 10.556L3.03501 6.77399L6.81701 2.99199C6.92114 2.87964 7.00011 2.74636 7.04864 2.60107C7.09717 2.45577 7.11414 2.30179 7.09843 2.14941C7.08272 1.99703 7.03468 1.84976 6.95753 1.71742C6.88037 1.58508 6.77587 1.47073 6.65101 1.38199V1.38199C6.15801 0.888986 5.52901 0.722985 5.04101 1.21599L0.371008 5.88599C0.253904 6.00284 0.160997 6.14164 0.0976067 6.29444C0.0342163 6.44725 0.00158691 6.61105 0.00158691 6.77648C0.00158691 6.94191 0.0342163 7.10573 0.0976067 7.25853C0.160997 7.41134 0.253904 7.55013 0.371008 7.66699L5.04101 12.337C5.52801 12.828 6.15801 12.657 6.65101 12.166Z" fill={color??'black'}/>
	</svg>


)