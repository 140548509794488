import React, { createContext, useContext, useMemo, useState } from 'react'

type LayoutType = {
	headerHidden: boolean
	setHeaderHidden: React.Dispatch<React.SetStateAction<boolean>>
}

const LayoutContext = createContext<LayoutType>({
	headerHidden: false,
	setHeaderHidden: () => undefined
})

type Props = {
	children: React.ReactNode
}

export const LayoutContextProvider: React.FC<Props> = ({ children }) => {
	const [headerHidden, setHeaderHidden] = useState(false)
	const value = useMemo(() => ({ headerHidden, setHeaderHidden }), [headerHidden, setHeaderHidden])

	return (
		<LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
	)
}

export const useLayoutContext = () => useContext(LayoutContext)