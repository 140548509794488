import { chunk } from '../../utils/chunk'
import { tContext } from '../../utils/cmsTranslations'
import {
	formatDate,
	formatAmount,
	formatAsLongDate,
} from '../../utils/formatDate'
import { getSivCodeDescription } from '../../utils/sivHelpers'
import { InfoText } from '../text-types/InfoText'
import { TextLight } from '../text-types/TextLight'
import { TableRow } from './TableRow'

type Props = {
	classNameOverride?: string
	birthDate?: Date
	currentIncomes?: any[][]
	grossIncome?: string | number
	grossIncomeYear?: string | number
	sivIncomes?: any[][]
}

const t = tContext('income')

export function renderSivIncomes(sivIncomes: any[][]) {
	return (
		<div className="w-full py-4">
			<p className="mb-4">{t('check.sectionIncomeSources.text')}</p>

			<div className="mb-4">
				{sivIncomes.map((siv: any) => (
					<div
						className="grid grid-cols-[1fr_80px] gap-4 w-full py-2"
						key={`siv${siv[0]}-${siv[1]}`}
					>
						<div>
							SIV-code {siv[0]}: {getSivCodeDescription(siv[0])}
						</div>
						<div className="text-right">{formatAmount(siv[1])}</div>
					</div>
				))}
			</div>

			<InfoText textId="incomeSources">
				{t('check.sectionIncomeSources.buttonMoreInfo.title')}
			</InfoText>
		</div>
	)
}

export function renderCurrentIncome(currentIncomes: any[][] | undefined) {
	if (!currentIncomes) {
		return null
	}

	return (
		<div className="my-4 flex flex-col w-full">
			<div className="flex flex-row w-full justify-between flex-wrap">
				{currentIncomes &&
					chunk(currentIncomes, 6).map((ch, index) => (
						<div key={index} className="flex flex-col w-full">
							{ch.map((i: any, idx) => {
								return (
									<div
										key={idx}
										className="w-full flex flex-row items-center justify-between py-2 border-b border-gray-200"
									>
										<TextLight size="md">
											<span className="sr-only">
												{formatAsLongDate(new Date(i[0]))} tot{' '}
												{formatAsLongDate(new Date(i[1]))}
											</span>
											<span aria-hidden="true">
												{formatDate(new Date(i[0]))} -{' '}
												{formatDate(new Date(i[1]))}
											</span>
										</TextLight>
										<TextLight size="md">{formatAmount(i[2])}</TextLight>
									</div>
								)
							})}
						</div>
					))}
			</div>
		</div>
	)
}

export const UserInfoTable = ({
	grossIncome,
	grossIncomeYear,
	birthDate,
	classNameOverride,
	currentIncomes,
	sivIncomes,
}: Props) => {
	return (
		<div className={classNameOverride}>
			<TableRow
				title={t('check.sectionCurrentIncome.title')}
				showBorder
				showTopBorder
				data={currentIncomes ? undefined : '-'}
			>
				{renderCurrentIncome(currentIncomes)}
			</TableRow>

			{sivIncomes && sivIncomes.length > 0 && (
				<TableRow title={t('check.sectionIncomeSources.title')} showBorder>
					{renderSivIncomes(sivIncomes)}
				</TableRow>
			)}

			<TableRow
				title={`Brutoloon ${grossIncomeYear}`}
				data={grossIncome ? formatAmount(Number(grossIncome)) : '-'}
				showBorder
			/>

			<TableRow
				title={t('check.sectionDateOfBirth.title')}
				data={birthDate ? formatDate(new Date(birthDate)).toString() : '-'}
				showBorder
			/>
		</div>
	)
}
