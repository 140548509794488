import { useContext } from 'react'
import { AppContext } from '../../context/app-context'
import { useLayoutContext } from '../../context/theme-context'
import { ProgressIndicator } from '../ProgressIndicator/ProgressIndicator'
import { SecondaryButton } from '../button/SecondaryButton'
import { ChevronLeftIcon } from '../../assets/icons'

export default function PageWrapper({
	children,
}: {
	children: React.ReactNode
}) {
	const { subStepConfig, backStep } = useContext(AppContext)
	const { headerHidden } = useLayoutContext()

	const maxSteps = subStepConfig.maxSteps
	const step = Math.max(1, Math.min(subStepConfig.currentStep + 1, maxSteps))

	return (
		<div className="flex items-center justify-center w-full h-full">
			<div className="flex flex-col items-center w-full h-full p-8 rounded-xl bg-white">
				{!headerHidden && subStepConfig.showIndicator && (
					<div className="w-full relative">
						{backStep && (
							<SecondaryButton
								variant="small"
								classNameOverride="absolute cursor-pointer left-0 top-0"
								onClick={() => backStep()}
								title="vorige stap"
								icon={<ChevronLeftIcon color="currentColor" />}
							/>
						)}
						<ProgressIndicator maxSteps={maxSteps} step={step} />
					</div>
				)}

				{!headerHidden && !subStepConfig.showIndicator && !!backStep && (
					<div className="w-full mb-12 relative">
						<SecondaryButton
							variant="small"
							classNameOverride="absolute cursor-pointer left-0 top-0"
							onClick={() => backStep()}
							title="vorige stap"
							icon={<ChevronLeftIcon color="currentColor" />}
						/>
					</div>
				)}

				{children}
			</div>
		</div>
	)
}
