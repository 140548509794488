import clsx from 'clsx'
import { CheckIcon } from '../../assets/icons'
import {
	MAIN_STEPS,
	getMainStepDescription,
	getMainStepText,
} from '../../utils/stepUtil'

export const OverallProgressIndication = ({
	step,
	withExplanation = false,
}: {
	step: number
	withExplanation?: boolean
}) => {
	return (
		<div className="flex flex-col mb-8 z-[1]">
			{MAIN_STEPS.map((s, i) => (
				<div
					key={s}
					className="relative grid grid-cols-[3rem_1fr] gap-4 py-3"
					{...(i !== step ? { 'aria-hidden': 'true' } : {})}
				>
					<div aria-hidden="true">
						<p
							className={clsx(
								'w-[3rem] h-[3rem] text-center font-semibold leading-[3rem] rounded-full',
								{
									'before:content-[""] before:block before:w-[2px] before:absolute before:top-[12px] before:left-[1.5rem] before:h-[100%] before:z-[-1]':
										i === 0 && i === step,
									'before:content-[""] before:block before:w-[2px] before:absolute before:top-[-24px] before:left-[1.5rem] before:h-[100%] before:z-[-1]':
										i > 0,
									'bg-green-600 text-white': i < step,
									'bg-gray-950 text-white': i === step,
									'bg-white': i > step && !withExplanation,
									'bg-gray-100': i > step && withExplanation,
									'before:bg-white': !withExplanation,
									'before:bg-gray-100': withExplanation,
								}
							)}
						>
							{s < step ? (
								<CheckIcon
									color="#fff"
									className="w-[3rem] h-[3rem]"
									title="Afgerond"
								/>
							) : (
								s + 1
							)}
						</p>
					</div>
					<div>
						<p
							className={clsx('leading-[3rem] font-semibold', {
								'text-md text-green-800': i < step,
								'text-[24px] text-gray-950': i === step,
								'text-md text-gray-800': i > step,
								'mb-2': withExplanation && i === step,
							})}
						>
							{getMainStepText(s)}
						</p>

						{withExplanation && i === step && (
							<div
								className="text-md"
								dangerouslySetInnerHTML={{ __html: getMainStepDescription(s) }}
							/>
						)}
					</div>
				</div>
			))}
		</div>
	)
}
