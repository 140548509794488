export type VideoTagName = 'registration' | 'household' | 'income' | 'energy' | 'send'

const VIDEO_URL = 'https://nfe-videos.s3.eu-central-1.amazonaws.com/web'

export const VIDEOS: Record<
	VideoTagName,
	{
		src: string
		altSrc: string
		poster: string
		subtitlesSrc: string
		transcript: string
	}
> = {
	registration: {
		src: `${VIDEO_URL}/VIDEO_Noodfonds_2_V3.mp4`,
		altSrc: `${VIDEO_URL}/VIDEO_Noodfonds_2_V3.webm`,
		poster: `${VIDEO_URL}/VIDEO_Noodfonds_2_V3.jpg`,
		subtitlesSrc: `${VIDEO_URL}/VIDEO_Noodfonds_2_V3_NL.vtt`,
		transcript: 'textRegistrationVideo',
	},
	household: {
		src: `${VIDEO_URL}/VIDEO_Noodfonds_2.1_V1.mp4`,
		altSrc: `${VIDEO_URL}/VIDEO_Noodfonds_2.1_V1.webm`,
		poster: `${VIDEO_URL}/VIDEO_Noodfonds_2.1_V3.jpg`,
		subtitlesSrc: `${VIDEO_URL}/VIDEO_Noodfonds_2.1_V1_NL.vtt`,
		transcript: 'textHouseholdVideo',
	},
	income: {
		src: `${VIDEO_URL}/VIDEO_Noodfonds_3_V3.mp4`,
		altSrc: `${VIDEO_URL}/VIDEO_Noodfonds_3_V3.webm`,
		poster: `${VIDEO_URL}/VIDEO_Noodfonds_3_V3.jpg`,
		subtitlesSrc: `${VIDEO_URL}/VIDEO_Noodfonds_3_V3_NL.vtt`,
		transcript: 'textIncomeVideo',
	},
	energy: {
		src: `${VIDEO_URL}/VIDEO_Noodfonds_4_V3.mp4`,
		altSrc: `${VIDEO_URL}/VIDEO_Noodfonds_4_V3.webm`,
		poster: `${VIDEO_URL}/VIDEO_Noodfonds_4_V3.jpg`,
		subtitlesSrc: `${VIDEO_URL}/VIDEO_Noodfonds_4_V3_NL.vtt`,
		transcript: 'textEnergyVideo',
	},
	send: {
		src: `${VIDEO_URL}/VIDEO_Noodfonds_5_V3.mp4`,
		altSrc: `${VIDEO_URL}/VIDEO_Noodfonds_5_V3.webm`,
		poster: `${VIDEO_URL}/VIDEO_Noodfonds_5_V3.jpg`,
		subtitlesSrc: `${VIDEO_URL}/VIDEO_Noodfonds_5_V3_NL.vtt`,
		transcript: 'textSendVideo',
	}
}
