import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { AppContext } from '../context/app-context'

export default function RedirectFirstPage({ children }: any) {
	const { appLoaded } = useContext(AppContext)
	const navigate = useNavigate()

	useEffect(() => {
		if (!appLoaded) {
			navigate('/')
		}
	}, [appLoaded, navigate])

	return <>{children}</>
}
