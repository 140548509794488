import { MarkdownText, Notice, Title } from '..'
import GiveReviewModal from '../../pageComponents/Overview/GiveReviewModal'
import { tContext } from '../../utils/cmsTranslations'

const t = tContext('send.result')

export default function Success() {
	return (
		<div className="w-full h-full">
			<Title classNameOverride="text-[34px] leading-xl mb-4">
				{t('title')}
			</Title>

			<Notice
				classNameOverride="mb-8"
				noticeType="success"
				title={t('positive.notice.title')}
				text={''}
			/>

			<p className="font-semibold mb-2">{t('positive.titleWhatsNext')}</p>

			<MarkdownText text={t('positive.textWhatsNext')} />

			<GiveReviewModal />
		</div>
	)
}
