import React from 'react'

type Props = React.SVGProps<SVGSVGElement> & { color?: string }

export function PhoneDisabledIcon({ color, ...props }: Props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			fill="none"
			{...props}
		>
			<path
				fill={color ?? '#000'}
				fillRule="evenodd"
				d="M8 4.5a.987.987 0 0 0-.42.092 1 1 0 0 1-.843-1.814C7.123 2.6 7.551 2.5 8 2.5h8a3 3 0 0 1 3 3v9a1 1 0 1 1-2 0v-9a1 1 0 0 0-1-1H8Zm-2 1a1 1 0 0 1 1 1v13a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1a1 1 0 1 1 2 0v1a3 3 0 0 1-3 3H8a3 3 0 0 1-3-3v-13a1 1 0 0 1 1-1Z"
				clipRule="evenodd"
			/>
			<path
				fill={color ?? '#000'}
				fillRule="evenodd"
				d="M10 4.5a1 1 0 0 1 1-1h2a1 1 0 1 1 0 2h-2a1 1 0 0 1-1-1ZM2.293 2.793a1 1 0 0 1 1.414 0l18 18a1 1 0 0 1-1.414 1.414l-18-18a1 1 0 0 1 0-1.414Z"
				clipRule="evenodd"
			/>
		</svg>
	)
}
