import { useContext, useEffect, useState } from 'react'
import { Dialog as HeadlessDialog } from '@headlessui/react'
import { useFocusRef } from '../../hooks/useFocusRef'
import Step1 from './GiveReviewModal/Step1'
import Step2 from './GiveReviewModal/Step2'
import RatingButtons from './GiveReviewModal/RatingButtons'
import { Button } from '../../components'
import Step3 from './GiveReviewModal/Step3'
import { tContext } from '../../utils/cmsTranslations'
import { postReview } from '../../utils/review-api'
import { AppContext } from '../../context/app-context'

const t = tContext('send.result')

const GiveReviewModal = () => {
	const titleRef = useFocusRef()
	const { email, ticket } = useContext(AppContext)

	const [showReview, setShowReview] = useState<boolean>(true)
	const [open, setOpen] = useState<boolean>(false)
	const [step, setStep] = useState<number>(1)
	const [rating, setRating] = useState<number>(0)
	const [doneBefore, setDoneBefore] = useState<string>('')
	const [positivePoints, setPositivePoints] = useState<string[]>([])
	const [toBeImprovedPoints, setToBeImprovedPoints] = useState<string[]>([])
	const [done, setDone] = useState<boolean>(false)

	const onGiveReviewClick = () => {
		setOpen(true)
	}

	const onRatingChange = (newRating: number) => {
		setRating(newRating)
	}

	const onNextStep1 = (newPositivePoints: string[]) => {
		setPositivePoints(newPositivePoints)
		setStep(2)
	}

	const onSkipStep1 = () => {
		setStep(2)
	}

	const onNextStep2 = (newToBeImprovedPoints: string[]) => {
		setToBeImprovedPoints(newToBeImprovedPoints)
		setStep(3)
	}

	const onSkipStep2 = () => {
		setStep(3)
	}

	const onNextStep3 = (newDoneBefore: string) => {
		setDoneBefore(newDoneBefore)
		setShowReview(false)
		setOpen(false)
		setDone(true)
	}

	useEffect(() => {
		if (done) {
			postReview(
				{
					email,
					stars: rating,
					positives: positivePoints,
					negatives: toBeImprovedPoints,
					aanvraagGedaanVorigJaar:
						doneBefore === 'Ja' ? true : doneBefore === 'Nee' ? false : null,
				},
				ticket
			)
		}
	}, [done])

	if (!ticket) {
		return null
	}

	if (!showReview) {
		return (
			<div className="inline-block border rounded-lg p-5">
				<p className="text-text text-center font-semibold mb-4">
					{t('ratingCompleted')}
				</p>

				<div className="mb-2">
					<RatingButtons rating={rating} />
				</div>
			</div>
		)
	}

	return (
		<div className="w-full">
			<div className="inline-block border rounded-lg p-5">
				<p className="text-text font-semibold mb-4">{t('ratingTitle')}</p>

				<div className="mb-2">
					<RatingButtons onRatingChange={onRatingChange} rating={rating} />
				</div>

				{rating > 0 && (
					<Button
						variant="small"
						title={t('buttonOpenReviewModal.title')}
						onClick={onGiveReviewClick}
						classNameOverride="w-full mt-4"
					/>
				)}
			</div>

			<HeadlessDialog
				open={open}
				onClose={() => setOpen(false)}
				className="fixed z-10 inset-0"
				aria-hidden="false"
				aria-modal="true"
			>
				<HeadlessDialog.Overlay />
				<div className="fixed inset-0 bg-modal-backdrop pointer-events-none" />

				<div className="flex items-center justify-center py-24 px-36">
					<div className="relative bg-white w-full max-w-lg mx-auto p-8 max-h-[80vh] overflow-y-auto">
						<HeadlessDialog.Title
							tabIndex={0}
							ref={titleRef}
							className="font-semibold text-text text-lg"
						>
							{step === 3 ? 'Beoordeling verzonden' : 'Beoordeling'}
						</HeadlessDialog.Title>

						<div className="mt-4">
							{step === 1 && (
								<Step1
									onNext={onNextStep1}
									onSkipStepClick={onSkipStep1}
									rating={rating}
								/>
							)}
							{step === 2 && (
								<Step2
									onNext={onNextStep2}
									onSkipStepClick={onSkipStep2}
									rating={rating}
								/>
							)}
							{step === 3 && <Step3 onNext={onNextStep3} rating={rating} />}
						</div>
					</div>
				</div>
			</HeadlessDialog>
		</div>
	)
}

export default GiveReviewModal
