import React from 'react'
import { ActivityIndicatorIcon } from '../../assets/icons'
import { MarkdownText } from '../../components'
import { Config } from '../../types/aqopi'
import { tContext } from '../../utils/cmsTranslations'

const t = tContext('collect.status')

const Query: React.FC<{ activeConfig: Config }> = ({ activeConfig }) => {
	return (
		<div className="flex items-center bg-white">
			<div className="flex flex-col items-center w-full my-8">
				<div className="text-lg w-full text-center max-w-[432px]">
					<MarkdownText
						text={t(
							activeConfig.id.includes('uwv-')
								? 'textUwvProgress'
								: 'textMbdProgress'
						)}
					/>

					<p className="sr-only">Een moment geduld.</p>
				</div>

				<ActivityIndicatorIcon
					aria-hidden="true"
					color="currentColor"
					className="animate-spin text-indigo-blue-800 mb-3 mt-3"
				/>
			</div>
		</div>
	)
}

export default Query
