import React from 'react'

type Props = React.SVGProps<SVGSVGElement> & { color?: string }

export const MoneyIcon: React.FC<Props> = ({ color, ...props }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		fill="none"
		{...props}
	>
		<path
			fill={color ?? '#000'}
			fillRule="evenodd"
			d="M10.736 4.649a6.212 6.212 0 0 1 4.108-.5c1.385.308 2.636 1.081 3.609 2.193a1 1 0 1 1-1.506 1.316c-.715-.817-1.602-1.349-2.538-1.558a4.212 4.212 0 0 0-2.792.345c-.893.437-1.68 1.186-2.244 2.176A6.857 6.857 0 0 0 8.5 12c0 1.213.307 2.389.872 3.38.563.99 1.35 1.738 2.244 2.175.89.437 1.858.553 2.792.345.936-.209 1.823-.74 2.538-1.558a1 1 0 1 1 1.506 1.316c-.973 1.112-2.224 1.885-3.61 2.194a6.211 6.211 0 0 1-4.107-.5c-1.285-.63-2.357-1.677-3.101-2.983A8.857 8.857 0 0 1 6.5 12c0-1.544.39-3.064 1.134-4.369.744-1.306 1.816-2.352 3.101-2.982Z"
			clipRule="evenodd"
		/>
		<path
			fill={color ?? '#000'}
			fillRule="evenodd"
			d="M4.5 10a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2h-8a1 1 0 0 1-1-1Zm0 4a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2h-8a1 1 0 0 1-1-1Z"
			clipRule="evenodd"
		/>
	</svg>
)
