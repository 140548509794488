import { useNavigate } from 'react-router'
import { Button, ListWithIcons, Title } from '../../components'
import { tContext } from '../../utils/cmsTranslations'
import { ChevronRightIcon } from '../../assets/icons'
import { Video } from '../../components/Video/Video'

const t = tContext('income.intro')

export const IncomeIntro = () => {
	const navigate = useNavigate()

	const onNextClick = () => {
		navigate('/review')
	}

	return (
		<div className="w-full">
			<Video tag="income" />

			<Title classNameOverride="mb-8">{t('title')}</Title>

			<ListWithIcons listKey="income.intro.list.items" className="mb-2" />

			<Button
				title={t('buttonNext.title')}
				onClick={onNextClick}
				icon={<ChevronRightIcon color="currentColor" />}
			/>
		</div>
	)
}
