import React, { useContext } from 'react'
import { CrossIcon, WarningIcon } from '../../assets/icons'
import { AppContext } from '../../context/app-context'
import { TextLight } from '../text-types/TextLight'
import { TextMedium } from '../text-types/TextMedium'

export type AppError = {
	message: string
	title?: string
	errorCode?: string
}

type Props = {
	classNameOverride?: string
}

export const ErrorModal: React.FC<Props> = () => {
	const { appError, setAppError } = useContext(AppContext)

	return (
		<div
			className={`bg-[rgba(95,88,88,0.6)] top-0 left-0 right-0 bottom-0 
			flex items-center justify-center w-full h-full z-10 ${
				appError ? 'fixed' : 'hidden'
			}`}
		>
			<div className="flex flex-col bg-white w-fit">
				<div className="w-full flex items-center justify-end pt-[10px] pr-[10px]">
					<CrossIcon
						className="cursor-pointer"
						onClick={() => setAppError(undefined)}
					/>
				</div>
				<div className="flex flex-col w-full px-10 items-start pb-[37px]">
					<div className="flex flex-row items-center mb-6">
						<WarningIcon />
						<TextMedium classNameOverride="ml-[10px]">
							{appError && appError?.title ? appError.title : 'Fout'}
						</TextMedium>
					</div>
					{appError ? (
						<div>
							<TextLight size="md" classNameOverride="mb-4 whitespace-pre-line">
								{appError.message}
							</TextLight>
							{appError.errorCode ? (
								<TextLight
									size="md"
									classNameOverride="mb-4"
								>{`Foutcode: ${appError.errorCode}`}</TextLight>
							) : null}
						</div>
					) : null}
				</div>
			</div>
		</div>
	)
}
