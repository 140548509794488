import { FormProvider, useForm } from 'react-hook-form'
import { ChevronRightIcon } from '../../../assets/icons'
import { Button } from '../../../components'
import Pill from './Pill'
import RatingButtons from './RatingButtons'
import { tContext } from '../../../utils/cmsTranslations'

const DATA = ['Ja', 'Nee']

type FormValues = { doneBefore: string }

const t = tContext('review')

const Step3 = ({
	onNext,
	rating,
}: {
	onNext: (doneBefore: string) => void
	rating: number
}) => {
	const methods = useForm<FormValues>({
		defaultValues: {
			doneBefore: '',
		},
	})

	const onSubmit = (data: FormValues) => {
		onNext(data.doneBefore)
	}

	return (
		<FormProvider {...methods}>
			<form onSubmit={methods.handleSubmit(onSubmit)}>
				<div className="mb-6">
					<hr />
					<div className="my-6">
						<RatingButtons rating={rating} />
					</div>
					<hr />
					<div className="mt-6 mb-4">
						<p className="text-text font-semibold mb-2">
							{t('secondTimeTitle')}
						</p>
						<div>
							{DATA.map((value, index) => (
								<Pill
									type="radio"
									value={value}
									name="doneBefore"
									color="green"
									index={index}
									key={value}
								/>
							))}
						</div>
					</div>
					<hr />
				</div>

				<div className="flex justify-between items-center">
					<div />
					<div>
						<Button
							variant="small"
							title={t('buttonSendReview.title')}
							icon={<ChevronRightIcon color="currentColor" />}
						/>
					</div>
				</div>
			</form>
		</FormProvider>
	)
}

export default Step3
