import { EnergyData, PeriodEnergyData } from '../../types/api'
import { getInverseEnergyType } from '../../utils/energyUtil'

export const CURRENT_PERIOD = '2024'

export function getEmptyEnergyData(isPrimary: boolean): EnergyData {
	return {
		energyType: getInverseEnergyType(undefined),
		clientNumber: '',
		supplier: '',
		advanceAdvice: '',
		actualAdvance: '',
		paymentAgreement: '',
		selectedContractHolder: undefined,
		isPrimary,
	}
}

export function getEmptyPeriodEnergyData(): PeriodEnergyData[] {
	return [
		{
			period: { val: CURRENT_PERIOD, label: 'Laatste periode' },
			data: [getEmptyEnergyData(true)],
		},
	]
}
