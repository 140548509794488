import { useEffect, useRef } from 'react'

export function useFocusRef(deps?: any) {
	const ref = useRef<HTMLHeadingElement | null>(null)

	useEffect(
		() => {
			if (ref.current) {
				ref.current.focus()
			}
		},
		deps ? [deps] : []
	)

	return ref
}
