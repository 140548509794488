import { PeriodEnergyData } from '../types/api'

function periodIsInComplete(periodData: PeriodEnergyData) {
	return periodData.data.some((data) => {
		return (
			(!data.advanceAdvice && !data.actualAdvance) ||
			!data.supplier ||
			!data.clientNumber ||
			!data.selectedContractHolder
		)
	})
}

export function isDatasetIncompleteCheck(energyData: PeriodEnergyData[]) {
	if (energyData.length === 0) {
		return true
	}

	return energyData.some((periodData) => {
		return periodIsInComplete(periodData)
	})
}

export const IS_DEVELOPMENT_ENV = process.env.NODE_ENV === 'development'

export const assetUrl = (path: string) => {
	const publicUrl = process.env.PUBLIC_URL || ''

	return `${publicUrl}${path}`
}
