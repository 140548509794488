import { checkAvailability } from '@iwize-aqopi/brondata-session-availability-check'
import {
	checkAqopiAvailability,
	machtigingenConfigs,
	configs as standardConfigs,
} from './extension'
import { useContext, useEffect } from 'react'
import { AppContext } from '../context/app-context'

const canActivate = async () => {
	const availabilityResult = await checkAvailability(['uwv-nfe-ext'])

	return {
		ok: availabilityResult.available,
		payload: {
			title: availabilityResult.title,
			message: availabilityResult.message,
		},
	}
}

export { canActivate }

export const useCheckAvailability = () => {
	const { isGemachtigd, setIsFailed } = useContext(AppContext)

	useEffect(() => {
		checkAqopiAvailability(
			isGemachtigd ? machtigingenConfigs : standardConfigs
		).then((available) => {
			setIsFailed(available)
		})
	}, [])
}
