import React from 'react'

type Props = {
	checked: boolean
	disabled?: boolean
	setChecked: () => void
	value: string
	classNameOveride?: string
	inputProps?: any
}

export const Checkbox: React.FC<Props> = ({
	value,
	checked,
	setChecked,
	classNameOveride,
	inputProps,
	disabled = false,
}) => {
	return (
		<label
			className={`flex items-center ${
				disabled ? 'opacity-50' : ''
			} ${classNameOveride}`}
		>
			<input
				type="checkbox"
				checked={checked}
				disabled={disabled}
				onChange={setChecked}
				className={`mr-3 w-6 h-6 ${
					disabled ? 'opacity-70' : ''
				} accent-indigo-blue-800`}
				{...inputProps}
			/>
			{value}
		</label>
	)
}
