import React from 'react'

export const EdgeIcon = () => (
	<svg width="372" height="148" xmlns="http://www.w3.org/2000/svg">
		<defs>
			<linearGradient x1="50%" y1="0%" x2="50%" y2="71.186%" id="a"><stop stopColor="#FFF" stopOpacity="0" offset="0%"/><stop stopColor="#FFF" offset="100%"/></linearGradient>
		</defs>
		<g transform="translate(10 10)" fill="none" fillRule="evenodd"><rect fill="#EFEFEF" opacity=".5" x="10" y="1" width="78" height="34" rx="8"/><rect fill="#E1E9F1" x="94" width="103" height="34" rx="8"/><rect fill="#E1E9F1" y="21" width="352" height="89" rx="6"/><rect width="50" height="5" rx="2.5" transform="translate(125 12)" fill="#8396AC"/>
			<g opacity=".5" transform="translate(18 9)" fill="#CECECE"><rect x="15" y="2" width="40" height="5" rx="2.5"/><rect width="9" height="9" rx="4.5"/>
			</g><path fill="url(#a)" d="M0 23h352v89H0z"/><rect fill="#FFF" opacity=".9" x="30" y="43" width="292" height="55" rx="4.5"/><rect fill="#BAC5D4" opacity=".5" x="80.5" y="81.52" width="55" height="5" rx="2.5"/><rect fill="#BAC5D4" opacity=".5" x="140.344" y="81.52" width="20" height="5" rx="2.5"/><rect fill="#BAC5D4" opacity=".5" x="165.188" y="81.52" width="40" height="5" rx="2.5"/>
			<g transform="translate(80.5 58)"><rect fill="#8396AC" width="50" height="5" rx="2.5"/><rect fill="#BAC5D4" opacity=".75" y="12" width="40" height="5" rx="2.5"/>
			</g>
			<g transform="translate(240 51)">
				<rect width="74" height="29" rx="9"/>
				<rect fill="#187AE4" x="0" y="0" width="74" height="29" rx="6" className='animate-pulse-new' />
				<rect fill="#186FE0" x="7" y="7" width="60" height="15" rx="4"/>
			</g>
			<rect fill="#8396AC" x="39.5" y="52" width="28" height="28" rx="4"/><path d="M106.97 15.972c0 .364.055.695.166.993.118.293.272.555.468.784.196.229.425.425.694.59.26.166.544.303.846.41.3.108.614.188.933.24a6.348 6.348 0 0 0 2.086-.017c.354-.066.7-.153 1.04-.264.34-.111.668-.243.996-.395.331-.15.666-.316 1.011-.5v3.203c-.385.189-.763.347-1.14.484-.379.132-.757.248-1.142.34a8.843 8.843 0 0 1-1.174.208c-.399.043-.807.066-1.233.066a6.837 6.837 0 0 1-1.641-.196 6.436 6.436 0 0 1-1.484-.562 6.217 6.217 0 0 1-1.27-.893 5.642 5.642 0 0 1-1.86-4.257c0-.621.085-1.212.255-1.776a5.67 5.67 0 0 1 1.907-2.814c.46-.366.977-.666 1.554-.897a3.28 3.28 0 0 0-.732 1.117c-.178.43-.289.862-.338 1.295h5.381c0-.544-.054-1.016-.165-1.422a2.374 2.374 0 0 0-.537-1.011 2.232 2.232 0 0 0-.952-.605c-.39-.135-.86-.203-1.41-.203-.65 0-1.3.094-1.949.29-.65.19-1.266.46-1.852.803a8.974 8.974 0 0 0-1.615 1.224c-.492.472-.905.98-1.24 1.53a8.82 8.82 0 0 1 .408-1.835 7.813 7.813 0 0 1 .768-1.63 7.31 7.31 0 0 1 1.096-1.356 6.531 6.531 0 0 1 1.394-1.039 7.053 7.053 0 0 1 1.646-.666 8.441 8.441 0 0 1 1.88-.215c.388 0 .773.035 1.158.104.385.07.76.165 1.127.288.727.25 1.377.6 1.949 1.044.571.449 1.05.96 1.44 1.543.39.583.686 1.228.889 1.93.203.701.307 1.431.307 2.19v1.877h-9.664z" fill="#188BE6"/>
		</g>
	</svg>
)