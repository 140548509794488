import './App.css'
import { useEffect } from 'react'
import { AppRoutes } from './routes'
import { initFeatureFlags } from './utils/featureFlags'
import { useAqopi } from './hooks/useAqopi'

function App() {
	const { initExtension } = useAqopi()
	useEffect(() => {
		initExtension()
		initFeatureFlags()
	}, [])

	return <AppRoutes />
}

export default App
