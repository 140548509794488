import { useCallback, useContext, useEffect, useState } from 'react'
import clsx from 'clsx'

import { FaqItems, FaqMappedByTag, getFaqMapping } from '../../utils/faq-api'
import { AppContext } from '../../context/app-context'
import { TableRow } from '../../components/info-table/TableRow'
import { MarkdownText, SecondaryButton } from '../../components'
import { tContext } from '../../utils/cmsTranslations'
import { ThumbsUpIcon } from '../../assets/icons/ThumbsUpIcon'
import { ThumbsDownIcon } from '../../assets/icons/ThumbsDownIcon'
import { getNagivationEnum } from '../../utils/navigation-logging'
import { getFaqButtonByStep, getFaqUrlByStep } from '../../utils/faqHelper'

const t = tContext('faq')

const FaqSection = () => {
	const appCtx = useContext(AppContext)
	const [faqMappingByTag, setFaqMappingByTag] = useState<FaqMappedByTag | null>(
		null
	)
	const [faqMapping, setFaqMapping] = useState<FaqItems | null>(null)
	const [ratingUpTitles, setRatingUpTitles] = useState<string[]>([])
	const [ratingDownTitles, setRatingDownTitles] = useState<string[]>([])
	const faqUrl = getFaqUrlByStep(appCtx.activeStep)
	const faqButtonLabel = getFaqButtonByStep(appCtx.activeStep)

	useEffect(() => {
		;(async () => {
			try {
				const result = await getFaqMapping()
				setFaqMappingByTag(result)
			} catch (err) {
				console.error(err)
			}
		})()
	}, [])

	useEffect(() => {
		if (!faqMappingByTag) {
			return
		}

		// the tags are based on our navigation enum
		const tag = getNagivationEnum(appCtx.activeStep, undefined)

		if (tag && faqMappingByTag[tag]) {
			setFaqMapping(faqMappingByTag[tag])
		} else {
			setFaqMapping(null)
		}
	}, [faqMappingByTag, appCtx.activeStep])

	const voteRatingDown = (title: string) => {
		if (ratingDownTitles.includes(title)) {
			setRatingDownTitles(ratingDownTitles.filter((t) => t !== title))
		} else {
			setRatingUpTitles(ratingUpTitles.filter((t) => t !== title))
			setRatingDownTitles([...ratingDownTitles, title])
		}
	}

	const voteRatingUp = (title: string) => {
		if (ratingUpTitles.includes(title)) {
			setRatingUpTitles(ratingUpTitles.filter((t) => t !== title))
		} else {
			setRatingDownTitles(ratingDownTitles.filter((t) => t !== title))
			setRatingUpTitles([...ratingUpTitles, title])
		}
	}

	const isRatedHelpful = useCallback(
		(title: string) => ratingUpTitles.includes(title),
		[ratingUpTitles]
	)

	const isRatedNotHelpful = useCallback(
		(title: string) => ratingDownTitles.includes(title),
		[ratingDownTitles]
	)

	if (!faqMappingByTag || !faqMapping) {
		return null
	}

	const faqMappingWithRating = faqMapping.map((faq) => ({
		...faq,
		isVotedHelpful: isRatedHelpful(faq.title),
		isVotedNotHelpful: isRatedNotHelpful(faq.title),
	}))

	return (
		<div className="flex items-center justify-center w-full h-full mt-8">
			<div className="w-full h-full p-8 rounded-xl bg-white">
				<h3 className="font-semibold text-text text-2xl mb-6">
					{t('faqTitle')}
				</h3>

				{faqMappingWithRating.map((faq, index) => (
					<TableRow
						key={faq.title}
						title={faq.title}
						showBorder
						showTopBorder={index === 0}
					>
						<div className="pt-6">
							<MarkdownText text={faq.content} className="mb-12" />

							<p
								className="text-text text-sm font-semibold mb-4"
								id="rating-title"
							>
								{t('ratingTitle')}
							</p>

							<div className="flex flex-gap-2 items-start justify-start">
								<SecondaryButton
									title={t('buttonRateUp.title')}
									aria-label={t('buttonRateUp.title')}
									aria-labelledby="rating-title"
									icon={<ThumbsUpIcon color="currentColor" />}
									variant="small"
									disabled={faq.isVotedHelpful}
									classNameOverride={clsx('mr-2', {
										'text-indigo-blue-800': !faq.isVotedHelpful,
										'bg-green-100 text-green-800': faq.isVotedHelpful,
									})}
									onClick={() => voteRatingUp(faq.title)}
								/>
								<SecondaryButton
									title={t('buttonRateDown.title')}
									aria-label={t('buttonRateDown.title')}
									aria-labelledby="rating-title"
									icon={<ThumbsDownIcon color="currentColor" />}
									variant="small"
									disabled={faq.isVotedNotHelpful}
									classNameOverride={clsx({
										'text-indigo-blue-800': !faq.isVotedNotHelpful,
										'bg-red-100 text-red-800': faq.isVotedNotHelpful,
									})}
									onClick={() => voteRatingDown(faq.title)}
								/>
							</div>

							{faq.isVotedNotHelpful && (
								<div className="mt-8 mb-4">
									<p className="text-text text-sm mb-2">{t('needMoreHelp')}</p>

									<a
										className="text-text font-semibold text-lg text-indigo-blue-800"
										href={
											'tel:' +
											encodeURI(t('buttonPhoneNumber.title').replace(/\s/g, ''))
										}
									>
										{t('buttonPhoneNumber.title')}
									</a>
								</div>
							)}
						</div>
					</TableRow>
				))}
				{faqUrl && faqButtonLabel && (
					<a
						href={faqUrl}
						target="_blank"
						rel="noreferrer"
						className="block font-semibold text-sm leading-6 text-left text-indigo-blue-800 mt-6"
					>
						{faqButtonLabel} &gt;
					</a>
				)}
			</div>
		</div>
	)
}

export default FaqSection
