import { AppError } from '../components/modal/ErrorModal'
import { t } from './cmsTranslations'

const errorMessageStatus: Record<number, string> = {
	// 0 is default, used for messages not in this list
	0: 'Er is een onbekende fout opgetreden.',
	401: 'Je hebt niet geauthoriseerd om deze methode aan te roepen.',
	403: 'Je mist bevoegdheden om deze methode aan te roepen.',
	404: 'De methode die je aanroept, is niet gevonden.',
	405: 'Er is een verkeerde oproep gedaan aan de methode.',
	429: 'De server is op dit moment overbelast.',
	500: 'Er is een interne server fout opgetreden.',
	503: 'De server is op dit moment niet beschikbaar.',
	504: 'Het duurde te lang voordat de server reageerde. De mogelijkheid zit er in dat deze overbelast is.',
}

export type HttpErrorHandler = (error: AppError | undefined) => void

const getTranslatedError = (key: string): AppError => ({
	title: t(`${key}.title`),
	message: t(`${key}.text`),
})

class Http {
	private errorHandler: HttpErrorHandler

	constructor() {
		this.errorHandler = (error) => {
			console.error('Error during fetch', error)
		}
	}

	setErrorHandler(errorHandler?: HttpErrorHandler) {
		if (!errorHandler) {
			this.errorHandler = this.defaultErrorHandler
		} else {
			this.errorHandler = errorHandler
		}
	}

	private defaultErrorHandler(error: AppError | undefined) {
		console.error('Error during fetch', error)
	}

	private getErrorContent(response: Response, url: URL): AppError {
		const urlStr = url.toString()

		if (
			Object.keys(errorMessageStatus).some(
				(x) => x === response.status.toString()
			)
		) {
			return {
				message: errorMessageStatus[response.status],
				errorCode: response.status.toString(),
			}
		} else if (urlStr.includes('/parse')) {
			return getTranslatedError('generic.feedbackParseDataFailure')
		} else if (urlStr.includes('/application/check')) {
			return getTranslatedError('send.check.feedbackError')
		} else if (urlStr.includes('/process/income')) {
			return getTranslatedError('generic.feedbackProcessIncomeFailure')
		} else if (urlStr.includes('/process/household')) {
			return getTranslatedError('generic.feedbackProcessHouseholdFailure')
		} else {
			return {
				message: errorMessageStatus[0] + '\n' + 'Probeer het later opnieuw.',
				errorCode: response.status.toString(),
			}
		}
	}

	/**
	 * A wrapper around fetch with implemented error handler
	 * @param args
	 * @returns
	 */
	async fetch(...args: Parameters<typeof fetch>) {
		try {
			const response = await fetch(...args)
			if (!response.ok) {
				const error = this.getErrorContent(response, args[0] as URL)
				if (this.errorHandler) this.errorHandler(error)
			}
			return response
		} catch (e) {
			const error: AppError = {
				message:
					'Kan geen verbinding met de server maken. Controleer je netwerkverbinding.',
				errorCode: e instanceof DOMException ? 'A2' : 'A1',
			}

			if (this.errorHandler) this.errorHandler(error)
			throw e
		}
	}
}

export const http = new Http()
