export type ActivityCheckProps = {
	thresholdSeconds: number,
	onInactivity: () => void
}

export class ActivityCheck {
	private inactiveCount = 0
	private inactiveTimerHandle: any = null
	private readonly resetTimer = () => this.inactiveCount = 0
	public threshold = 0
	public thresholdHandler: () => void
	private readonly windowEvents: (keyof WindowEventMap)[] = [
		'load',
		'mousedown',
		'mousemove',
		'click',
		'keydown',
		'touchstart'
	]

	constructor(opts: ActivityCheckProps) {
		this.threshold = opts.thresholdSeconds
		this.thresholdHandler = opts.onInactivity
	}

	incrementTimer() {
		this.inactiveCount++
		if (this.inactiveCount > this.threshold) {
			this.thresholdHandler()
			this.inactiveCount = 0
		}
	}

	install() {
		this.inactiveTimerHandle = setInterval(() => this.incrementTimer(), 1000)
		for (const ev of this.windowEvents) {
			window.addEventListener(ev, this.resetTimer)
		}
	}

	uninstall() {
		if (this.inactiveTimerHandle) {
			clearInterval(this.inactiveTimerHandle)
			this.inactiveTimerHandle = null
		}
		for (const ev of this.windowEvents) {
			window.addEventListener(ev, this.resetTimer)
		}
	}
}