import { useContext, useState } from 'react'
import { browserName, BrowserTypes } from 'react-device-detect'
import {
	ChevronRightIcon,
	ChromeIcon,
	EdgeIcon,
	FirefoxIcon,
} from '../../assets/icons'
import { Button, InfoText, TextBold, TextLight, Title } from '../../components'
import Dialog from '../../components/modal/Dialog'
import { AppContext } from '../../context/app-context'
import { useTitle } from '../../hooks/useTitle'
import { getExtensionAPI } from '../../utils/extension'
import { useLogger } from '../../utils/logging-service'
import { tContext } from '../../utils/cmsTranslations'
import { AvailableStep } from '../../utils/stepUtil'

const t = tContext('registration.extension')

export default function Step1() {
	const [isOpen, setIsOpen] = useState(false)
	const { ticket, setActiveStep } = useContext(AppContext)
	const [error, setError] = useState(false)
	const logger = useLogger()
	useTitle('Installeer extensie')

	const getExtensionIcon = () => {
		switch (browserName) {
			case BrowserTypes.Chrome:
				return (
					<div>
						<ChromeIcon />
						<TextBold classNameOverride="pl-3 mb-2" size="md">
							{t('browserExplanationTitleChrome')}
						</TextBold>
						<TextLight classNameOverride="pl-3" size="md">
							<span>{t('textBrowserExplanationTextChrome')}</span>
						</TextLight>
					</div>
				)
			case BrowserTypes.Edge:
				return (
					<div>
						<EdgeIcon />
						<TextLight classNameOverride="pl-3" size="md">
							<span>{t('textBrowserExplanationTextEdge')}</span>
						</TextLight>
					</div>
				)
			case BrowserTypes.EdgeChromium:
				return (
					<div>
						<EdgeIcon />
						<TextLight classNameOverride="pl-3" size="md">
							<span>{t('textBrowserExplanationTextEdgeChromium')}</span>
						</TextLight>
					</div>
				)
			case BrowserTypes.Firefox:
				return (
					<div>
						<FirefoxIcon />
						<TextLight classNameOverride="pl-3 mt-2" size="md">
							<span>{t('textBrowserExplanationTextFirefox')}</span>
						</TextLight>
					</div>
				)
			default:
				return null
		}
	}

	const onClick = () => {
		;(async () => {
			try {
				setIsOpen(false)
				const aqopiExtensionApi = getExtensionAPI()
				await aqopiExtensionApi.install()

				setActiveStep(AvailableStep.registrationAgreement)
				logger.log('extension', 'installed succesfully', ticket)
			} catch (err: any) {
				if (err && err.message == 'Extension already installed.') {
					setActiveStep(AvailableStep.registrationAgreement)
					logger.logError(
						{ name: 'extension', message: 'Extension already installed' },
						ticket
					)
				} else {
					console.log(err)
					setError(true)
					logger.logError(
						{ name: 'extension', message: 'Extension install failed' },
						ticket
					)
				}
			}
		})()
	}

	function renderInstallModal() {
		return (
			<Dialog
				title={t('installModalTitle')}
				open={isOpen}
				setOpen={setIsOpen}
				disableAutoClose
			>
				{getExtensionIcon()}

				<Button
					title={'Ok'}
					classNameOverride={'py-[15px] px-[61px] mt-6'}
					onClick={onClick}
				/>
			</Dialog>
		)
	}

	function renderErrorModal() {
		return (
			<Dialog title={t('errorModalTitle')} open={error} setOpen={setError}>
				<p>{t('textErrorModalText')}</p>

				<Button
					title={t('buttonErrorModal.title')}
					classNameOverride="mt-6"
					onClick={() => setError(false)}
				/>
			</Dialog>
		)
	}

	return (
		<>
			{isOpen && renderInstallModal()}

			{error && renderErrorModal()}

			<div className="flex flex-col items-start w-full h-full">
				<Title classNameOverride="mb-6">{t('title')}</Title>

				<p className="mb-6">{t('text')}</p>

				<InfoText textId="home" classNameOverride="mb-6">
					{t('buttonMoreInfo.title')}
				</InfoText>

				<Button
					title={t('buttonInstall.title')}
					icon={<ChevronRightIcon />}
					onClick={() => setIsOpen(true)}
				/>
			</div>
		</>
	)
}
