import React, { useState, useMemo } from 'react'
import clsx from 'clsx'
import { InfoIcon } from '../../assets/icons'
import Dialog from '../modal/Dialog'
import { TextId, getDataByTextId } from './infoTextData'

type Props = {
	children: string
	classNameOverride?: string
	textId: TextId
}

export const InfoText: React.FC<Props> = ({
	children,
	classNameOverride,
	textId,
}) => {
	const [open, setOpen] = useState(false)
	const { title, content } = useMemo(() => getDataByTextId(textId), [textId])

	return (
		<>
			<button
				type="button"
				className={clsx(
					'text-sm font-semibold text-left text-indigo-blue-800 flex flex-row items-center cursor-pointer',
					classNameOverride
				)}
				onClick={(e) => {
					e.preventDefault()
					setOpen(true)
				}}
			>
				<span aria-hidden="true" className="shrink-0">
					<InfoIcon color="currentColor" />
				</span>

				<span className="ml-2">{children}</span>
			</button>

			<Dialog open={open} setOpen={setOpen} title={title} showCloseButton>
				<div>{content}</div>
			</Dialog>
		</>
	)
}

export const InfoTextTranslated: React.FC<{ textId: TextId }> = ({
	textId,
}) => {
	const { title } = useMemo(() => getDataByTextId(textId), [textId])

	return <InfoText textId={textId}>{title}</InfoText>
}
