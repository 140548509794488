import React from 'react'

type Props = React.SVGProps<SVGSVGElement> & { color?: string }

export function MailIcon({ color, ...props }: Props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 16 16"
			fill="none"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3.33344 3.99999C3.15662 3.99999 2.98705 4.07023 2.86203 4.19525C2.73701 4.32028 2.66677 4.48985 2.66677 4.66666V11.3333C2.66677 11.5101 2.73701 11.6797 2.86203 11.8047C2.98705 11.9298 3.15662 12 3.33344 12H12.6668C12.8436 12 13.0131 11.9298 13.1382 11.8047C13.2632 11.6797 13.3334 11.5101 13.3334 11.3333V4.66666C13.3334 4.48985 13.2632 4.32028 13.1382 4.19525C13.0131 4.07023 12.8436 3.99999 12.6668 3.99999H3.33344ZM1.91922 3.25244C2.29429 2.87737 2.803 2.66666 3.33344 2.66666H12.6668C13.1972 2.66666 13.7059 2.87737 14.081 3.25244C14.4561 3.62752 14.6668 4.13622 14.6668 4.66666V11.3333C14.6668 11.8638 14.4561 12.3725 14.081 12.7475C13.7059 13.1226 13.1972 13.3333 12.6668 13.3333H3.33344C2.803 13.3333 2.29429 13.1226 1.91922 12.7475C1.54415 12.3725 1.33344 11.8638 1.33344 11.3333V4.66666C1.33344 4.13622 1.54415 3.62752 1.91922 3.25244Z"
				fill={color ?? '#000'}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M1.44541 4.29685C1.64965 3.99049 2.06356 3.90771 2.36991 4.11195L8.00011 7.86541L13.6303 4.11195C13.9367 3.90771 14.3506 3.99049 14.5548 4.29685C14.759 4.6032 14.6763 5.01711 14.3699 5.22135L8.36991 9.22135C8.14598 9.37064 7.85424 9.37064 7.63031 9.22135L1.63031 5.22135C1.32396 5.01711 1.24118 4.6032 1.44541 4.29685Z"
				fill={color ?? '#000'}
			/>
		</svg>
	)
}
