import { InferType, object, boolean, string, mixed } from 'yup'
import { tContext } from '../../utils/cmsTranslations'
import { numbersOnlyRegex } from '../../utils/regexpHelpers'
import { EnergyType } from '../../types/api'
import {
	NAMES_ENECO_VARIANTS,
	checkEnecoVariant,
	checkGenericSanity,
	ValidationResult,
	supplierChecks,
} from '../../utils/customer-number-utils'

const t = tContext('validation')

const energyDataFormSubSchema = () =>
	object({
		energyType: mixed<EnergyType>()
			.oneOf(Object.values(EnergyType))
			.required(t('validationGenericRequired')),
		supplier: string().required(t('validationEnergySupplierRequired')),
		contractHolderName: string().required(
			t('validationEnergySupplierContractHolderRequired')
		),
		advanceAdvice: string().test(
			'is-valid-advance-advice',
			t('validationAdvanceAdviceInvalid'),
			(value) =>
				numbersOnlyRegex.test(typeof value === 'undefined' ? '' : value)
		),
		actualAdvance: string()
			.required(t('validationActualAdvanceRequired'))
			.test(
				'is-valid-advance-advice',
				t('validationActualAdvanceInvalid'),
				(value) => numbersOnlyRegex.test(value)
			),
		clientNumber: string()
			.ensure()
			.when('supplier', {
				is: (supplier: string | undefined) => !!supplier,
				then: () =>
					string()
						.required(t('validationGenericRequired'))
						.test({
							name: 'is-valid-client-number',
							skipAbsent: true,
							test(value, ctx) {
								const { supplier } = ctx.parent
								let supplierCheck: ValidationResult = checkGenericSanity

								if (NAMES_ENECO_VARIANTS.includes(supplier)) {
									supplierCheck = checkEnecoVariant
								} else if (Object.keys(supplierChecks).includes(supplier)) {
									supplierCheck =
										supplierChecks[supplier as keyof typeof supplierChecks]
								}

								if (typeof value === 'string') {
									const result = supplierCheck(value, supplier)

									if (typeof result === 'string') {
										return ctx.createError({ message: result })
									}

									return result
								}

								return true
							},
						}),
				otherwise: () => string().required(t('validationGenericRequired')),
			}),
	})

export const energyDataFormSchema = object({
	primary: energyDataFormSubSchema(),
	secondary: energyDataFormSubSchema().notRequired().default(null),
	isSecondaryAdded: boolean().required(),
})

export interface EnergyDataFormSupplierDataFields {
	energyType: EnergyType
	supplier: string
	contractHolderName: string
	advanceAdvice?: string
	actualAdvance: string
	clientNumber: string
}

export type EnergyDataFormFields = InferType<typeof energyDataFormSchema>
