import { useContext, useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import {
	AqopiSession,
	Blob,
	ConfigEndpoint,
	ConfigId,
	Config,
	QueryStatus,
} from '../types/aqopi'
import { AppContext } from '../context/app-context'
import {
	householdConfigs,
	householdConfigsGemachtigd,
	incomeConfigs,
	incomeConfigsGemachtigd,
} from '../utils/extension'
import { ProcessFailureType, QueryFailureType } from '../utils/failures'
import {
	finalizeInkomenQuery,
	finalizeToeslagenQuery,
} from '../utils/noodfonds-service-main'
import { HouseholdData, IncomeData } from '../types/api'

type Stage = 'input' | 'income'
const SKIP_LOGGING = process.env.REACT_APP_SKIP_LOGGING === 'true'

const SESSION_ID = uuidv4()

export function useAqopi(stage?: Stage) {
	const aqopiExtensionApi = window.brondata['extension.api'].extensionApi
	const { ticket, aqopiSession, setAqopiSession, isGemachtigd, householdData } =
		useContext(AppContext)
	const [configs, setConfigs] = useState<Config[]>([])
	const [result, setResult] = useState<IncomeData | HouseholdData | null>(null)
	const [sessionBlobs, setSessionBlobs] = useState<Blob[]>([])

	useEffect(() => {
		if (stage === 'income') {
			setConfigs(isGemachtigd ? incomeConfigsGemachtigd : incomeConfigs)
		} else if (stage === 'input') {
			setConfigs(isGemachtigd ? householdConfigsGemachtigd : householdConfigs)
		}
	}, [])

	const initExtension = async () => {
		const urlParams = new URLSearchParams(window.location.search)
		const extensionIdOverride = urlParams.get('extension-id')
		const configEndpoint = process.env.REACT_APP_IWIZE_ENV as ConfigEndpoint

		await aqopiExtensionApi
			.init(
				{
					chromeParams: {
						installUrl: process.env.REACT_APP_CHROME_EXTENSION_URL,
						extensionId: extensionIdOverride
							? extensionIdOverride
							: process.env.REACT_APP_CHROME_EXTENSION_ID,
						configEndpoint,
						debug: true,
					},
					firefoxParams: {
						installUrl: process.env.REACT_APP_FIREFOX_EXTENSION_URL,
						installParams: {},
						configEndpoint,
						debug: false,
					},
					edgeParams: {
						installUrl: process.env.REACT_APP_EDGE_EXTENSION_URL,
						extensionId: extensionIdOverride
							? extensionIdOverride
							: process.env.REACT_APP_EDGE_EXTENSION_ID,
						configEndpoint,
						debug: false,
					},
				},
				window['aqopi.parser']['prepare'].ParserFactory,
				window['aqopi.parser']['fin-advies.process'].getParser(),
				!!extensionIdOverride, // uninstallSuppress
				false,
				{
					sessionId: SESSION_ID,
					stickyCredentials: false,
					env: process.env.REACT_APP_ENV || 'dev',
					customerId: 'noodfonds-web',
				}
			)
			.then(() => console.log('Extension initialized'))
			.catch((err: any) => {
				console.error(
					'An error occured during extension API initialization',
					err
				)
			})
	}

	const updateConfigStatus = (
		configId: ConfigId,
		status: QueryStatus,
		failureType?: QueryFailureType | ProcessFailureType
	) => {
		setConfigs([
			...configs.map((c) => {
				if (c.id === configId) {
					return { ...c, status, failureType }
				} else return c
			}),
		])
	}

	const prepareSession = (configs: Config[]): AqopiSession => {
		if (!aqopiSession) {
			const newSession = aqopiExtensionApi.startQuerySession(
				configs.map((c) => ({ id: c.id, data: c.data })),
				SKIP_LOGGING ? undefined : ticket
			)
			setAqopiSession(newSession)
			return newSession
		}
		return aqopiSession
	}

	const resetSession = () => {
		setResult(null)
		setAqopiSession(null)
		setSessionBlobs([])
		setConfigs([
			...configs.map((config) => ({
				...config,
				status: QueryStatus.Untouched,
				failureType: undefined,
			})),
		])
	}

	const uninstallExtension = () => {
		try {
			aqopiExtensionApi?.uninstall()
		} catch (e) {
			console.log(e)
		}
	}

	const installExtension = async (): Promise<boolean> => {
		try {
			await aqopiExtensionApi.install()
			return true
		} catch (e) {
			console.error(e)
			return false
		}
	}

	const handleInputQueryResult = (blobs: Blob[], currentConfigId: ConfigId) => {
		finalizeToeslagenQuery(blobs[0], ticket, householdData)
			.then((res) => {
				setResult(res)
				updateConfigStatus(currentConfigId, QueryStatus.Success)
			})
			.catch((err) => {
				updateConfigStatus(
					currentConfigId,
					QueryStatus.Failed,
					ProcessFailureType.FinalizeToeslagenFailed
				)
				console.log('Err: ', err)
			})
	}

	const handleIncomeQueryResult = (
		blobs: Blob[],
		currentConfigId: ConfigId,
		currentPersonId: string,
		isApplicant?: boolean
	) => {
		const uwvStep = blobs.every((res) => res.data.parserId.includes('uwv-'))
		const mbdStep = blobs.some((res) => res.data.parserId.includes('mbd-'))
		if (uwvStep) {
			if (!blobs.length) {
				console.log('UWV returned 0 blobs')
				updateConfigStatus(currentConfigId, QueryStatus.Success)
			} else if (
				!blobs.find((blob) => blob.data.parserId === 'uwv-verzekeringsbericht')
			) {
				updateConfigStatus(
					currentConfigId,
					QueryStatus.Failed,
					QueryFailureType.UwvIncomplete
				)
			} else {
				updateConfigStatus(currentConfigId, QueryStatus.Success)
			}
		} else if (mbdStep && currentPersonId) {
			finalizeInkomenQuery(
				blobs.map((el) => ({ data: el.data })),
				ticket,
				currentPersonId,
				isApplicant ? householdData.mijnHuishoudenParseResult : null
			)
				.then((res) => {
					setResult(res)
					updateConfigStatus(currentConfigId, QueryStatus.Success)
				})
				.catch((err) => {
					console.log('Err: ', err)
					updateConfigStatus(currentConfigId, QueryStatus.Failed, err)
				})
		}
	}

	const startQuery = (currentPersonId?: string, isApplicant?: boolean) => {
		let currentConfigId: ConfigId
		const queryEventQueue = prepareSession(configs)

		queryEventQueue
			.onStartQuery((cfgId: ConfigId) => {
				currentConfigId = cfgId
				updateConfigStatus(cfgId, QueryStatus.Active)
			})
			.onAuthenticate((authenticateHandler: any) => {
				authenticateHandler()
			})
			.onAuthenticated((authenticatedHandler: any) => {
				authenticatedHandler()
			})
			.onQuerySuccess((result: { blobList: Blob[] }) => {
				const blobList = sessionBlobs.concat(result.blobList)
				setSessionBlobs(blobList)
				if (stage === 'input') {
					handleInputQueryResult(blobList, currentConfigId)
				} else if (stage === 'income' && currentPersonId) {
					handleIncomeQueryResult(
						blobList,
						currentConfigId,
						currentPersonId,
						isApplicant
					)
				}
			})
			.onQueryFailure((failureType: QueryFailureType) => {
				updateConfigStatus(currentConfigId, QueryStatus.Failed, failureType)
			})
			.onDone(() => {
				setAqopiSession(null)
			})

		aqopiExtensionApi.next()
	}

	return {
		initExtension,
		installExtension,
		resetSession,
		uninstallExtension,
		startQuery,
		configs,
		result,
	}
}
