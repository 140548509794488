import { useCallback } from 'react'
import { useNavigate } from 'react-router'
import { Button, ListWithIcons, Title } from '../../components'
import RedirectFirstPage from '../../routes/RedirectFirstPage'
import { ChevronRightIcon } from '../../assets/icons'
import { tContext } from '../../utils/cmsTranslations'
import { getRoute } from '../../utils/routeUtils'
import { AvailableStep } from '../../utils/stepUtil'
import { Video } from '../../components/Video/Video'
import { useDisableBackButton } from '../../hooks/useDisableBackButton'

const t = tContext('energy.intro')

export const EnergyIntro = () => {
	useDisableBackButton()

	const navigate = useNavigate()

	const onClick = useCallback(() => {
		navigate(getRoute(AvailableStep.energyData), { replace: true })
	}, [navigate])

	return (
		<RedirectFirstPage>
			<div className="flex flex-col justify-start items-start w-full">
				<Title classNameOverride="mb-8">{t('title')}</Title>

				<Video tag="energy" />

				<ListWithIcons listKey="energy.intro.list.items" />

				<Button
					onClick={onClick}
					title={t('buttonNext.title')}
					icon={<ChevronRightIcon />}
				/>
			</div>
		</RedirectFirstPage>
	)
}
