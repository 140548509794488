import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { ActivityIndicatorIcon, ChevronRightIcon } from '../../assets/icons'
import { Button, Notice, Title } from '../../components'
import { InfoTable } from '../../components/info-table/InfoTable'
import { AppContext } from '../../context/app-context'
import { useDisableBackButton } from '../../hooks/useDisableBackButton'
import { useTitle } from '../../hooks/useTitle'
import RedirectFirstPage from '../../routes/RedirectFirstPage'
import {
	ApplicationStatus,
	DataObject,
	EnergyData,
	PeriodEnergyData,
} from '../../types/api'
import { checkApplication } from '../../utils/noodfonds-service-helpers'
import { AvailableStep, useSetActiveStep } from '../../utils/stepUtil'
import { t } from '../../utils/cmsTranslations'
import { Video } from '../../components/Video/Video'

export default function ResultPage() {
	useTitle('Aanvraag afronden')
	useDisableBackButton()
	useSetActiveStep(AvailableStep.overview)

	const navigate = useNavigate()
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [errorMessage, setErrorMessage] = useState<{
		title: string
		message: string
	} | null>(null)
	const [decisionString, setDecisionString] = useState('')

	const {
		householdData,
		energyProviders,
		ticket,
		email,
		phoneNumber,
		savedIncomeData,
		energyData,
		isGemachtigd,
		gemachtigdeClientFields,
	} = useContext(AppContext)

	async function checkPeriods() {
		setErrorMessage(null)

		const results = await Promise.all(
			energyData.map((periodData) => checkPeriod(periodData))
		)
		const submissionData = results.map((result) => ({ ...result, email }))
		const queryString = results
			.map((result, p) => `${energyData[p].period.val}=${result.status}`)
			.join('&')

		setDecisionString(queryString)

		return submissionData
	}

	const checkPeriod = async (periodData: PeriodEnergyData) => {
		const contractData = periodData.data.map((item) => mapContractData(item))

		const data = {
			householdData: householdData,
			contractData: contractData,
			period: periodData.period.val,
			incomeData: savedIncomeData,
		}

		return await handleApplicationCheck(
			ticket,
			email,
			phoneNumber,
			isGemachtigd,
			data
		)
	}

	function mapContractData(item: EnergyData) {
		const providerCode = (supplier: string) =>
			Object.keys(energyProviders.suppliers).find(
				(code) =>
					energyProviders.suppliers[code].toUpperCase() ===
					supplier?.toUpperCase()
			)

		return {
			energiemaatschappij: {
				code: providerCode(item.supplier) || 'manueelIngevoerd',
				naam: item.supplier,
			},
			klantnummer: item.clientNumber,
			contracthouderId:
				item.selectedContractHolder?.id || householdData.personen[0].id,
			// type: item.isPrimary ? 'eerste' : 'tweede',
			type: item.energyType,
			huidigVoorschot: Number(item.actualAdvance),
			adviesVoorschot: Number(item.advanceAdvice),
			...(item.paymentAgreement && {
				betalingsregelingMaandbedrag: Number(item.paymentAgreement),
			}),
		}
	}

	const handleApplicationCheck = async (
		ticket: string,
		email: string,
		phoneNumber: string,
		gemachtigd: boolean,
		data: Partial<DataObject>
	) => {
		const gemachtigdeExtraFields =
			gemachtigd && gemachtigdeClientFields
				? {
					gemachtigdeEmailadres: gemachtigdeClientFields.email,
					gemachtigdeNaam: gemachtigdeClientFields.name,
					gemachtigdeTelefoonnummer: gemachtigdeClientFields.phoneNumber,
				}
				: undefined

		return await checkApplication(
			ticket,
			email,
			phoneNumber,
			gemachtigd,
			data,
			gemachtigdeExtraFields
		)
	}

	const submitApplications = () => {
		if (savedIncomeData.length) {
			setIsSubmitting(true)
			checkPeriods()
				.then((data) => {
					if (data.length === 0 || data[0].status === ApplicationStatus.FOUT) {
						setIsSubmitting(false)
						setErrorMessage({
							title: t('send.check.feedbackError.title'),
							message: t('send.check.feedbackError.text'),
						})
					}
				})
				.catch((err) => {
					setIsSubmitting(false)
					console.error(err)
					setErrorMessage({
						title: t('send.check.feedbackError.title'),
						message: t('send.check.feedbackError.text'),
					})
				})
		}
	}

	useEffect(() => {
		if (decisionString && !decisionString.includes('FOUT')) {
			navigate(
				{
					pathname: '/decision',
					search: `?${decisionString}`,
				},
				{ replace: true }
			)
		}
	}, [decisionString])

	const isLoading = isSubmitting && decisionString === ''

	return (
		<RedirectFirstPage>
			<div className="w-full h-full">
				<div className="flex flex-col items-center h-full">
					<div className="flex flex-col items-start w-full h-full">
						{errorMessage && (
							<Notice
								noticeType="error"
								classNameOverride="mb-8"
								title={errorMessage.title}
								text={errorMessage.message}
							/>
						)}

						<Video tag="send" />

						<Title classNameOverride="mb-4">{t('send.check.title')}</Title>

						{energyData.map((periodEnergyData) => (
							<InfoTable
								key={periodEnergyData.period.val}
								title={t('send.check.checkSection.title')}
								periodEnergyData={periodEnergyData}
								householdData={householdData}
							/>
						))}

						{isLoading ? (
							<ActivityIndicatorIcon
								color="currentColor"
								className="animate-spin text-indigo-blue-800 mb-3 mt-[52px]"
							/>
						) : (
							<Button
								// disabled={checkResults.every(
								// 	(c) => c.status === ApplicationStatus.FOUT
								// )}
								title={t('send.check.buttonSubmit.title')}
								icon={<ChevronRightIcon />}
								onClick={submitApplications}
							/>
						)}
					</div>
				</div>
			</div>
		</RedirectFirstPage>
	)
}
