import React from 'react'

type Props = React.SVGProps<SVGSVGElement> & { color?: string }

export function LockClosedIcon({ color, ...props }: Props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			fill="none"
			{...props}
		>
			<path
				fill={color ?? '#000'}
				fillRule="evenodd"
				d="M12 4a3 3 0 0 0-3 3v3h6V7a3 3 0 0 0-3-3Zm5 6V7A5 5 0 0 0 7 7v3a3 3 0 0 0-3 3v6a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3v-6a3 3 0 0 0-3-3ZM7 12a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1H7Zm3.586 2.586a2 2 0 1 1 2.828 2.828 2 2 0 0 1-2.828-2.828Z"
				clipRule="evenodd"
			/>
		</svg>
	)
}
