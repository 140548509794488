import React from 'react'

type Props = React.SVGProps<SVGSVGElement> & { color?: string }

export function PeopleIcon({ color, ...props }: Props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			fill="none"
			{...props}
		>
			<path
				fill={color ?? '#000'}
				fillRule="evenodd"
				d="M5.464 3.464a5 5 0 1 1 7.072 7.071 5 5 0 0 1-7.072-7.07ZM9 4a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm6.031-1.118a1 1 0 0 1 1.217-.72 5 5 0 0 1 0 9.687 1 1 0 0 1-.496-1.938 3 3 0 0 0 0-5.812 1 1 0 0 1-.72-1.217ZM7 16a3 3 0 0 0-3 3v2a1 1 0 1 1-2 0v-2a5 5 0 0 1 5-5h4a5 5 0 0 1 5 5v2a1 1 0 1 1-2 0v-2a3 3 0 0 0-3-3H7Zm10.032-1.1a1 1 0 0 1 1.218-.718A5 5 0 0 1 22 18.994V21a1 1 0 1 1-2 0v-1.997a3 3 0 0 0-2.25-2.885 1 1 0 0 1-.718-1.218Z"
				clipRule="evenodd"
			/>
		</svg>
	)
}
