import React from 'react'

type Props = React.SVGProps<SVGSVGElement> & { color?: string }

export function EnergyIcon({ color, ...props }: Props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			fill="none"
			{...props}
		>
			<path
				fill={color ?? '#000'}
				fillRule="evenodd"
				d="M10.617 3.076a1 1 0 0 1 1.09.217c.657.657 1.48 2.094 1.888 3.73.341 1.368.426 3.02-.237 4.567a.373.373 0 0 0 .055-.019c.116-.046.326-.176.634-.52.305-.34.665-.842 1.094-1.563a1 1 0 0 1 1.606-.153c.573.644 1.125 1.622 1.531 2.607.405.98.722 2.104.722 3.058a7 7 0 0 1-14 0c0-2.01.868-4.243 2.283-5.697l.098-.1C8.607 7.945 10 6.518 10 4a1 1 0 0 1 .617-.924Zm.853 3.803c-.655 1.676-1.782 2.827-2.6 3.662l-.154.157L8 10l.717.697C7.68 11.763 7 13.49 7 15a5 5 0 1 0 10 0c0-.578-.21-1.423-.57-2.296a10.252 10.252 0 0 0-.422-.898c-.16.214-.317.407-.472.58-.42.47-.872.84-1.381 1.043-1.159.463-2.115-.098-2.792-.658a1 1 0 0 1-.192-1.33c.783-1.159.82-2.583.484-3.934a7.915 7.915 0 0 0-.185-.628Z"
				clipRule="evenodd"
			/>
		</svg>
	)
}
