import { useContext, useEffect } from 'react'
import { AppContext } from '../context/app-context'
import { NavigateFunction, useNavigate } from 'react-router'
import { tContext } from './cmsTranslations'
import { useLogNavigation } from './navigation-logging'

export enum MainSteps {
	'Sneltest' = -1,
	'Registratie' = 0,
	'Huishouden' = 1,
	'Inkomen' = 2,
	'Energie' = 3,
	'Uitslag' = 4,
}

export const MAIN_STEPS = [
	MainSteps.Registratie,
	MainSteps.Huishouden,
	MainSteps.Inkomen,
	MainSteps.Energie,
	MainSteps.Uitslag,
]

export type SubStepConfig = {
	currentStep: number
	maxSteps: number
	showIndicator: boolean
}

const getCurrentStepForRegistration = (
	overallStep: AvailableStep,
	isGemachtigd: boolean
) => {
	const isGemachtigdStep = isGemachtigd ? 1 : 0
	switch (overallStep) {
		case AvailableStep.registrationStart:
		case AvailableStep.registrationEmail:
			return 0
		case AvailableStep.registrationValidateEmail:
			return 1
		case AvailableStep.registrationExtraDetails:
			return 2
		case AvailableStep.registrationPhoneNumber:
			return 2 + isGemachtigdStep
		case AvailableStep.registrationValidatePhoneNumber:
			return 3 + isGemachtigdStep
		case AvailableStep.registrationExtension:
			return 4 + isGemachtigdStep
		case AvailableStep.registrationAgreement:
			return 5 + isGemachtigdStep
		default:
			return 0
	}
}

const getCurrentStepForHuishouden = (overallStep: AvailableStep) => {
	switch (overallStep) {
		case AvailableStep.inputHousehold:
			return 0
		case AvailableStep.people:
			return 1
		default:
			return 0
	}
}

export const getSubStepConfigByMainStepAndOverallStep = (
	mainStep: MainSteps,
	overallStep: AvailableStep,
	isGemachtigd: boolean
): SubStepConfig => {
	switch (mainStep) {
		case MainSteps.Registratie:
			return {
				currentStep: getCurrentStepForRegistration(overallStep, isGemachtigd),
				maxSteps: 6 + (isGemachtigd ? 1 : 0),
				showIndicator: AvailableStep.registrationStart !== overallStep,
			}

		case MainSteps.Huishouden:
			return {
				currentStep: getCurrentStepForHuishouden(overallStep),
				maxSteps: 2,
				showIndicator: AvailableStep.householdIntro !== overallStep,
			}

		case MainSteps.Energie:
			return {
				currentStep: 0,
				maxSteps: 1,
				showIndicator: AvailableStep.energyIntro !== overallStep,
			}

		default:
			return {
				currentStep: 0,
				maxSteps: 0,
				showIndicator: false,
			}
	}
}

export enum AvailableStep {
	'none' = -1,
	'registrationStart' = 0,
	'registrationEmail' = 1,
	'registrationValidateEmail' = 2,
	'registrationExtraDetails' = 3,
	'registrationPhoneNumber' = 4,
	'registrationValidatePhoneNumber' = 5,
	'registrationExtension' = 6,
	'registrationAgreement' = 7,
	'householdIntro' = 8,
	'inputHousehold' = 9,
	'incomeIntro' = 10,
	'review' = 11,
	'people' = 12,
	'energyIntro' = 13,
	'energyData' = 14,
	'overview' = 15,
	'finish' = 16,
}

export const getMainStepByActiveStep = (step: AvailableStep) => {
	switch (step) {
		case AvailableStep.registrationStart:
		case AvailableStep.registrationEmail:
		case AvailableStep.registrationValidateEmail:
		case AvailableStep.registrationPhoneNumber:
		case AvailableStep.registrationValidatePhoneNumber:
		case AvailableStep.registrationExtension:
		case AvailableStep.registrationAgreement:
			return MainSteps.Registratie

		case AvailableStep.householdIntro:
		case AvailableStep.inputHousehold:
		case AvailableStep.people:
			return MainSteps.Huishouden

		case AvailableStep.incomeIntro:
		case AvailableStep.review:
			return MainSteps.Inkomen

		case AvailableStep.energyIntro:
		case AvailableStep.energyData:
			return MainSteps.Energie

		case AvailableStep.finish:
		case AvailableStep.overview:
			return MainSteps.Uitslag

		default:
			return MainSteps.Registratie
	}
}

export const useSetActiveStep = (
	step: AvailableStep,
	callback?: (navigate: NavigateFunction) => void
) => {
	const { setActiveStep, setMainStep, setBackStep, ticket } =
		useContext(AppContext)
	const navigate = useNavigate()

	useLogNavigation(step, ticket)

	useEffect(() => {
		setActiveStep(step)
		setMainStep(getMainStepByActiveStep(step))

		if (callback) {
			setBackStep(() => () => void callback(navigate))
		} else {
			setBackStep(null)
		}
	}, [])
}

const t = tContext('overview')

const mainStepTexts = [
	t('step2.title'),
	t('step3.title'),
	t('step4.title'),
	t('step5.title'),
	t('step6.title'),
]

const mainStepDescriptions = [
	t('step2.text'),
	t('step3.text'),
	t('step4.text'),
	t('step5.text'),
	t('step6.text'),
]

export const getMainStepText = (step: MainSteps) => {
	return mainStepTexts[step]
}

export const getMainStepDescription = (step: MainSteps) => {
	return mainStepDescriptions[step]
}
