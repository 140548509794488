export const formatDutchDate = (dateStr: string): Date => {
	const dateArray = dateStr.split('-')

	return new Date(`${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`)
}

export const formatDate = (input: Date): string => {
	if (!input) return ''

	const year = input.getFullYear()
	const month = (input.getMonth() + 1).toString().padStart(2, '0')
	const day = input.getDate().toString().padStart(2, '0')
	return `${day}.${month}.${year}`
}

export const formatAsLongDate = (input: Date): string => {
	return input.toLocaleDateString('nl-nl', {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
	})
}

export const formatAmount = (input: number | string): string => {
	const arr = input.toString().split('.')
	const num = arr[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')
	const dec = arr[1]
	return dec ? `€ ${num},${dec.padEnd(2, '0')}` : `€ ${num}`
}
