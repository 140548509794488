import clsx from 'clsx'
import {
	DevicesIcon,
	EnergyIcon,
	LicenseIcon,
	LightbulbIcon,
	LockClosedIcon,
	MessageIcon,
	MoneyIcon,
	PeopleIcon,
	PhoneDisabledIcon,
	UserIcon,
} from '../../assets/icons'
import { tAsList } from '../../utils/cmsTranslations'

const IconForList = ({ icon }: { icon: string }) => {
	let Component = null

	switch (icon) {
		case 'contact':
			Component = DevicesIcon
			break

		case 'check':
		case 'users':
			Component = PeopleIcon
			break

		case 'lock':
			Component = LockClosedIcon
			break

		case 'people':
			Component = UserIcon
			break

		case 'energy':
		case 'fire':
			Component = EnergyIcon
			break

		case 'euro':
			Component = MoneyIcon
			break

		case 'license':
			Component = LicenseIcon
			break

		case 'result':
		case 'bulb':
			Component = LightbulbIcon
			break

		case 'message':
			Component = MessageIcon
			break

		case 'phone-disabled':
			Component = PhoneDisabledIcon
			break

		default:
			console.log(icon, 'missing svg icon')
			break
	}
	return Component ? (
		<Component
			aria-hidden="true"
			className="block shrink-0 w-[24px]"
			color="currentColor"
		/>
	) : null
}

export const ListWithIcons = ({
	listKey,
	className,
}: {
	listKey: string
	className?: string
}) => {
	return (
		<ul
			className={clsx(
				'max-w-[80%] list-none list-outside font-normal mb-8',
				className
			)}
		>
			{tAsList(listKey)
				.filter((item) => item.text !== 'leeg')
				.map((item, index) => (
					<li
						className="flex gap-6 items-center justify-start pb-6"
						key={index}
					>
						<IconForList icon={item.icon} />
						<div>{item.text}</div>
					</li>
				))}
		</ul>
	)
}
