import { useState } from 'react'
import { DocumentIcon } from '../../assets/icons'
import { SecondaryButton } from '../button/SecondaryButton'
import Dialog from '../modal/Dialog'
import { VIDEOS, VideoTagName } from './data'
import { Button } from '../button/Button'
import { tContext } from '../../utils/cmsTranslations'
import { MarkdownText } from '../MarkdownText/MarkdownText'

type Props = {
	tag: VideoTagName
}

const t = tContext('video')

export const Video = ({ tag }: Props) => {
	const [open, setOpen] = useState(false)
	const { src, altSrc, poster, subtitlesSrc, transcript } = VIDEOS[tag]

	return (
		<div className="w-9/12 mb-8">
			<div className="relative mb-4">
				<video
					controls
					preload="metadata"
					crossOrigin="anonymous"
					poster={poster}
				>
					<source src={src} type="video/mp4" />
					<source src={altSrc} type="video/webm" />
					<track
						kind="captions"
						label="Nederlands"
						srcLang="nl"
						src={subtitlesSrc}
						default
					/>
					<track
						kind="subtitles"
						label="Nederlands"
						srcLang="nl"
						src={subtitlesSrc}
						default
					/>
				</video>
			</div>
			<SecondaryButton
				classNameOverride="text-indigo-blue-800"
				title={t('buttonViewTextVersion.title')}
				variant="small"
				iconPosition="left"
				icon={<DocumentIcon color="currentColor" />}
				onClick={() => setOpen(true)}
			/>
			<Dialog
				title={t('textModalTitle')}
				open={open}
				setOpen={setOpen}
				showCloseButton
			>
				<div className="mb-8">
					<MarkdownText text={t(transcript)} className="text-text" />
				</div>
				<Button title="Sluit tekstversie" onClick={() => setOpen(false)} />
			</Dialog>
		</div>
	)
}
