import { ChevronLeftIcon } from '../../assets/icons'
import { EnergyData, HouseholdData, PeriodEnergyData } from '../../types/api'
import { tContext } from '../../utils/cmsTranslations'
import { convertEnumToText } from '../../utils/energyUtil'
import { formatAmount } from '../../utils/formatDate'
import { SecondaryButton } from '../button/SecondaryButton'
import { TextLight } from '../text-types/TextLight'
import { TableRow } from './TableRow'
import { AvailableStep } from '../../utils/stepUtil'
import { useNavigate } from 'react-router'
import { getRoute } from '../../utils/routeUtils'

type Props = {
	title: string
	householdData: HouseholdData
	periodEnergyData: PeriodEnergyData
}

const t = tContext('send.check')

export const InfoTable = ({
	title,
	householdData,
	periodEnergyData,
}: Props) => {
	const navigate = useNavigate()

	const primarySupplier = periodEnergyData.data.find(
		(supplier) => supplier.isPrimary
	) as EnergyData
	const secondSupplier = periodEnergyData.data.find(
		(supplier) => !supplier?.isPrimary
	)

	const persons = householdData.personen
	const householdCount = persons.length

	const onBackToInputClick = () => {
		navigate(getRoute(AvailableStep.energyData))
	}

	return (
		<div className="w-full mb-8">
			<p className="font-semibold text-lg py-4">{title}</p>

			<TableRow
				title={t('checkSection.household')}
				data={`${householdCount} ${
					householdCount === 1 ? 'volwassene' : 'volwassenen'
				}`}
				showBorder
				showTopBorder
			/>

			<TableRow title={t('checkSection.householdData')} showBorder>
				<div className="my-3 grid grid-cols-1 md:w-1/2 gap-x-16 gap-y-3">
					<div className="flex items-center justify-between">
						<TextLight classNameOverride="text-sm">
							{t('householdDataLabels.houseNumber')}
						</TextLight>

						<p className="text-base font-normal">
							{householdData.huisnummerMetToevoeging}
						</p>
					</div>

					<div className="flex items-center justify-between">
						<TextLight classNameOverride="text-sm">
							{t('householdDataLabels.postalCode')}
						</TextLight>

						<p className="text-base font-normal">{householdData.postcode}</p>
					</div>
				</div>
			</TableRow>

			<TableRow title={t('checkSection.income')} showBorder>
				<div className="my-3">
					{persons.map((el) => (
						<div key={el.id} className="flex justify-start w-full mb-3">
							<TextLight>{el.volledigeNaam}</TextLight>
						</div>
					))}
				</div>
			</TableRow>

			<TableRow
				title={
					secondSupplier
						? 'Energiegegevens eerste leverancier'
						: t('checkSection.energy')
				}
				showBorder
			>
				<div className="my-3 grid grid-cols-1 gap-3">
					{!!secondSupplier && (
						<div className="flex items-center justify-between">
							<TextLight classNameOverride="text-sm">
								{t('energyLabels.contractType')}
							</TextLight>

							<p className="text-base font-normal">
								{convertEnumToText(primarySupplier.energyType)}
							</p>
						</div>
					)}

					<div className="flex items-center justify-between">
						<TextLight classNameOverride="text-sm">
							{t('energyLabels.supplier')}
						</TextLight>

						<p className="text-base font-normal">{primarySupplier.supplier}</p>
					</div>

					<div className="flex items-center justify-between">
						<TextLight classNameOverride="text-sm">
							{t('energyLabels.customerNumber')}
						</TextLight>

						<p className="text-base font-normal">
							{primarySupplier.clientNumber}
						</p>
					</div>

					<div className="flex items-center justify-between">
						<TextLight classNameOverride="text-sm">
							{t('energyLabels.contractHolder')}
						</TextLight>

						<p className="text-base font-normal">
							{primarySupplier.selectedContractHolder?.volledigeNaam}
						</p>
					</div>

					<div className="flex items-center justify-between">
						<TextLight classNameOverride="text-sm">
							{t('energyLabels.actualAdvance')}
						</TextLight>

						<p className="text-base font-normal">
							{Number(primarySupplier.actualAdvance) > 0
								? formatAmount(Number(primarySupplier.actualAdvance))
								: '-'}
						</p>
					</div>
					<div className="flex items-center justify-between">
						<TextLight classNameOverride="text-sm">
							{t('energyLabels.adviceAdvance')}
						</TextLight>

						<p className="text-base font-normal">
							{Number(primarySupplier.advanceAdvice) > 0
								? formatAmount(Number(primarySupplier.advanceAdvice))
								: '-'}
						</p>
					</div>
				</div>

				<div className="mt-5">
					<SecondaryButton
						variant="small"
						classNameOverride="text-indigo-blue-800"
						icon={<ChevronLeftIcon color="currentColor" />}
						title={t('energyLabels.buttonBackToInput.title')}
						onClick={onBackToInputClick}
					/>
				</div>
			</TableRow>

			{secondSupplier && (
				<TableRow title="Energiegegevens tweede leverancier" showBorder>
					<div className="my-3 grid grid-cols-1 gap-3">
						<div className="flex items-center justify-between">
							<TextLight classNameOverride="text-sm">Type contract</TextLight>

							<p className="text-base font-normal">
								{convertEnumToText(secondSupplier.energyType)}
							</p>
						</div>

						<div className="flex items-center justify-between">
							<TextLight classNameOverride="text-sm">
								{t('energyLabels.secondSupplier')}
							</TextLight>

							<p className="text-base font-normal">{secondSupplier.supplier}</p>
						</div>

						<div className="flex items-center justify-between">
							<TextLight classNameOverride="text-sm">
								{t('energyLabels.customerNumber')}
							</TextLight>

							<p className="text-base font-normal">
								{secondSupplier.clientNumber}
							</p>
						</div>

						<div className="flex items-center justify-between">
							<TextLight classNameOverride="text-sm">
								{t('energyLabels.contractHolder')}
							</TextLight>

							<p className="text-base font-normal">
								{secondSupplier.selectedContractHolder?.volledigeNaam}
							</p>
						</div>

						<div className="flex items-center justify-between">
							<TextLight classNameOverride="text-sm">
								{t('energyLabels.actualAdvance')}
							</TextLight>

							<p className="text-base font-normal">
								{Number(secondSupplier.actualAdvance) > 0
									? `€${secondSupplier.actualAdvance}`
									: '-'}
							</p>
						</div>

						<div className="flex items-center justify-between">
							<TextLight classNameOverride="text-sm">
								{t('energyLabels.adviceAdvance')}
							</TextLight>
							<p className="text-base font-normal">
								{Number(secondSupplier.advanceAdvice) > 0
									? `€${secondSupplier.advanceAdvice}`
									: '-'}
							</p>
						</div>
					</div>
				</TableRow>
			)}
		</div>
	)
}
