const BEARER_TOKEN =
	'Bearer fb4cb6f52ee426675b3402a52b1fd8cdc482147f1ab10b14412437bab57e7e99646107ce9fa1e1897c25e857402a627d3b5098f72c0eeb0639bcc150cc8d40c75976021208ef0d5af1548f69bd3d22329383878fa76e8f2b8128df73aca9be4e09a3a01fdd74f3e5c5649b385b788f33ae27321eee2f44d0082ce52284dfd15c'

type FaqResponseItem = {
	title: string
	content: string
	tags: string[]
}

export type FaqItems = Array<{ title: string; content: string }>

export type FaqMappedByTag = Record<string, FaqItems>

export async function getFaqMapping(): Promise<FaqMappedByTag> {
	try {
		const response = await fetch(
			'https://cms.noodfondsenergie.nl/api/filterfaq/',
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: BEARER_TOKEN,
				},
			}
		)

		if (!response.ok) {
			return Promise.reject('faq mapping response not ok')
		}

		const json = await response.json()

		const mapped = json.result.reduce(
			(acc: FaqMappedByTag, curr: FaqResponseItem) => {
				for (const tag of curr.tags) {
					const { tags, ...rest } = curr

					if (tags && acc[tag]) {
						acc[tag].push(rest)
					} else {
						acc[tag] = [rest]
					}
				}

				return acc
			},
			{} as FaqMappedByTag
		)

		if (Object.keys(mapped).length > 0) {
			return Promise.resolve(mapped)
		}

		return Promise.reject('no data returned from faq mapping endpoint')
	} catch (error: any) {
		return Promise.reject(error)
	}
}
