import React from 'react'

type Props = React.SVGProps<SVGSVGElement> & { color?: string }

export function PhoneIcon({ color, ...props }: Props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			fill="none"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6 4C5.73478 4 5.48043 4.10536 5.29289 4.29289C5.11141 4.47438 5.00688 4.71844 5.00033 4.97437C5.23032 8.6183 6.78159 12.0537 9.36394 14.6361C11.9463 17.2184 15.3817 18.7697 19.0256 18.9997C19.2816 18.9931 19.5256 18.8886 19.7071 18.7071C19.8946 18.5196 20 18.2652 20 18V14.677L16.4193 13.2448L15.3575 15.0145C15.0897 15.4608 14.5244 15.6271 14.0577 15.3969C11.6887 14.2285 9.77146 12.3113 8.60314 9.94231C8.37294 9.47556 8.53924 8.91027 8.9855 8.64251L10.7552 7.58067L9.32297 4H6ZM3.87868 2.87868C4.44129 2.31607 5.20435 2 6 2H10C10.4089 2 10.7766 2.24895 10.9285 2.62861L12.9285 7.62861C13.1108 8.08432 12.9354 8.60497 12.5145 8.85749L10.8405 9.86189C11.6644 11.2055 12.7945 12.3356 14.1381 13.1595L15.1425 11.4855C15.395 11.0646 15.9157 10.8892 16.3714 11.0715L21.3714 13.0715C21.751 13.2234 22 13.5911 22 14V18C22 18.7957 21.6839 19.5587 21.1213 20.1213C20.5587 20.6839 19.7957 21 19 21C18.9798 21 18.9595 20.9994 18.9393 20.9982C14.7948 20.7463 10.8858 18.9863 7.94972 16.0503C5.01369 13.1142 3.25371 9.20519 3.00184 5.06066C3.00061 5.04046 3 5.02023 3 5C3 4.20435 3.31607 3.44129 3.87868 2.87868Z"
				fill={color ?? '#000'}
			/>
		</svg>
	)
}
