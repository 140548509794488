import { checkAvailability } from '@iwize-aqopi/brondata-session-availability-check'

import { Config, ConfigId, QueryStatus } from '../types/aqopi'

const householdConfigs: Config[] = [
	{
		id: ConfigId.TOESLAGEN,
		data: ['huishouden'],
		status: QueryStatus.Untouched,
	},
]

const householdConfigsGemachtigd: Config[] = [
	{
		id: ConfigId.TOESLAGEN_GEMACHTIGD,
		data: ['huishouden'],
		status: QueryStatus.Untouched,
	},
]

const incomeConfigs: Config[] = [
	{
		id: ConfigId.UWV,
		data: ['verzekeringsbericht'],
		status: QueryStatus.Untouched,
	},
	{
		id: ConfigId.BELASTINGDIENST,
		data: ['via', 'klantbeeld'],
		status: QueryStatus.Untouched,
	},
]

const incomeConfigsGemachtigd: Config[] = [
	{
		id: ConfigId.UWV_GEMACHTIGD,
		data: ['verzekeringsbericht'],
		status: QueryStatus.Untouched,
	},
	{
		id: ConfigId.BELASTINGDIENST_GEMACHTIGD,
		data: ['via', 'klantbeeld'],
		status: QueryStatus.Untouched,
	},
]

const configs: Config[] = [...householdConfigs, ...incomeConfigs]

const machtigingenConfigs: Config[] = [
	...householdConfigsGemachtigd,
	...incomeConfigsGemachtigd,
]

const checkAqopiAvailability = async (configs: Config[]) => {
	try {
		const availability = await checkAvailability(
			configs.map((item: any) => item.id)
		)
		return availability
	} catch (e) {
		console.log(e)
	}
}

const getExtensionAPI = () => {
	const aq = window.brondata
	const aqopiExtensionApi = aq['extension.api'].extensionApi

	return aqopiExtensionApi
}

export {
	checkAqopiAvailability,
	getExtensionAPI,
	configs,
	machtigingenConfigs,
	incomeConfigs,
	incomeConfigsGemachtigd,
	householdConfigs,
	householdConfigsGemachtigd,
}
