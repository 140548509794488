import { useContext, useEffect } from 'react'
import { AppContext } from '../../context/app-context'
import EnergyDataForm from '../../pageComponents/EnergyDataForm'
import EnergyDataMissingDataMessage from '../../pageComponents/EnergyDataMissingDataMessage'
import { getEmptyPeriodEnergyData } from '../../pageComponents/EnergyDataForm/helpers'
import RedirectFirstPage from '../../routes/RedirectFirstPage'
import { AvailableStep, useSetActiveStep } from '../../utils/stepUtil'

export default function CollectPage() {
	useSetActiveStep(AvailableStep.energyData)

	const { energyData: energyDataFromContext, setEnergyData } =
		useContext(AppContext)

	const energyData = energyDataFromContext?.length
		? energyDataFromContext
		: getEmptyPeriodEnergyData()

	const periodEnergyData = energyData.find((e) => !!e.period.val)

	const period = periodEnergyData?.period.val
	const periodLabel = periodEnergyData?.period.label
	const primarySupplierData = periodEnergyData?.data.find((d) => d.isPrimary)
	const secondarySupplierData = periodEnergyData?.data.find((d) => !d.isPrimary)

	useEffect(() => {
		// update context if it is empty (first time this page is rendered)
		if (energyDataFromContext.length === 0) {
			setEnergyData(energyData)
		}
	}, [energyDataFromContext])

	const isDataMissing = !period || !periodLabel || !primarySupplierData

	return (
		<RedirectFirstPage>
			{isDataMissing ? (
				<EnergyDataMissingDataMessage />
			) : (
				<EnergyDataForm
					isPrimary={true}
					supplierData={primarySupplierData}
					secondarySupplierData={secondarySupplierData}
					period={period}
					periodLabel={periodLabel}
				/>
			)}
		</RedirectFirstPage>
	)
}
