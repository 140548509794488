import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { string, object, ObjectSchema } from 'yup'

import {
	Button,
	SecondaryButton,
	TextLight,
	Title,
	InfoBoxText,
} from '../../components'
import { TextInputControlled } from '../../components/text-input/TextInputControlled'
import { useTitle } from '../../hooks/useTitle'
import { tAsList, tContext, t as tAll } from '../../utils/cmsTranslations'
import { ChevronRightIcon, SyncIcon } from '../../assets/icons'
import { AvailableStep, useSetActiveStep } from '../../utils/stepUtil'
import { numbersOnlyRegex } from '../../utils/regexpHelpers'
import { IS_DEVELOPMENT_ENV } from '../../utils/utils'

const t = tContext('registration.emailVerification')
const tValidation = tContext('validation')

interface Props {
	onSubmit: (code: string) => Promise<any>
	onBack: () => void
	email: string
}

interface FormValues {
	code: string
}

const authenticateCodeSchema: ObjectSchema<FormValues> = object({
	code: string()
		.required(tValidation('validationCodeRequired'))
		.test(
			'len',
			tValidation('validationCodeLength'),
			(val) => val.toString().length === 6
		)
		.test('isNumber', tValidation('validationCodeInvalid'), (val) =>
			numbersOnlyRegex.test(val)
		),
})

const RetryArea: React.FC<{ onClick: () => void }> = ({ onClick }) => {
	const MAX_SECONDS = 60
	const [startTime] = useState(Date.now())
	const [secondsPassed, secondsPassedSet] = useState(0)

	useEffect(() => {
		const intervalId = setInterval(() => {
			const timePassed = Date.now() - startTime
			secondsPassedSet(Math.min(MAX_SECONDS, Math.floor(timePassed / 1000)))

			if (timePassed > MAX_SECONDS * 1000) {
				clearInterval(intervalId)
			}
		}, 1000)

		return () => {
			clearInterval(intervalId)
		}
	}, [startTime])

	const isCountingDown = secondsPassed < MAX_SECONDS

	return (
		<>
			<TextLight>{t('buttonResend.superscript')}</TextLight>

			{isCountingDown ? (
				<>
					<p className="text-text my-4 sr-only">
						{tAll('generic.countDown', { seconds: MAX_SECONDS })}
					</p>
					<p className="text-text my-4" aria-hidden="true">
						{tAll('generic.countDown', {
							seconds: MAX_SECONDS - secondsPassed,
						})}
					</p>
				</>
			) : (
				<SecondaryButton
					onClick={onClick}
					title={t('buttonResend.title')}
					icon={<SyncIcon color="currentColor" />}
				/>
			)}
		</>
	)
}

export const Substep_2: React.FC<Props> = ({ onBack, onSubmit }) => {
	useTitle('Code')
	useSetActiveStep(AvailableStep.registrationValidateEmail)

	const { handleSubmit, formState, register, setError } = useForm<FormValues>({
		resolver: yupResolver(authenticateCodeSchema),
		defaultValues: IS_DEVELOPMENT_ENV ? { code: '000000' } : {},
	})

	const onSubmitInternal = async (fields: FormValues) => {
		const { code } = fields

		onSubmit(code).catch(() => {
			setError('root', {
				type: 'serverError',
				message: t('serverError'),
			})
		})
	}

	return (
		<form
			className="flex flex-col items-start w-full h-full"
			onSubmit={handleSubmit(onSubmitInternal)}
		>
			<Title classNameOverride="mb-6">{t('title')}</Title>

			{formState.errors.root?.type === 'serverError' && (
				<InfoBoxText
					messageType="error"
					text={formState.errors.root.message}
					classNameOverride="max-w-xl mb-8"
				/>
			)}

			<ol className="max-w-[80%] list-decimal list-outside pl-6">
				{tAsList('registration.emailVerification.list.items').map(
					({ text }, index) => (
						<li key={index} className="pb-2">
							{text}
						</li>
					)
				)}
			</ol>

			<div className="w-full md:w-1/3 my-6">
				<TextInputControlled
					{...register('code')}
					aria-required={true}
					title={t('inputCode.label')}
					placeholder={t('inputCode.placeholder')}
					autoComplete="off"
					type="number"
					min="0"
					hint={
						formState.errors.code ? formState.errors.code.message : undefined
					}
					hintVariant={'error'}
				/>
			</div>

			<Button
				data-testid="submit-button"
				title={t('buttonSubmit.title')}
				buttonProps={{ type: 'submit' }}
				classNameOverride="mb-8"
				icon={<ChevronRightIcon />}
			/>

			<RetryArea onClick={onBack} />
		</form>
	)
}
