import React from 'react'
import { assetUrl } from '../../utils/utils'

export const Header: React.FC = () => {
	return (
		<header
			id="header"
			className={
				'z-10 w-full flex items-center justify-between py-4 mb-1 fixed top-0 shadow-[0px_4px_12px_rgba(22,22,22,0.1)] bg-white'
			}
		>
			<span
				className={
					'h-[40px] pl-4 text-[16px] sm:text-[24px] leading-[39px] font-semibold text-indigo-blue-800'
				}
			>
				<img
					src={assetUrl('/logo_noodfonds_energie.svg')}
					alt="Logo Noodfonds Energie"
					className="h-[40px]"
				/>
			</span>
		</header>
	)
}
