import { EnergyType } from '../types/api'

export function getInverseEnergyType(energyType: EnergyType | undefined) {
	if (!energyType) {
		return EnergyType.ELEKTRA_EN_GAS_OF_WARMTE
	}

	return energyType === EnergyType.ELEKTRA
		? EnergyType.GAS_OF_WARMTE
		: EnergyType.ELEKTRA
}

const TEXT_ELEKTRA = 'Elektriciteit'
const TEXT_GAS_OF_WARMTE = 'Gas en/of warmte'
const TEXT_ELEKTRA_EN_GAS_OF_WARMTE = 'Elektriciteit en gas en warmte'

export const convertTextToEnum = (energyTypeAsText: string) => {
	switch (energyTypeAsText) {
		case TEXT_ELEKTRA:
			return EnergyType.ELEKTRA
		case TEXT_GAS_OF_WARMTE:
			return EnergyType.GAS_OF_WARMTE
		default:
			return EnergyType.ELEKTRA_EN_GAS_OF_WARMTE
	}
}

export const convertEnumToText = (energyType: EnergyType) => {
	switch (energyType) {
		case EnergyType.ELEKTRA:
			return TEXT_ELEKTRA
		case EnergyType.GAS_OF_WARMTE:
			return TEXT_GAS_OF_WARMTE
		default:
			return TEXT_ELEKTRA_EN_GAS_OF_WARMTE
	}
}
