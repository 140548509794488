import React from 'react'

type Props = React.SVGProps<SVGSVGElement> & { color?: string }

export function ThumbsUpIcon({ color, ...props }: Props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			fill="none"
			{...props}
		>
			<path
				fill={color ?? '#36369D'}
				fillRule="evenodd"
				d="M12.5 5a1 1 0 0 0-1 1v1a5 5 0 0 1-4 4.899V17a2 2 0 0 0 2 2h7a1 1 0 0 1 .085.004c.087.007.238-.018.43-.21.2-.2.396-.54.508-1.007l.974-4.87A1 1 0 0 0 17.5 12h-3a1 1 0 0 1-1-1V6a1 1 0 0 0-1-1ZM7.1 20.2a1.997 1.997 0 0 1-1.6.8h-2a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3a3 3 0 0 0 3-3V6a3 3 0 1 1 6 0v4h2a3 3 0 0 1 3 3 1 1 0 0 1-.02.196l-1 5-.006.032c-.177.752-.524 1.46-1.044 1.98-.518.518-1.207.841-1.967.792H9.5a4 4 0 0 1-2.4-.8ZM5.5 12h-2v7h2v-7Z"
				clipRule="evenodd"
			/>
		</svg>
	)
}
