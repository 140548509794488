import React from 'react'

export function TrashIcon({ color }: { color?: string }) {
	return (
		<svg
			width="16"
			height="21"
			viewBox="0 0 16 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1 19.663C1 20.231 1.4473 20.6907 2 20.6907H14C14.5527 20.6907 15 20.231 15 19.663V6.30359H1V19.663ZM11 8.35889H13V17.6077L11 18.6354V8.35889ZM7 8.35889H9V17.6077L7 18.6354V8.35889ZM3 8.35889H5V17.6077L3 18.6354V8.35889ZM15 2.19299H12V1.16535C12 0.597363 11.5527 0.137695 11 0.137695H5C4.4473 0.137695 4 0.597363 4 1.16535V2.19299H1C0.4473 2.19299 0 2.65266 0 3.22064V5.27594H1H15H16V3.22064C16 2.65266 15.5527 2.19299 15 2.19299Z"
				fill={color ?? '#D85D18'}
			/>
		</svg>
	)
}
