import React from 'react'

type Props = React.SVGProps<SVGSVGElement> & { color?: string }

export const CrossRoundedIcon: React.FC<Props> = ({ color, ...props }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 48 48"
		fill="none"
		{...props}
	>
		<path
			fill={color ?? '#991B1B'}
			d="M0 24C0 10.745 10.745 0 24 0s24 10.745 24 24-10.745 24-24 24S0 37.255 0 24Z"
		/>
		<path
			fill="#fff"
			fillRule="evenodd"
			d="M17.293 17.293a1 1 0 0 1 1.414 0L24 22.586l5.293-5.293a1 1 0 0 1 1.414 1.414L25.414 24l5.293 5.293a1 1 0 0 1-1.414 1.414L24 25.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L22.586 24l-5.293-5.293a1 1 0 0 1 0-1.414Z"
			clipRule="evenodd"
		/>
	</svg>
)
