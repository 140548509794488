import React from 'react'

type Props = React.SVGProps<SVGSVGElement> & { color?: string }

export const PlusIcon: React.FC<Props> = ({ color, ...props }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		fill="none"
		{...props}
	>
		<path
			fill={color ?? '#1E4BAF'}
			fillRule="evenodd"
			d="M12.255 4.085c.564 0 1.021.457 1.021 1.021v6.128h6.128a1.021 1.021 0 0 1 0 2.043h-6.127v6.127a1.021 1.021 0 0 1-2.043 0v-6.127H5.106a1.021 1.021 0 1 1 0-2.043h6.128V5.106c0-.564.457-1.02 1.021-1.02Z"
			clipRule="evenodd"
		/>
	</svg>
)
