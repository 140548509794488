export function chunk<T> (arr: T[], len: number): T[][] {
	const chunks = []
	let i = 0
	const n = arr.length

	while (i < n) {
		chunks.push(arr.slice(i, i += len))
	}

	return chunks
}


export const divideArrAlphabetically = (arr: string[]) => {
	const obj: Record<string, string[]> = {}

	arr.map(str => {
		const key = str[0].toUpperCase()
		if (obj[key]) {
			obj[key].push(str)
		} else {
			obj[key] = [str]
		}
	})

	return obj
}