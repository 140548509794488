import { HouseholdIntro } from '../../pageComponents/Household/HouseholdIntro'
import RedirectFirstPage from '../../routes/RedirectFirstPage'
import { AvailableStep, useSetActiveStep } from '../../utils/stepUtil'

export default function HouseholdIntroPage() {
	useSetActiveStep(AvailableStep.householdIntro)

	return (
		<RedirectFirstPage>
			<HouseholdIntro />
		</RedirectFirstPage>
	)
}
