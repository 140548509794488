import React from 'react'

export const ChromeIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="363" height="112">
		<defs>
			<linearGradient x1="50%" y1="0%" x2="50%" y2="71.186%" id="a">
				<stop stopColor="#FFF" stopOpacity="0" offset="0%" />
				<stop stopColor="#FFF" offset="100%" />
			</linearGradient>
		</defs>
		<g fill="none" fillRule="evenodd">
			<rect fill="#EFEFEF" opacity=".5" x="15" y="1" width="78" height="34" rx="8" />
			<rect fill="#E1E9F1" x="99" width="103" height="34" rx="8" />
			<rect fill="#E1E9F1" x="5" y="21" width="352" height="89" rx="6" />
			<g transform="translate(107 8)">
				<rect fill="#8396AC" x="23" y="4" width="50" height="5" rx="2.5" />
				<path d="M7.557 11.38c-.756 0-1.46-.208-2.085-.625-.625-.416-1.094-.937-1.407-1.588L.938 3.75C.287 4.922 0 6.198 0 7.526c0 1.901.625 3.542 1.85 4.948C3.075 13.88 4.612 14.714 6.436 15l2.189-3.802c-.208.078-.6.182-1.068.182" fill="#51B84E" />
				<path d="M5.073 4.335c.699-.536 1.475-.765 2.356-.765h6.446c-.673-1.122-1.58-1.938-2.718-2.576A7.28 7.28 0 0 0 7.429 0 7.565 7.565 0 0 0 4.14.74C3.13 1.223 2.173 1.937 1.5 2.855l2.175 3.519c.207-.816.699-1.505 1.398-2.04" fill="#E64A45" />
				<path d="M14.425 4.5h-4.39c.758.763 1.28 1.763 1.28 2.868 0 .816-.235 1.553-.68 2.237L7.5 15c2.064-.026 3.841-.763 5.305-2.263C14.268 11.237 15 9.447 15 7.368c0-.973-.157-2-.575-2.868" fill="#FED130" />
				<path d="M7.688 4.875c1.534 0 2.812 1.278 2.812 2.813 0 1.534-1.278 2.812-2.813 2.812-1.534 0-2.812-1.278-2.812-2.813 0-1.534 1.278-2.812 2.813-2.812" fill="#4793C6" />
			</g>
			<g transform="translate(23 9)" fill="#CECECE" opacity=".5">
				<rect x="15" y="2" width="40" height="5" rx="2.5" />
				<rect width="9" height="9" rx="4.5" />
			</g>
			<path fill="url(#a)" d="M0 23h363v89H0z" />
			<g transform="translate(86 43)">
				<rect fill="#8396AC" width="50" height="5" rx="2.5" />
				<rect fill="#BAC5D4" opacity=".75" y="12" width="100" height="5" rx="2.5" />
				<rect fill="#BAC5D4" opacity=".5" y="23" width="55" height="5" rx="2.5" />
			</g>
			<rect fill="#187AE4" x="241" y="36" width="74" height="29" rx="6" className='animate-pulse-new' />
			<rect fill="#186FE0" x="248" y="43" width="60" height="15" rx="4" />
			<rect x="241" y="36" width="74" height="29" rx="9" />
			<rect fill="#8396AC" x="45" y="43" width="28" height="28" rx="4" />
		</g>
	</svg>
)