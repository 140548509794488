import React from 'react'

export const FirefoxIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="363px" height="112px">
		<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="firefox" fillRule="nonzero">
				<rect id="Rectangle-path" fill="#EFEFEF" opacity="0.5" x="15" y="1" width="78" height="34" rx="8" />
				<rect id="Rectangle-path" fill="#E1E9F1" x="99" y="0" width="103" height="34" rx="8" />
				<rect id="Rectangle-path" fill="#E1E9F1" x="5" y="21" width="352" height="89" rx="6" />
				<g id="Group" transform="translate(130.000000, 12.000000)" fill="#8396AC">
					<rect id="Rectangle-path" x="0" y="0" width="50" height="5" rx="2.5" />
				</g>
				<g transform="translate(109.000000, 7.000000)">
					<path d="M6.5,13 C2.90741761,13 0,10.0928496 0,6.5 C0,2.90742969 2.90716367,0 6.5,0 C10.092557,0 13,2.90715039 13,6.5 C12.9999746,10.0925703 10.0928363,13 6.5,13 Z" id="Shape" fill="#3FA0E1" />
					<path d="M7,0 L7,13 C10.8692084,13 14,10.0925703 14,6.5 C13.9999727,2.90717578 10.8689075,0 7,0 Z" id="Shape" fill="#2568BD" />
					<path d="M6.70359377,0 C6.57130558,0 6.43973073,0.00467188412 6.30884463,0.0129746347 C8.1147088,0.666733724 10.3347792,3.43286999 10.5440346,6.26126418 C10.7295283,8.7691265 9.3092382,11.4204715 4,12.373866 C4.83392232,12.7836715 5.75263404,13 6.70359377,13 C10.1836243,13 13,10.0928439 13,6.4999873 C12.9999754,2.90740998 10.1838949,0 6.70359377,0 Z" id="Shape" fill="#FF9842" />
					<path d="M7,0 L7,0.186164063 C8.91192005,1.06483203 11.0536621,3.63603906 11.2695646,6.26125195 C11.4351218,8.2747793 10.449764,10.3807793 7,11.6262148 L7,13 C10.8689075,13 14,10.0928496 14,6.5 C13.9999727,2.90742969 10.8692084,0 7,0 Z" id="Shape" fill="#FF8322" />
					<g id="Group" fill="#FF9842">
						<path d="M2.74816071,2.39799408 L0.701571429,0.132550296 C0.590357143,0.00947928994 0.414375,-0.0327248521 0.258964286,0.0264674556 C0.103553571,0.0856597633 0.0009375,0.234 0.0009375,0.399434911 L0.0009375,6.81656805 C0.0009375,7.03714793 0.180803571,7.21597633 0.402669643,7.21597633 C0.624535714,7.21597633 0.804401786,7.03714793 0.804401786,6.81656805 C0.804401786,5.63043195 1.15058036,4.491 1.79367857,3.51814793 L2.74816071,2.39799408 Z" id="Shape" />
						<path d="M4.21682143,8.93497633 C4.1145,8.91897337 4.02235714,8.86430769 3.95959821,8.78240237 C3.35483036,7.99277219 3.03516964,7.0410355 3.03516964,6.03002663 C3.03516964,5.68150296 3.45514286,5.50001183 3.71142857,5.73840533 C4.39470536,6.3741568 4.97185714,6.28202663 5.70254464,6.16539941 C6.56989286,6.0269645 7.64938393,5.85460651 8.80317857,7.1828787 C9.02791071,7.44150888 9.05528571,7.80986982 8.87129464,8.09946746 C8.68733036,8.38901183 8.34101786,8.52257396 8.00957143,8.43188166 C7.20688393,8.21242012 6.83879464,8.38347337 6.37299107,8.60011243 C5.89125,8.82410059 5.2914375,9.10299408 4.21682143,8.93497633 Z" id="Shape" />
					</g>
					<path d="M12.7400043,4.67274388 C11.5390267,11.615192 3.8964677,10.3065307 3.64502413,5.96815125 L5.72827586,4.78550391 C5.84745955,4.71783774 5.92109242,4.59134134 5.92109242,4.45428242 L5.92109242,3.88657209 C5.92109242,3.73862056 5.83539899,3.60404993 5.70133638,3.54146189 L4.15613784,2.82006174 L4.15613784,0.380880162 C4.15613784,0.223102443 4.05886627,0.0816763253 3.91154975,0.0252328318 C3.76420783,-0.0312360524 3.59736589,0.00903357452 3.49196932,0.126414713 L1.79671206,2.0143899 C-0.6265458,4.55515961 -0.606360236,8.58504224 1.90449535,11.0962064 C3.09892212,12.2907366 4.7249137,13 6.50017688,13 C8.22128182,13 9.86161911,12.3306 11.0958584,11.0962064 C12.8486509,9.3432081 13.3771064,6.86022759 12.7400043,4.67274388 Z" id="Shape" fill="#FFD664" />
					<path d="M8.82257914,6.92548111 C8.18157542,6.26261806 7.56508383,6.04393397 7,6 L7,7.95101786 C7.27971938,7.87488953 7.59501524,7.85743693 8.07923661,7.97635662 C8.38971534,8.05266417 8.7140684,7.94028641 8.88638095,7.69666682 C9.0587186,7.45302483 9.03307745,7.14308981 8.82257914,6.92548111 Z" id="Shape" fill="#FF8322" />
					<path d="M13.719997,5 C12.9320101,9.06338481 9.56909938,10.1845288 7,9.49779702 L7,13 C8.85354806,13 10.6201132,12.356907 11.9493302,11.1710243 C13.8370042,9.48691781 14.4061256,7.10151693 13.719997,5 Z" id="Shape" fill="#FF9842" />
				</g>
				<g id="Group" opacity="0.5" transform="translate(23.000000, 9.000000)" fill="#CECECE">
					<rect id="Rectangle-path" x="15" y="2" width="40" height="5" rx="2.5" />
					<rect id="Rectangle-path" x="0" y="0" width="9" height="9" rx="4.5" />
				</g>
				<polygon id="Shape" fill="url(#linearGradient-1)" points="0 23 363 23 363 112 0 112" />
				<g id="Group" transform="translate(86.000000, 43.000000)">
					<rect id="Rectangle-path" fill="#8396AC" x="0" y="0" width="50" height="5" rx="2.5" />
					<rect id="Rectangle-path" fill="#BAC5D4" opacity="0.75" x="0" y="12" width="100" height="5" rx="2.5" />
					<rect id="Rectangle-path" fill="#BAC5D4" opacity="0.5" x="0" y="23" width="55" height="5" rx="2.5" />
				</g>
				<rect id="Rectangle-path" fill="#8396AC" x="45" y="43" width="28" height="28" rx="4" />
				<rect fill="#187AE4" x="241" y="36" width="74" height="29" rx="6" className='animate-pulse-new' />
				<rect fill="#186FE0" x="248" y="43" width="60" height="15" rx="4" />
			</g>
		</g>
	</svg>
)