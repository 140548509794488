type Props = React.SVGProps<SVGSVGElement> & {
	color?: string
}

export const InfoIcon = ({ color, ...props }: Props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		fill="none"
		{...props}
	>
		<path
			fill={color ?? '#1E4BAF'}
			fillRule="evenodd"
			d="M4.929 4.929A10 10 0 1 1 19.07 19.07 10 10 0 0 1 4.93 4.93ZM12 4a8 8 0 1 0 0 16 8 8 0 0 0 0-16Zm-1 5a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2H12a1 1 0 0 1-1-1Zm-1 3a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v3a1 1 0 1 1 0 2h-1a1 1 0 0 1-1-1v-3a1 1 0 0 1-1-1Z"
			clipRule="evenodd"
		/>
	</svg>
)
