import { FormProvider, useForm } from 'react-hook-form'
import { ChevronRightIcon } from '../../../assets/icons'
import { Button, SecondaryButton } from '../../../components'
import Pill from './Pill'
import RatingButtons from './RatingButtons'
import { tContext } from '../../../utils/cmsTranslations'

const DATA = [
	'Onduidelijk',
	'Moeilijke taal',
	'Duurde lang',
	'Traag',
	'Weinig hulp',
	'Moeilijk inloggen',
]

type FormValues = { toBeImproved: string[] }

const t = tContext('review')

const Step2 = ({
	onNext,
	onSkipStepClick,
	rating,
}: {
	onNext: (toBeImproved: string[]) => void
	onSkipStepClick: () => void
	rating: number
}) => {
	const methods = useForm<FormValues>({
		defaultValues: {
			toBeImproved: [],
		},
	})

	const onSubmit = (data: FormValues) => {
		onNext(data.toBeImproved.filter((value) => !!value))
	}

	return (
		<FormProvider {...methods}>
			<form onSubmit={methods.handleSubmit(onSubmit)}>
				<div className="mb-6">
					<hr />
					<div className="my-6">
						<RatingButtons rating={rating} />
					</div>
					<hr />
					<div className="mt-6 mb-4">
						<p className="text-text font-semibold mb-2">
							{t('improvementTitle')}
						</p>
						<p className="text-text mb-6">{t('improvementDecisionTitle')}</p>
						<div>
							{DATA.map((value, index) => (
								<Pill
									value={value}
									name="toBeImproved"
									color="red"
									index={index}
									key={value}
								/>
							))}
						</div>
					</div>
					<hr />
				</div>

				<div className="flex justify-between items-center">
					<div>
						<SecondaryButton
							variant="small"
							onClick={onSkipStepClick}
							title={t('buttonSkipStep.title')}
						/>
					</div>
					<div>
						<Button
							variant="small"
							title={t('buttonNextStep.title')}
							icon={<ChevronRightIcon color="currentColor" />}
						/>
					</div>
				</div>
			</form>
		</FormProvider>
	)
}

export default Step2
