import React, { useEffect, useContext, useState } from 'react'
import { useLayoutContext } from '../../context/theme-context'
import { Header } from '../header/Header'
import {
	isMobile,
	isChrome,
	isEdgeChromium,
	isFirefox,
	isEdge,
} from 'react-device-detect'
import {
	NotSupportedBrowser,
	NotSupportedMobile,
	NotAvailable,
	NotAvailableMessage,
} from './NotSupportedBrowser'
import { getExtensionAPI } from '../../utils/extension'
import { AppContext } from '../../context/app-context'
import { ActivityCheck } from '../../utils/activityCheck'
import { useNavigate } from 'react-router'
import { ErrorModal } from '../../components/modal/ErrorModal'
import { useStatusMessage } from '../../hooks/useStatusMessage'
import { OverallProgressIndication } from '../../components/OverallProgressIndication/OverallProgressIndication'
import { AvailableStep, getMainStepText } from '../../utils/stepUtil'
import { getFaqUrlByStep } from '../../utils/faqHelper'
import { InfoIcon } from '../../assets/icons'
import { useAqopi } from '../../hooks/useAqopi'
import { fetchSupportCode } from '../../utils/support-code-util'
import { useEnergyProviders } from '../../hooks/useEnergyProviders'
import { useCheckAvailability } from '../../utils/check-availability'
import FaqSection from '../../pageComponents/FaqSection/FaqSection'
import HelpModal from '../../components/Help/HelpModal'

type Props = {
	children: React.ReactNode
}

export const MainLayout: React.FC<Props> = ({ children }) => {
	useEnergyProviders()
	useCheckAvailability()

	const { headerHidden } = useLayoutContext()
	const [helpModalOpen, setHelpModalOpen] = useState(false)

	const navigate = useNavigate()
	const { blockingStatusMessage } = useStatusMessage()
	const appCtx = useContext(AppContext)
	const { ticket, isFailed, supportCode, setSupportCode } = appCtx
	const { uninstallExtension } = useAqopi()

	useEffect(() => {
		if (ticket) {
			fetchSupportCode(ticket).then((newSupportCode) => {
				setSupportCode(newSupportCode || null)
			})
		}
	}, [ticket])

	useEffect(() => {
		;(() => {
			const removeExtension = () => {
				const ext = getExtensionAPI()
				if (ext) {
					uninstallExtension()
					navigate('/')
				}
			}
			new ActivityCheck({
				thresholdSeconds: 600,
				onInactivity() {
					removeExtension()
				},
			}).install()

			window.addEventListener('beforeunload', () => {
				removeExtension()
			})
		})()
	}, [])

	const [faqUrl, setFaqUrl] = useState(getFaqUrlByStep(appCtx.activeStep))

	useEffect(() => {
		setFaqUrl(getFaqUrlByStep(appCtx.activeStep))
	}, [appCtx.mainStep])

	if (!isFailed?.available) {
		return isFailed?.unavailableConfigs ? (
			<NotAvailable unavailableConfigs={isFailed?.unavailableConfigs} />
		) : null
	}

	if (blockingStatusMessage) {
		return <NotAvailableMessage message={blockingStatusMessage} />
	}

	if (isMobile || (!isChrome && !isEdgeChromium && !isFirefox && !isEdge)) {
		return (
			<>
				<Header />
				<div className="min-h-screen pt-[83px]">
					<main className="container pt-10 mx-auto px-4">
						{isMobile ? <NotSupportedMobile /> : <NotSupportedBrowser />}
					</main>
				</div>
			</>
		)
	}

	return (
		<>
			{!headerHidden && <Header />}
			{helpModalOpen && (
				<HelpModal
					open={helpModalOpen}
					setOpen={setHelpModalOpen}
					isResult={appCtx.activeStep === AvailableStep.finish}
				/>
			)}
			<div
				className={`min-h-screen w-full mx-auto max-w-5xl ${
					headerHidden ? 'pt-6' : 'pt-[83px]'
				}`}
			>
				<main className={`container px-4 ${headerHidden ? '' : 'py-5'}`}>
					{appCtx.mainStep > -1 && (
						<div className="flex md:grid md:grid-cols-[33%_1fr] gap-4">
							<div className="w-full md:col-start-2 relative">
								<h1 className="font-semibold text-[30px] text-gray-950 mb-4">
									{getMainStepText(appCtx.mainStep)}
								</h1>

								{faqUrl && (
									<button
										className="absolute top-2 right-0 text-gray-950 cursor-pointer font-semibold p-1"
										onClick={() => setHelpModalOpen(true)}
										aria-label="Veelgestelde vragen"
									>
										Help{' '}
										<InfoIcon
											color={'currentColor'}
											className="inline-block"
											aria-hidden="true"
										/>
									</button>
								)}
							</div>
						</div>
					)}

					<div className="flex flex-col-reverse md:grid md:grid-cols-[33%_1fr] gap-4">
						<div className="pt-8">
							<OverallProgressIndication step={appCtx.mainStep} />
						</div>

						<div className="flex flex-col items-center pb-6">
							{children}

							{!!supportCode && (
								<div className="w-full text-left mt-6 text-gray-900">
									supportcode: {supportCode}
								</div>
							)}

							<FaqSection />
						</div>
					</div>
				</main>
			</div>

			<ErrorModal />
		</>
	)
}
