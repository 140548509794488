import React, { useContext, useState } from 'react'
import {
	ChevronRightIcon,
	CrossRoundedIcon,
	InfoIcon,
} from '../../assets/icons'
import { Button, Checkbox, ListWithIcons, Title } from '../../components'
import { AppContext } from '../../context/app-context'
import { useTitle } from '../../hooks/useTitle'
import { tContext } from '../../utils/cmsTranslations'
import { AvailableStep, useSetActiveStep } from '../../utils/stepUtil'
import { Video } from '../../components/Video/Video'

const t = tContext('registration.intro')

type Props = {
	onClick: () => void
}

export const Substep_0: React.FC<Props> = ({ onClick }) => {
	useTitle('Info')
	useSetActiveStep(AvailableStep.registrationStart)

	const {
		isGemachtigd,
		wasAutomaticallySignedOut,
		setWasAutomaticallySignedOut,
	} = useContext(AppContext)
	const [canProceed, setCanProceed] = useState(false)

	return (
		<div className="flex flex-col items-start w-full h-full">
			{wasAutomaticallySignedOut && (
				<div
					aria-live="polite"
					className="w-full rounded-[1rem] px-6 pt-6 pb-2 bg-red-100 mb-6"
				>
					<div className="grid grid-cols-[64px_1fr]">
						<div aria-hidden="true">
							<CrossRoundedIcon className="w-[48px] h-[48px]" />
						</div>
						<div className="self-center">
							<p className="font-semibold text-text text-lg mb-1">
								Je bent automatisch uitgelogd
							</p>

							<p className="text-text">
								Je was langer dan 15 minuten niet actief. Doorloop de stappen
								opnieuw om verder te gaan.
							</p>

							<button
								className="text-text font-semibold underline cursor-pointer px-0 py-2"
								onClick={() => {
									setWasAutomaticallySignedOut(false)
								}}
							>
								Sluiten
							</button>
						</div>
					</div>
				</div>
			)}

			<Video tag="registration" />

			<Title classNameOverride="mb-8">{t('title')}</Title>

			<ListWithIcons listKey={'registration.intro.list.items'} />

			{isGemachtigd && (
				<>
					<div className="mb-6">
						<Checkbox
							value="Ik heb toestemming gekregen van alle volwassenen in het huishouden om een aanvraag in te dienen bij het Noodfonds Energie en ik ben DigiD gemachtigd om informatie te verzamelen bij de Belastingdienst, Toeslagen en UWV"
							checked={canProceed}
							setChecked={() => setCanProceed(!canProceed)}
						/>
					</div>
					<div className={'text-text flex flex-row items-center mb-5'}>
						<InfoIcon color="currentColor" />

						<p className={'text-text text-md font-normal ml-1'}>
							Vragen over machtiging? Kijk op de website van{' '}
							<a
								className="text-primary underline"
								href="https://www.noodfondsenergie.nl/faq#faq-section-9"
								rel={'noreferrer'}
								target={'_blank'}
							>
								Noodfonds Energie
							</a>
						</p>
					</div>
				</>
			)}

			<Button
				disabled={isGemachtigd && !canProceed}
				title={t('buttonNext.title')}
				icon={<ChevronRightIcon />}
				onClick={onClick}
			/>
		</div>
	)
}
