import React, { HTMLProps, forwardRef } from 'react'

type Props = {
	label: string
	value: string
}

// eslint-disable-next-line react/display-name
export const CheckboxControlled = forwardRef<
	HTMLInputElement,
	Props & HTMLProps<HTMLInputElement>
>(({ label, value, ...props }, ref) => {
	return (
		<label className="flex items-center cursor-pointer">
			<input
				ref={ref}
				className="mr-3 w-6 h-6 accent-indigo-blue-800"
				type="checkbox"
				value={value}
				{...props}
			/>
			{label}
		</label>
	)
})
