import React from 'react'

type Props = React.SVGProps<SVGSVGElement> & {
	color?: string
	inverseColor?: boolean
}

const CIRCLE_COLOR = '#ffffff'
const CHECK_COLOR = '#B73E17'

export const WarningRoundedIcon: React.FC<Props> = ({
	color,
	inverseColor = false,
	...props
}) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="48"
		height="48"
		fill="none"
		{...props}
	>
		<path
			fill={inverseColor ? CIRCLE_COLOR : color ?? CHECK_COLOR}
			d="M0 24C0 10.745 10.745 0 24 0s24 10.745 24 24-10.745 24-24 24S0 37.255 0 24Z"
		/>
		<path
			fill={inverseColor ? CHECK_COLOR : CIRCLE_COLOR}
			fillRule="evenodd"
			d="M22.729 14.043A2.37 2.37 0 0 1 24 13.668c.445 0 .883.129 1.271.375.388.246.713.6.944 1.029l.003.004 7.205 13.536.008.015c.225.44.345.94.346 1.448a3.202 3.202 0 0 1-.34 1.45c-.224.442-.547.81-.936 1.068-.39.258-.832.397-1.284.403H16.784a2.377 2.377 0 0 1-1.285-.403 2.781 2.781 0 0 1-.937-1.068 3.2 3.2 0 0 1-.34-1.451c.002-.509.122-1.009.348-1.45l.007-.013 7.191-13.507a2.761 2.761 0 0 1 .96-1.061ZM24 15.668c-.14 0-.277.04-.399.118a.86.86 0 0 0-.286.304l-.008.015-7.2 13.526a1.005 1.005 0 0 0 0 .904c.07.138.172.254.294.335.12.08.258.123.399.126h14.4a.746.746 0 0 0 .399-.126.873.873 0 0 0 .293-.335 1.004 1.004 0 0 0 .001-.904l-7.198-13.523-.001-.002a.866.866 0 0 0-.295-.32.743.743 0 0 0-.399-.118ZM24 20c.49 0 .889.448.889 1v4c0 .552-.398 1-.889 1-.49 0-.889-.448-.889-1v-4c0-.552.398-1 .889-1Zm-.889 8c0-.552.398-1 .889-1h.009c.49 0 .889.448.889 1s-.398 1-.89 1H24c-.49 0-.889-.448-.889-1Z"
			clipRule="evenodd"
		/>
	</svg>
)
