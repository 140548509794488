export function SyncIcon({ color }: { color?: string }) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" fill="none">
			<path
				fill={color ?? '#1E40AF'}
				d="M16.125.25h-.866c-.21 0-.38.173-.375.384l.073 3.08A7.752 7.752 0 0 0 .884 6.557a.375.375 0 0 0 .37.443h.892c.178 0 .33-.124.367-.298A6.123 6.123 0 0 1 8.5 1.875a6.122 6.122 0 0 1 5.584 3.603l-3.95-.094a.375.375 0 0 0-.384.375v.866c0 .207.168.375.375.375h6a.375.375 0 0 0 .375-.375v-6a.375.375 0 0 0-.375-.375ZM15.747 9h-.893a.376.376 0 0 0-.367.298A6.123 6.123 0 0 1 8.5 14.125a6.122 6.122 0 0 1-5.584-3.603l3.95.094a.375.375 0 0 0 .384-.375v-.866A.375.375 0 0 0 6.875 9h-6a.375.375 0 0 0-.375.375v6c0 .207.168.375.375.375h.866c.21 0 .38-.173.375-.384l-.073-3.08a7.752 7.752 0 0 0 14.073-2.843.375.375 0 0 0-.37-.443Z"
			/>
		</svg>
	)
}
