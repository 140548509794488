import { useContext } from 'react'
import { checkCustomerNumber } from '../utils/customer-number-utils'
import { AppContext } from '../context/app-context'
import { EnergyData, HouseholdData, PeriodEnergyData } from '../types/api'

// these fields have to be present on EnergyData
const MANDATORY_FIELDS: (keyof EnergyData)[] = [
	'actualAdvance',
	'advanceAdvice',
	'supplier',
	'clientNumber',
]

export function getUpdatedHouseholdData(
	householdDataFromContext: HouseholdData,
	contractHolderName: string
): Promise<HouseholdData> {
	const newHouseholdData = { ...householdDataFromContext }

	const contractHolder = newHouseholdData.personen.find(
		(p) => p.volledigeNaam === contractHolderName
	)

	if (!contractHolder) {
		return Promise.reject('Contract holder not found')
	}

	newHouseholdData.personen = newHouseholdData.personen.map((person) => ({
		...person,
		isContracthouder: person.id === contractHolder?.id,
	}))

	return Promise.resolve(newHouseholdData)
}

export function getUpdatedEnergyData(
	energyDataFromContext: PeriodEnergyData[],
	period: string,
	primarySupplierData: EnergyData,
	secondarySupplierData: EnergyData | undefined
): Promise<PeriodEnergyData[]> {
	const energyData = [...energyDataFromContext]
	const periodEnergyData = energyData.find((e) => e.period.val === period)

	if (!periodEnergyData) {
		return Promise.reject('Period not found')
	}

	if (!primarySupplierData.selectedContractHolder) {
		return Promise.reject('Primary contract holder not found')
	}

	if (
		!!secondarySupplierData &&
		!secondarySupplierData.selectedContractHolder
	) {
		return Promise.reject('Secondary contract holder not found')
	}

	const updatedFields = MANDATORY_FIELDS.reduce((acc, key) => {
		acc[key] = primarySupplierData[key]
		if (secondarySupplierData) {
			acc[`secondary_${key}`] = secondarySupplierData[key]
		}
		return acc
	}, {} as Record<string, any>)

	const OPTIONAL_FIELDS = ['advanceAdvice', 'secondary_advanceAdvice']

	// if there are empty (required) fields for the amounts, we also reject!
	if (
		Object.entries(updatedFields)
			.map(([key, value]) => {
				if (OPTIONAL_FIELDS.includes(key) && value === '') return null
				return value
			})
			.some((v) => v === '')
	) {
		return Promise.reject('Missing some required fields')
	}

	const newPeriodData: PeriodEnergyData = {
		...periodEnergyData,
		data: [primarySupplierData].concat(
			secondarySupplierData ? [secondarySupplierData] : []
		),
	}

	// we only update the record of the supplied period
	return Promise.resolve(
		energyData.map((e) => (e.period.val === period ? newPeriodData : e))
	)
}

export function useSubmitEnergyFormData() {
	const {
		energyProviders,

		householdData: householdDataFromContext,
		setHouseholdData,

		energyData: energyDataFromContext,
		setEnergyData,
	} = useContext(AppContext)

	const submit = async (
		period: string,
		primarySupplierData: EnergyData,
		secondarySupplierData: EnergyData | undefined
	): Promise<any> => {
		const isValidCustomerNumber = checkCustomerNumber(
			energyProviders.suppliers,
			primarySupplierData.supplier,
			primarySupplierData.clientNumber
		)

		if (isValidCustomerNumber !== true) {
			return Promise.reject(isValidCustomerNumber)
		}

		if (secondarySupplierData) {
			const isSecondaryValidCustomerNumber = checkCustomerNumber(
				energyProviders.suppliers,
				secondarySupplierData.supplier,
				secondarySupplierData.clientNumber
			)
			if (isSecondaryValidCustomerNumber !== true) {
				return Promise.reject(isSecondaryValidCustomerNumber)
			}
		}

		const contractHolder = primarySupplierData.selectedContractHolder

		if (!contractHolder) {
			return Promise.reject('Contract holder not present on primary supplier')
		}

		const [newHouseholdData, newEnergyData] = await Promise.all([
			getUpdatedHouseholdData(
				householdDataFromContext,
				contractHolder.volledigeNaam
			),
			getUpdatedEnergyData(
				energyDataFromContext,
				period,
				primarySupplierData,
				secondarySupplierData
			),
		])

		setEnergyData(newEnergyData)
		setHouseholdData(newHouseholdData)

		return Promise.resolve()
	}

	return {
		submit,
	}
}
