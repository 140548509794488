import { browserVersion, osName, osVersion } from 'react-device-detect'
import { getBrowserName } from './navigation-logging'

const getSupportCodeUrl = (env?: string) => {
	const BASE_URL = 'https://logging-query.service.iwize.nl/'

	switch (env) {
		case 'acct':
			return `${BASE_URL}env/acct/api/v1/supportcode`
		case 'test':
			return `${BASE_URL}env/test/api/v1/supportcode`
		case 'production':
			return `${BASE_URL}api/v1/supportcode`

		default:
			return `${BASE_URL}env/dev/api/v1/supportcode`
	}
}

export const fetchSupportCode = async (ticket: string): Promise<string> => {
	const env = process.env.REACT_APP_ENV
	const timestamp = new Date().toISOString()
	const body = {
		timestamp,
		origin: 'noodfonds-web',
		type: 'web-navigation',
		path: 'Blokkade - Bericht',
		browser: getBrowserName(),
		browserVersion: browserVersion,
		platform: osName,
		platformVersion: osVersion,
	}

	const response = await fetch(getSupportCodeUrl(env), {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Ticket: ticket,
		},
		body: JSON.stringify(body),
	})

	if (!response.ok) {
		throw new Error('Failed to get support code')
	}

	const data = await response.json()

	if (!data.supportCode) {
		throw new Error('no supportcode from backend')
	}

	return data.supportCode as string
}
