import React from 'react'

type Props = React.SVGProps<SVGSVGElement>

export const CrossIcon: React.FC<Props> = ({ ...props }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		fill="none"
		{...props}
	>
		<path
			fill="#323439"
			d="M18.67 5.924a1.124 1.124 0 0 0-1.588-1.589l-5.577 5.576-5.581-5.58A1.124 1.124 0 0 0 4.335 5.92l5.576 5.575-5.58 5.581a1.124 1.124 0 0 0 1.589 1.588l5.575-5.575 5.581 5.58a1.124 1.124 0 0 0 1.588-1.588l-5.575-5.576 5.58-5.581Z"
		/>
	</svg>
)
