import React from 'react'

type Props = {
	children: string
	classNameOverride?: string
	size?: 'lg' | 'md' | 'sm'
}

const textSizes = {
	lg: 'text-lg',
	md: 'text-base',
	sm: 'text-sm',
}

export const TextBold: React.FC<Props> = ({
	children,
	classNameOverride,
	size = 'lg',
}) => (
	<p
		className={`font-semibold text-text ${textSizes[size]} ${classNameOverride}`}
	>
		{children}
	</p>
)
