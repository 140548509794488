import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { AppContext } from '../../context/app-context'
import { useLayoutContext } from '../../context/theme-context'
import { useDisableBackButton } from '../../hooks/useDisableBackButton'
import { useTitle } from '../../hooks/useTitle'
import RedirectFirstPage from '../../routes/RedirectFirstPage'
import { FetchingProgress } from '../../pageComponents/Input/FetchingProgress'
import { InputCompleted } from '../../pageComponents/Input/Completed'
import { InputRetrieve } from '../../pageComponents/Input/Retrieve'
import { useAqopi } from '../../hooks/useAqopi'
import { QueryStatus } from '../../types/aqopi'
import { ProcessFailureType, QueryFailureType } from '../../utils/failures'
import { HouseholdData } from '../../types/api'
import { fireLogNavigationEvent } from '../../utils/navigation-logging'
import { AvailableStep } from '../../utils/stepUtil'
import { getRoute } from '../../utils/routeUtils'

export default function InputPage() {
	useTitle('Gegevens huishouden')
	useDisableBackButton()
	const { startQuery, configs, result } = useAqopi('input')
	const { householdData, setHouseholdData, ticket } = useContext(AppContext)
	const { setHeaderHidden, headerHidden } = useLayoutContext()
	const navigate = useNavigate()
	const [loaded, setLoaded] = useState(false)
	const [failureType, setFailureType] = useState<
		QueryFailureType | ProcessFailureType | null
	>(null)

	// useEffect(() => {
	// 	checkAqopiAvailability(
	// 		isGemachtigd ? machtigingenConfigs : standardConfigs
	// 	).then((available) => {
	// 		setIsFailed(available)
	// 	})
	// }, [])

	useEffect(() => {
		setLoaded(configs[0]?.status === QueryStatus.Success)
		setFailureType(configs[0]?.failureType ?? null)
		setHeaderHidden(configs[0]?.status === QueryStatus.Active)
		result && setHouseholdData(result as HouseholdData)
	}, [configs, result])

	const onFetchClick = () => {
		setHeaderHidden(true)

		try {
			startQuery()
		} catch (err) {
			setHeaderHidden(false)
		}
	}

	const isHouseHoldResultParsed =
		!!householdData.mijnHuishoudenParseResult && loaded

	const isCompletedSuccessfully = loaded && !failureType

	const onNavigate = () => {
		const nextUrl =
			householdData?.personen.length > 1
				? getRoute(AvailableStep.people)
				: getRoute(AvailableStep.incomeIntro)

		navigate(nextUrl, { replace: true })
	}

	useEffect(() => {
		// this is how we currently 'detect progress'
		if (headerHidden) {
			fireLogNavigationEvent(
				AvailableStep.inputHousehold,
				ticket,
				'gegevens-ophalen'
			)
		}
	}, [ticket, headerHidden])

	return (
		<RedirectFirstPage>
			<div className="w-full">
				<div className="flex flex-col items-center h-full">
					{headerHidden ? (
						<FetchingProgress />
					) : isCompletedSuccessfully ? (
						<InputCompleted onNavigate={onNavigate} />
					) : (
						<InputRetrieve
							isFailed={!!failureType}
							isHouseholdResultParsed={isHouseHoldResultParsed}
							failureType={failureType}
							onFetchClick={onFetchClick}
						/>
					)}
				</div>
			</div>
		</RedirectFirstPage>
	)
}
