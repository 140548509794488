import { t } from './cmsTranslations'

enum QueryFailureType {
	Error = 'ERROR',
	Busy = 'BUSY',
	Canceled = 'CANCELED',
	UserInconsistency = 'USER-INCONSISTENCY',
	NoValidDataIds = 'NO_VALID_DATA_IDS',
	NoDataQueried = 'NO_DATA_QUERIED',
	NotAvailable = 'NOT-AVAILABLE',
	NoSmsConfigured = 'NO_SMS_CONFIGURED',
	TechnicalError = 'TECHNICAL_ERROR',
	ResetCurrent = 'RESET_CURRENT',
	UwvIncomplete = 'UWV_INCOMPLETE',
	TabClosed = 'TAB_CLOSED',
}

enum ProcessFailureType {
	ParsedDataIncomplete = 'PARSED_DATA_INCOMPLETE',
	CrossCheckNegative = 'CROSSCHECK_NEGATIVE',
	FinalizeToeslagenFailed = 'FINALIZE_TOESLAGEN_FAILED',
}

const translatedFailure = (key: string) => ({
	title: t(`${key}.title`),
	msg: t(`${key}.text`),
})

const showFailure = (
	type: QueryFailureType | ProcessFailureType | null
): { title: string; msg: string } => {
	if (!type) {
		return translatedFailure('generic.feedbackUnexpectedFailure')
	}

	// Query failures
	if (
		type === QueryFailureType.Canceled ||
		type === QueryFailureType.TabClosed
	) {
		return translatedFailure('collect.error.feedbackCancelled')
	} else if (type === QueryFailureType.UserInconsistency) {
		return translatedFailure('collect.error.feedbackUserInconsistency')
	} else if (type === QueryFailureType.NoSmsConfigured) {
		return translatedFailure('collect.error.feedbackNoSmsConfigured')
	} else if (type === QueryFailureType.ResetCurrent) {
		return translatedFailure('collect.error.feedbackCancelled')
	} else if (type === QueryFailureType.UwvIncomplete) {
		return translatedFailure('generic.feedbackUwvIncomplete')
	} else if (type === QueryFailureType.TechnicalError) {
		return translatedFailure('generic.feedbackUnexpectedFailure')
	}

	// Process failures
	if (type === ProcessFailureType.FinalizeToeslagenFailed) {
		return translatedFailure('generic.feedbackUnexpectedFailure')
	} else if (type === ProcessFailureType.ParsedDataIncomplete) {
		return translatedFailure('generic.feedbackCrossCheckFailure')
	} else if (type === ProcessFailureType.CrossCheckNegative) {
		return translatedFailure('generic.feedbackCrossCheckNegative')
	}

	// undocumented failure
	console.error('Undocumented failure type:', type)
	return {
		title: 'Er is een fout opgetreden',
		msg: 'Er is iets foutgegaan. Probeer het later opnieuw (Code U1)',
	}
}

export { QueryFailureType, ProcessFailureType, showFailure }
