import { AvailableStep } from './stepUtil'

const ROUTE_MAPPING: Record<AvailableStep, string> = {
	[AvailableStep.none]: '/404',

	// registration steps
	[AvailableStep.registrationStart]: '/start',
	[AvailableStep.registrationEmail]: '/intro',
	[AvailableStep.registrationValidateEmail]: '/intro',
	[AvailableStep.registrationExtraDetails]: '/intro',
	[AvailableStep.registrationPhoneNumber]: '/intro',
	[AvailableStep.registrationValidatePhoneNumber]: '/intro',
	[AvailableStep.registrationExtension]:
		'/registratie/extensie-en-overeenkomst',
	[AvailableStep.registrationAgreement]:
		'/registratie/extensie-en-overeenkomst',

	// household steps
	[AvailableStep.householdIntro]: '/huishouden/intro',
	[AvailableStep.inputHousehold]: '/huishouden/opvragen',

	// income steps
	[AvailableStep.incomeIntro]: '/inkomen/intro',
	[AvailableStep.people]: '/people',
	[AvailableStep.review]: '/review',

	// energy steps
	[AvailableStep.energyIntro]: '/energy-intro',
	[AvailableStep.energyData]: '/collect',

	// overview steps
	[AvailableStep.overview]: '/result',
	[AvailableStep.finish]: '/decision',
}

export const getRoute = (step: AvailableStep) => ROUTE_MAPPING[step] || '/404'
