import { useState } from 'react'
import { Button } from '../button/Button'
import { Title } from '../text-types/Title'
import AgreementModal from './AgreementModal'
import { useTitle } from '../../hooks/useTitle'
import { tContext } from '../../utils/cmsTranslations'
import { SecondaryButton } from '../button/SecondaryButton'
import { ChevronRightIcon } from '../../assets/icons'
import { MarkdownText } from '../MarkdownText/MarkdownText'

const t = tContext('registration.agreement')

type AgreementType = {
	onAccepted: () => void
	onCancel: () => void
}

export default function Agreement({ onAccepted, onCancel }: AgreementType) {
	useTitle('Overeenkomst')
	const [showModal, setShowModal] = useState(false)

	function renderAgreementModal() {
		return (
			<AgreementModal
				open={showModal}
				setOpen={setShowModal}
				onAccepted={onAccepted}
			/>
		)
	}

	return (
		<>
			{showModal && renderAgreementModal()}

			<div className="items-start w-full h-full">
				<Title classNameOverride="mb-4">{t('title')}</Title>

				<MarkdownText className="mb-8" text={t('text')} />

				<div className="flex flex-row justify-start items-center gap-6">
					<Button
						onClick={() => setShowModal(true)}
						title={t('buttonOpen.title')}
						icon={<ChevronRightIcon />}
					/>

					<SecondaryButton onClick={onCancel} title={t('buttonCancel.title')} />
				</div>
			</div>
		</>
	)
}
