import { LockClosedIcon } from '../../assets/icons'
import { MarkdownText, MultilineButton, Notice, Title } from '../../components'
import {
	showFailure,
	QueryFailureType,
	ProcessFailureType,
} from '../../utils/failures'
import { tContext, tAsList } from '../../utils/cmsTranslations'
import { AvailableStep, useSetActiveStep } from '../../utils/stepUtil'

const t = tContext('household')
const tGeneric = tContext('generic')

interface StepContentProps {
	isFailed: boolean
	failureType: QueryFailureType | ProcessFailureType | null
	isHouseholdResultParsed: boolean
	onFetchClick: () => void
}

const ContentFailure = ({
	failureType,
}: {
	failureType: StepContentProps['failureType']
}) => {
	const { title, msg } = showFailure(failureType)
	return (
		<div className="mb-8">
			<Notice
				noticeType="error"
				classNameOverride="mb-8"
				title={title}
				text={msg}
			/>
		</div>
	)
}

export const InputRetrieve = ({
	isFailed,
	failureType,
	onFetchClick,
}: StepContentProps) => {
	useSetActiveStep(AvailableStep.inputHousehold)

	return (
		<>
			<div className="w-full h-full">
				<Title classNameOverride="mb-6">{t('retrieve.title')}</Title>

				{!!isFailed && <ContentFailure failureType={failureType} />}

				<MarkdownText text={t('retrieve.text')} />

				<ul className="list-disc list-outside pl-6 mb-8">
					{tAsList('household.retrieve.list.items').map((item, index) => (
						<li key={index} className="text-base font-normal">
							{item.text}
						</li>
					))}
				</ul>

				<div role="region" aria-live="polite" aria-busy="false">
					<MultilineButton
						line1={tGeneric('resourceIdentifierToeslagen.resource')}
						line2={tGeneric('resourceIdentifierToeslagen.getLabel')}
						accessibilityLabel={tGeneric(
							'resourceIdentifierToeslagen.accessibilityLabel'
						)}
						icon={<LockClosedIcon color="white" aria-hidden="true" />}
						classNameOverride={isFailed ? 'border border-issue' : ''}
						onClick={onFetchClick}
					/>
				</div>
			</div>
		</>
	)
}
