import { Popover } from '@headlessui/react'
import { InfoIcon } from '../../assets/icons'

interface Props {
  text: string;
}

function InfoPopover({ text }: Props) {
	return (
		<Popover className="relative">
			<Popover.Button className='focus:outline-primary focus:outline-dashed'>
				<InfoIcon />
			</Popover.Button>

			<Popover.Panel className="absolute z-10">
				<div className='bg-primary p-2 text-white w-64 rounded'>
					{text}
				</div>
			</Popover.Panel>
		</Popover>
	)
}

export default InfoPopover
