import { FailIconBig } from '../../assets/icons'
import { Header } from '../header/Header'
import PageWrapper from '../../components/PageWrapper/PageWrapper'
import { tContext } from '../../utils/cmsTranslations'
import { MarkdownText } from '../../components'

const t = tContext('web')

const WrapPage = ({ children }: { children: React.ReactNode }) => (
	<div className="flex items-center justify-center w-full h-full">
		<div className="flex flex-col items-start w-full h-full p-8 rounded-xl bg-white">
			{children}
		</div>
	</div>
)

export const NotSupportedBrowser = () => {
	return (
		<WrapPage>
			<h1 className="text-text font-semibold text-[22px] sm:text-[28px] mb-4">
				{t('notSupportedBrowser.titleTitle')}
			</h1>

			<MarkdownText text={t('notSupportedBrowser.textContent')} />
		</WrapPage>
	)
}

export const NotSupportedMobile = () => {
	return (
		<WrapPage>
			<h1 className="text-text font-semibold text-[22px] sm:text-[28px] mb-4">
				{t('notSupportedMobile.titleTitle')}
			</h1>

			<MarkdownText text={t('notSupportedMobile.textContent')} />
		</WrapPage>
	)
}

export const NotAvailable = ({ unavailableConfigs }: any) => {
	const labels: Record<string, string> = {
		'mts-nfe-ext': 'Toeslagen',
		'mts-nfe-2-ext': 'Toeslagen',
		'uwv-nfe-ext': 'het UWV',
		'uwv-nfe-2-ext': 'het UWV',
		'mbd-nfe-ext': 'de Belastingdienst',
		'mbd-nfe-2-ext': 'de Belastingdienst',
	}

	const unavailableList = unavailableConfigs.map(
		(config: string) => labels[config]
	)

	const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1)

	const listUnavailableSourcesAsString = (array: string[]) => {
		const copy = JSON.parse(JSON.stringify(array))
		const last = copy.pop()
		const asString = copy.join(', ')

		return asString.length
			? capitalize(asString) + ' en ' + last
			: capitalize(last)
	}

	return (
		<NotAvailableMessage
			message={`Er is op dit moment een storing bij ${listUnavailableSourcesAsString(
				unavailableList
			)}. Hierdoor kunnen we hier tijdelijk geen gegevens ophalen. Omdat we deze gegevens nodig hebben kun je tijdelijk helaas geen aanvraag doen. Hou onze website in de gaten voor meer informatie.`}
		/>
	)
}

export const NotAvailableMessage = ({ message }: { message: string }) => {
	return (
		<>
			<Header />
			<div className="min-h-screen w-full pt-[83px]">
				<main className={'container mx-auto pt-10 sm:p-0'}>
					<PageWrapper>
						<div className="flex flex-col items-center justify-center max-w-[60%] min-h-[300px] mx-auto">
							<div className="flex flex-row items-center justify-center mt-10 mb-6">
								<FailIconBig color="#E17000" />
								<p className="text-text font-semibold text-[22px] sm:text-[28px] ml-[11px]">
									De service is tijdelijk niet beschikbaar
								</p>
							</div>
							<p className="text-text text-[14px] sm:text-[18px] mb-10 text-center">
								<span>{message}</span>
							</p>
						</div>
					</PageWrapper>
				</main>
			</div>
		</>
	)
}
