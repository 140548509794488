import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { AvailableStep, useSetActiveStep } from '../../utils/stepUtil'
import { AppContext } from '../../context/app-context'

// this page only exists to redirect to the first page of the registration flow
const FirstPage = () => {
	useSetActiveStep(AvailableStep.registrationStart)

	const { setIsGemachtigd, setWasAutomaticallySignedOut } =
		useContext(AppContext)
	const navigate = useNavigate()

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search)
		if (urlParams.get('machtiging') !== 'true') {
			setIsGemachtigd(false)
		} else {
			setIsGemachtigd(true)
		}

		if (urlParams.get('timeoutMelding') === 'true') {
			setWasAutomaticallySignedOut(true)
		}

		if (urlParams.get('slow') === 'true') {
			try {
				window.localStorage.setItem('slow', 'true')
			} catch (e) {
				console.error(e, 'localstorage not available')
			}
		}

		navigate('/start')
	}, [])

	return null
}

export default FirstPage
