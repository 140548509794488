import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { ActivityIndicatorIcon, ChevronRightIcon } from '../../assets/icons'
import { Button, InfoBoxText, Title } from '../../components'
import { UserInfoTable } from '../../components/info-table/UserInfoTable'
import { AppContext } from '../../context/app-context'
import { useDisableBackButton } from '../../hooks/useDisableBackButton'
import { useTitle } from '../../hooks/useTitle'
import { IncomeData, IncomeUI } from '../../types/api'
import { showFailure } from '../../utils/failures'
import Query from './Query'
import { AvailableStep, useSetActiveStep } from '../../utils/stepUtil'
import { AddIncomeForm } from './AddIncomeForm'
import { t } from '../../utils/cmsTranslations'
import { PersonIndicator } from './PersonIndicator'
import { useAqopi } from '../../hooks/useAqopi'
import { Config, QueryStatus } from '../../types/aqopi'
import { IncomeButton } from './IncomeButton'
import {
	hasUwvData,
	incomeDataToViewModel,
} from '../../utils/noodfonds-service-helpers'
import { fireLogNavigationEvent } from '../../utils/navigation-logging'
import { getRoute } from '../../utils/routeUtils'

export const SinglePerson: React.FC = () => {
	useTitle('Gegevens inkomen en werk')
	useSetActiveStep(AvailableStep.review)
	const { startQuery, configs, result, resetSession } = useAqopi('income')
	const navigate = useNavigate()
	useDisableBackButton()

	const { householdData, setSavedIncomeData, ticket } = useContext(AppContext)
	const [viewModel, setViewModel] = useState<IncomeUI>()
	const [activeConfig, setActiveConfig] = useState<Config>()

	const [failureMessage, setFailureMessage] = useState<{
		title: string
		msg: string
	} | null>(null)

	const name = useMemo(() => {
		const person = householdData.personen[0]
		if (person) {
			return person.volledigeNaam
		} else {
			return ''
		}
	}, [householdData])

	useEffect(() => {
		const active = configs.find((c) => c.status === QueryStatus.Active)
		const failed = configs.find((c) => c.status === QueryStatus.Failed)

		if (failed?.id.includes('mbd-') && failed?.failureType) {
			setFailureMessage(showFailure(failed.failureType))
			resetSession()
		} else {
			setActiveConfig(active)
		}
	}, [configs])

	useEffect(() => {
		result && setViewModel(incomeDataToViewModel(result as IncomeData))
	}, [result])

	const onNavigate = () => {
		persistInkomenQueryResult(result as IncomeData, undefined)

		navigate(getRoute(AvailableStep.energyIntro), { replace: true })
	}

	const persistInkomenQueryResult = (
		income: IncomeData,
		manuallyProvidedIncome?: number
	) => {
		income.manuallyProvidedIncome = manuallyProvidedIncome
		setSavedIncomeData((prev) => [...prev, income])
	}

	const onSave = (manualIncomeFromForm: string) => {
		const manualIncomeAsNumber = parseFloat(
			manualIncomeFromForm.replace(',', '.')
		)
		setViewModel({ ...viewModel, grossIncome: manualIncomeAsNumber })

		persistInkomenQueryResult(
			result as IncomeData,
			Number(manualIncomeAsNumber) || undefined
		)

		navigate(getRoute(AvailableStep.energyIntro), { replace: true })
	}

	const onUwvFetchData = () => {
		if (failureMessage) {
			setFailureMessage(null)
		}

		startQuery(householdData.personen[0]?.id, true)
	}

	const onMbdFetchData = () => {
		startQuery(householdData.personen[0]?.id, true)
	}

	useEffect(() => {
		// this is how we detect fetching progress
		if (activeConfig) {
			fireLogNavigationEvent(AvailableStep.review, ticket, 'gegevens-ophalen')
		}
	}, [activeConfig])

	useEffect(() => {
		if (!!result && typeof viewModel !== 'undefined') {
			fireLogNavigationEvent(AvailableStep.review, ticket, 'inkomen-verzameld')
		}
	}, [result, viewModel])

	if (activeConfig) {
		return <Query activeConfig={activeConfig} />
	}

	const isIncomeInformationPresent = hasUwvData(result as IncomeData)
	const isViewModelPresent = typeof viewModel !== 'undefined'
	const isLoadingViewModel = isIncomeInformationPresent && !isViewModelPresent

	const uwvConfig = configs.find((c) => c.id.includes('uwv-'))
	const mbdConfig = configs.find((c) => c.id.includes('mbd-'))

	return (
		<div className="flex flex-col items-start w-full h-full">
			{failureMessage && (
				<InfoBoxText
					classNameOverride="mb-8"
					title={failureMessage.title}
					text={failureMessage.msg}
				/>
			)}

			{!isIncomeInformationPresent ? (
				<div className="mb-6">
					<Title classNameOverride="mb-4">{t('income.collect.title')}</Title>

					<p className="font-normal">{t('income.collect.noticeForWeb.text')}</p>
				</div>
			) : (
				<Title classNameOverride="mb-4">{t('income.check.title')}</Title>
			)}

			<PersonIndicator name={name} />

			{uwvConfig && (
				<IncomeButton
					isSuccess={
						uwvConfig.status === QueryStatus.Success &&
						mbdConfig?.status !== QueryStatus.Failed
					}
					isAccepted={true}
					config={uwvConfig}
					onFetchData={onUwvFetchData}
					disable={false}
				/>
			)}

			{mbdConfig && (
				<IncomeButton
					isSuccess={
						mbdConfig.status === QueryStatus.Success &&
						uwvConfig?.status !== QueryStatus.Failed
					}
					isAccepted={true}
					config={mbdConfig}
					onFetchData={onMbdFetchData}
					disable={
						configs.find((c) => c.id.includes('uwv-'))?.status !==
						QueryStatus.Success
					}
				/>
			)}

			{isViewModelPresent && (
				<>
					{isIncomeInformationPresent ? (
						<>
							<div className="w-full my-8">
								<UserInfoTable
									birthDate={viewModel?.birthDate}
									currentIncomes={viewModel?.currentIncomes}
									grossIncome={viewModel?.grossIncome}
									grossIncomeYear={viewModel?.grossIncomeYear}
									sivIncomes={viewModel?.sivIncomes}
								/>
							</div>
							<Button
								title={t('income.check.buttonConfirm.title')}
								icon={<ChevronRightIcon />}
								onClick={onNavigate}
							/>
						</>
					) : (
						<AddIncomeForm viewModel={viewModel} onSave={onSave} />
					)}
				</>
			)}

			{isLoadingViewModel && (
				<ActivityIndicatorIcon
					color="currentColor"
					className="animate-spin text-indigo-blue-800 my-6"
				/>
			)}
		</div>
	)
}
