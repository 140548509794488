import { useContext, type FC } from 'react'
import { InfoBoxText } from '../text-types/InfoBoxText'
import { AppContext } from '../../context/app-context'
import { PhoneIcon } from '../../assets/icons/PhoneIcon'
import { MailIcon } from '../../assets/icons/MailIcon'

export type HelpModalContentProps = {
	className?: string
	text: string
	button?: string
	showSupportCode?: boolean
}

export const HelpModalContent: FC<HelpModalContentProps> = ({
	text,
	className,
	button,
	showSupportCode = false,
}) => {
	const appCtx = useContext(AppContext)
	const { supportCode } = appCtx
	const isEmail = button ? button.indexOf('@') > -1 : false
	const Icon = isEmail ? MailIcon : PhoneIcon

	return (
		<section className={className}>
			<p className="text-sm font-normal text-text">{text}</p>

			{button && (
				<a
					className="block font-semibold mt-6 mb-3 text-indigo-blue-800"
					href={isEmail ? `mailto:${button}` : `tel:${button}`}
				>
					<Icon color="#37389c" className="inline" /> {button}
				</a>
			)}

			{showSupportCode && supportCode && (
				<InfoBoxText
					messageType="info"
					classNameOverride="my-6"
					text={`Supportcode: ${supportCode}`}
				/>
			)}
		</section>
	)
}
