import clsx from 'clsx'
import { useFormContext } from 'react-hook-form'

const Pill = ({
	value,
	name,
	type = 'checkbox',
	color,
	index,
}: {
	value: string
	name: string
	type?: 'radio' | 'checkbox'
	color: 'red' | 'green'
	index: number
}) => {
	const { register } = useFormContext()

	return (
		<div className="inline-block">
			<input
				type={type}
				className="sr-only peer"
				value={value}
				id={`${name}_${index}`}
				{...register(type === 'radio' ? name : `${name}.${index}`)}
			/>
			<label
				htmlFor={`${name}_${index}`}
				className={clsx(
					'text-text cursor-pointer inline-block mr-2 mb-2 border rounded-full py-2 px-3',
					'peer-focus-visible:ring peer-focus-visible:ring-indigo-blue-950 peer-focus-visible:ring-offset-4',
					'peer-checked:text-white',
					{
						'peer-checked:bg-red-600 peer-checked:border-red-700':
							color === 'red',
						'peer-checked:bg-green-600 peer-checked:border-green-700':
							color === 'green',
					}
				)}
			>
				{value}
			</label>
		</div>
	)
}

export default Pill
