import { AvailableStep, useSetActiveStep } from '../../utils/stepUtil'
import { ChevronRightIcon } from '../../assets/icons'
import { Button, CompletedText, Title } from '../../components'
import { tContext } from '../../utils/cmsTranslations'

const t = tContext('household')
const tGeneric = tContext('generic')

interface InputCompletedProps {
	onNavigate: () => void
}

export const InputCompleted = ({ onNavigate }: InputCompletedProps) => {
	useSetActiveStep(AvailableStep.people)

	return (
		<div className="w-full h-full">
			<Title classNameOverride="mb-6">{t('collected.title')}</Title>

			<div role="region" aria-live="polite" aria-busy="false">
				<CompletedText
					line1={tGeneric('resourceIdentifierToeslagen.resource')}
					line2={tGeneric('resourceIdentifierToeslagen.completeLabel')}
					classNameOverride="mb-8"
				/>
			</div>

			<Button
				title={t('retrieve.buttonNext.title')}
				icon={<ChevronRightIcon />}
				onClick={onNavigate}
			/>
		</div>
	)
}
