import { MarkdownText, Notice, Title } from '../../components'
import { tContext } from '../../utils/cmsTranslations'

const t = tContext('send.result')

export default function Fail() {
	return (
		<div className="w-full h-full">
			<Title classNameOverride="text-[34px] leading-xl mb-4">
				{t('title')}
			</Title>

			<Notice
				classNameOverride="mb-8"
				noticeType="error"
				title={t('negative.notice.title')}
				text={t('negative.notice.text')}
			/>

			<p className="font-semibold mb-2">{t('negative.titleWhatsNext')}</p>

			<MarkdownText text={t('negative.textWhatsNext')} />
		</div>
	)
}
