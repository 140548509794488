import { TextBold, TextLight, Title } from '../components'

const t = {
	title: 'We missen gegevens',
	contentText1:
		'Er is iets niet goed gegaan aan onze kant, we missen gegevens om verder te gaan.',
	contentText2: 'Probeer het later opnieuw.',
}

export default function EnergyDataMissingDataMessage() {
	return (
		<div className="flex flex-col items-start w-full h-full">
			<div className="w-full">
				<Title classNameOverride="mb-8">{t.title}</Title>

				<TextLight classNameOverride="mb-4">{t.contentText1}</TextLight>

				<TextBold classNameOverride="mb-4">{t.contentText2}</TextBold>
			</div>
		</div>
	)
}
