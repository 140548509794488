import clsx from 'clsx'
import { StarEmptyIcon, StarFilledIcon } from '../../../assets/icons'
import { useEffect, useState } from 'react'

const INITIAL_STAR_STATE = [
	{ ratingValue: 1, filled: false },
	{ ratingValue: 2, filled: false },
	{ ratingValue: 3, filled: false },
	{ ratingValue: 4, filled: false },
	{ ratingValue: 5, filled: false },
]

function ratingToStars(rating: number) {
	return INITIAL_STAR_STATE.map((star) => {
		if (star.ratingValue <= rating) {
			return { ...star, filled: true }
		} else {
			return { ...star, filled: false }
		}
	})
}

const RatingButtons = ({
	onRatingChange,
	rating,
}: {
	onRatingChange?: (rating: number) => void
	rating: number
}) => {
	const [isChangable, setIsChangable] = useState(!!onRatingChange)
	const [ratingState, setRating] = useState(rating)
	const [stars, setStars] = useState(ratingToStars(rating))

	const onRateClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		const newRating = Number(event.currentTarget.dataset.rating)
		const newStars = ratingToStars(newRating)

		setRating(newRating)
		setStars(newStars)
	}

	useEffect(() => {
		setIsChangable(!!onRatingChange)
	}, [onRatingChange])

	useEffect(() => {
		setRating(rating)
	}, [rating])

	useEffect(() => {
		if (ratingState > 0 && !!onRatingChange) {
			onRatingChange(ratingState)
		}
	}, [ratingState])

	return (
		<div className="flex items-center justify-center">
			{stars.map((star) =>
				isChangable ? (
					<button
						key={star.ratingValue}
						onClick={onRateClick}
						data-rating={star.ratingValue}
						className="cursor-pointer mx-2"
					>
						<StarEmptyIcon
							className={clsx(star.filled && 'hidden')}
							aria-hidden="true"
						/>
						<StarFilledIcon
							className={clsx(!star.filled && 'hidden')}
							aria-hidden="true"
						/>
						<span className="sr-only">
							{star.ratingValue === 1
								? '1 ster'
								: `${star.ratingValue} sterren`}
						</span>
					</button>
				) : (
					<span key={star.ratingValue} className="mx-2">
						<StarEmptyIcon className={clsx(star.filled && 'hidden')} />
						<StarFilledIcon className={clsx(!star.filled && 'hidden')} />
					</span>
				)
			)}

			{!isChangable && rating > 0 && (
				<span className="sr-only">
					Beoordeling {rating === 1 ? '1 ster' : `${rating} sterren`}
				</span>
			)}
		</div>
	)
}

export default RatingButtons
