import React from 'react'

type Props = React.SVGProps<SVGSVGElement> & { color?: string }

export function UserIcon({ color, ...props }: Props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			fill="none"
			{...props}
		>
			<path
				fill={color ?? '#000'}
				fillRule="evenodd"
				d="M8.464 3.464a5 5 0 1 1 7.071 7.071 5 5 0 0 1-7.07-7.07ZM12 4a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm-2 12a3 3 0 0 0-3 3v2a1 1 0 1 1-2 0v-2a5 5 0 0 1 5-5h4a5 5 0 0 1 5 5v2a1 1 0 1 1-2 0v-2a3 3 0 0 0-3-3h-4Z"
				clipRule="evenodd"
			/>
		</svg>
	)
}
