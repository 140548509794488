import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { AppContext } from '../../context/app-context'
import RedirectFirstPage from '../../routes/RedirectFirstPage'
import { Substep_0 } from '../../pageComponents/Start/Substep_0'
import { Substep_1 } from '../../pageComponents/Start/Substep_1'
import { Substep_2 } from '../../pageComponents/Start/Substep_2'
import { Substep_2_B } from '../../pageComponents/Start/Substep_2_b'
import { Substep_3 } from '../../pageComponents/Start/Substep_3'
import { Substep_4 } from '../../pageComponents/Start/Substep_4'
import { t as tFull } from '../../utils/cmsTranslations'
import {
	TokenError,
	requestPhoneVerificationRequest,
	sendOtpTokenRequest,
	verifyEmailRequest,
	verifyPhoneRequest,
} from '../../pageComponents/Start/requests'
import { Notice } from '../../components/Notice/Notice'
import { AvailableStep } from '../../utils/stepUtil'
import { isDutchRegionalNumber } from '../../utils/regexpHelpers'
import { getRoute } from '../../utils/routeUtils'

const logError = (err: any) => {
	if (process.env.NODE_ENV === 'development') {
		console.error(err)
	}
}

type NoticeMessage = {
	title: string
	message: string
}

export default function RegistrationStartPage() {
	const {
		email,
		setEmail,
		phoneNumber,
		setPhoneNumber,
		ticket,
		setTicket,
		activeStep,
		setActiveStep,
		isGemachtigd,
		setGemachtigdeClientFields,
	} = useContext(AppContext)
	const navigate = useNavigate()
	const [errorMessage, setErrorMessage] = useState<NoticeMessage | null>(null)
	const [successMessage, setSuccessMessage] = useState<NoticeMessage | null>(
		null
	)

	// move to the first step if the user is on the start page
	useEffect(() => {
		if (activeStep === AvailableStep.none) {
			setActiveStep(AvailableStep.registrationStart)
		}
	}, [activeStep])

	const onStep1Submit = async (email: string) => {
		setErrorMessage(null)

		return sendOtpTokenRequest(email)
			.then(() => {
				setEmail(email)
				setSuccessMessage({
					title: tFull('registration.email.feedbackSuccess.title'),
					message: tFull('registration.email.feedbackSuccess.text', {
						emailAddress: email,
					}),
				})
				setActiveStep(AvailableStep.registrationValidateEmail)
			})
			.catch((err) => {
				logError(err)
				setErrorMessage({
					title: tFull('registration.email.feedbackError.title'),
					message: tFull('registration.email.feedbackError.text'),
				})
				setSuccessMessage(null)
			})
	}

	const onStep2Submit = async (code: string) => {
		setErrorMessage(null)

		return verifyEmailRequest(email, code)
			.then((ticket) => {
				setTicket(ticket)
				setSuccessMessage({
					title: '',
					message: tFull('registration.emailVerification.ariaSuccess'),
				})

				if (isGemachtigd) {
					setActiveStep(AvailableStep.registrationExtraDetails)
				} else {
					setActiveStep(AvailableStep.registrationPhoneNumber)
				}
			})
			.catch((err) => {
				logError(err)
				setSuccessMessage(null)

				if (err instanceof TokenError) {
					setErrorMessage({
						title: tFull(
							'registration.emailVerification.feedbackTokenError.title'
						),
						message: tFull(
							'registration.emailVerification.feedbackTokenError.text'
						),
					})
				} else {
					setErrorMessage({
						title: tFull('registration.emailVerification.feedbackError.title'),
						message: tFull('registration.emailVerification.feedbackError.text'),
					})
				}
			})
	}

	const onStep2BSubmit = (name: string, email: string, phoneNumber: string) => {
		setSuccessMessage(null)
		setGemachtigdeClientFields({
			name,
			email,
			phoneNumber,
		})
		setActiveStep(AvailableStep.registrationPhoneNumber)
	}

	const onStep3Submit = async (sanitizedPhoneNumber: string) => {
		setErrorMessage(null)

		// we skip the phone number validation if the user enters a Dutch regional number
		if (isDutchRegionalNumber(sanitizedPhoneNumber)) {
			setPhoneNumber(sanitizedPhoneNumber)
			navigate(getRoute(AvailableStep.registrationExtension))
			setSuccessMessage(null)
			return
		}

		return requestPhoneVerificationRequest(email, sanitizedPhoneNumber, ticket)
			.then(({ data }) => {
				setPhoneNumber(sanitizedPhoneNumber)

				if (data.verified) {
					navigate(getRoute(AvailableStep.registrationExtension))
					setSuccessMessage(null)
				} else {
					setSuccessMessage({
						title: tFull('registration.phoneNumber.feedbackSuccess.title'),
						message: tFull('registration.phoneNumber.feedbackSuccess.text', {
							phoneNumber: sanitizedPhoneNumber,
						}),
					})
					setActiveStep(AvailableStep.registrationValidatePhoneNumber)
				}
			})
			.catch((err) => {
				logError(err)
				setErrorMessage({
					title: tFull('registration.phoneNumber.feedbackError.title'),
					message: tFull('registration.phoneNumber.feedbackError.text'),
				})
				setSuccessMessage(null)
			})
	}

	const onStep4Retry = async () => {
		setErrorMessage(null)
		setSuccessMessage(null)
		setActiveStep(AvailableStep.registrationPhoneNumber)
	}

	const onStep4Submit = async (token: string) => {
		setErrorMessage(null)

		return verifyPhoneRequest(email, phoneNumber, token, ticket)
			.then(() => {
				navigate(getRoute(AvailableStep.registrationExtension))
				setSuccessMessage(null)
			})
			.catch((err) => {
				logError(err)

				if (err instanceof TokenError) {
					setErrorMessage({
						title: tFull(
							'registration.phoneNumberVerification.feedbackTokenError.title'
						),
						message: tFull(
							'registration.phoneNumberVerification.feedbackTokenError.text'
						),
					})
				} else {
					setErrorMessage({
						title: tFull(
							'registration.phoneNumberVerification.feedbackError.title'
						),
						message: tFull(
							'registration.phoneNumberVerification.feedbackError.text'
						),
					})
				}
				setSuccessMessage(null)
			})
	}

	const subSteps: Record<number, JSX.Element> = {
		[AvailableStep.registrationStart]: (
			<Substep_0
				onClick={() => setActiveStep(AvailableStep.registrationEmail)}
			/>
		),
		[AvailableStep.registrationEmail]: (
			<Substep_1 email={email} onSubmit={onStep1Submit} />
		),
		[AvailableStep.registrationValidateEmail]: (
			<Substep_2
				email={email}
				onBack={() => {
					setSuccessMessage(null)
					setActiveStep(AvailableStep.registrationEmail)
				}}
				onSubmit={onStep2Submit}
			/>
		),
		[AvailableStep.registrationExtraDetails]: (
			<Substep_2_B onSubmit={onStep2BSubmit} />
		),
		[AvailableStep.registrationPhoneNumber]: (
			<Substep_3 onSubmit={onStep3Submit} />
		),
		[AvailableStep.registrationValidatePhoneNumber]: (
			<Substep_4 onSubmit={onStep4Submit} onRetry={onStep4Retry} />
		),
	}

	return (
		<RedirectFirstPage>
			<div className="flex items-center justify-center w-full h-full">
				<div className="flex flex-col items-center w-full h-full">
					{errorMessage && (
						<Notice
							noticeType="error"
							title={errorMessage.title}
							classNameOverride="mb-8"
							text={errorMessage.message}
						/>
					)}

					{successMessage && (
						<Notice
							noticeType="success"
							title={successMessage.title}
							classNameOverride="mb-8"
							text={successMessage.message}
						/>
					)}

					{subSteps[activeStep]}
				</div>
			</div>
		</RedirectFirstPage>
	)
}
