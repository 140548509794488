export function postReview(
	feedbackData: {
		email: string
		stars: number
		positives: string[]
		negatives: string[]
		aanvraagGedaanVorigJaar: boolean | null
	},
	ticket: string
) {
	try {
		const serviceUrl = process.env.REACT_APP_ENERGY_SERVICE

		fetch(`${serviceUrl}/feedback`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Ticket: ticket,
			},
			body: JSON.stringify(feedbackData),
		})
	} catch (error: any) {
		console.error(error)
	}
}
