import React from 'react'

type Props = {
	children: React.ReactNode
	classNameOverride?: string
	size?: 'lg' | 'md' | 'sm'
} & (
	| {
			onClick: () => void
			isButton: true
	  }
	| {
			onClick?: never
			isButton?: false
	  }
)

const textSizes = {
	lg: 'text-lg',
	md: 'text-base',
	sm: 'text-sm',
}

export const TextLight: React.FC<Props> = ({
	children,
	classNameOverride,
	onClick,
	size = 'md',
	isButton = false,
}) =>
	isButton ? (
		<button
			className={`font-normal text-text ${textSizes[size]} ${classNameOverride}`}
			onClick={onClick}
		>
			{children}
		</button>
	) : (
		<p
			className={`font-normal text-text ${textSizes[size]} ${classNameOverride}`}
		>
			{children}
		</p>
	)
