export const ProgressIndicator = ({
	step,
	maxSteps,
}: {
	step: number
	maxSteps: number
}) => {
	const width = `${(Math.min(maxSteps, Math.max(0, step)) / maxSteps) * 100}%`

	return (
		<div className="mb-8">
			<p className="pt-2 pb-6 font-semibold text-sm text-right">
				{/* line height 1.5 */}
				Stap {step} van {maxSteps}
			</p>

			<div className="h-[6px] bg-[#FDF0E9] relative">
				<div className="h-[6px] bg-orange-600" style={{ width }} />
			</div>
		</div>
	)
}
