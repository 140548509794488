import { useNavigate } from 'react-router'
import Agreement from '../../components/Agreement/Agreement'
import { getRoute } from '../../utils/routeUtils'
import { AvailableStep } from '../../utils/stepUtil'

export default function Step2() {
	const navigate = useNavigate()

	const accept = () => {
		navigate(getRoute(AvailableStep.householdIntro), { replace: true })
	}

	const cancel = () => {
		navigate('/', { replace: true })
	}

	return <Agreement onAccepted={accept} onCancel={cancel} />
}
