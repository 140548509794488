export function getSivCodeDescription(code: number) {
	switch (code) {
		case 11:
			return 'Loon of salaris ambtenaren in de zin van de Ambtenarenwet'
		case 13:
			return 'Loon of salaris directeuren van een nv of bv, wel verzekerd voor de werknemersverzekeringen'
		case 15:
			return 'Loon of salaris niet onder te brengen onder 11, 13 of 17'
		case 17:
			return 'Loon of salaris directeur-grootaandeelhouder van een nv of bv, niet verzekerd voor de werknemersverzekeringen'
		case 18:
			return 'Wachtgeld van een overheidsinstelling'
		case 22:
			return 'Uitkering in het kader van de Algemene Ouderdomswet (aow)'
		case 23:
			return 'Oorlogs- en verzetspensioenen'
		case 24:
			return 'Uitkering in het kader van de Algemene nabestaandenwet (Anw)'
		case 31:
			return 'Uitkering in het kader van de Ziektewet (zw) en vrijwillige verzekering Ziektewet'
		case 32:
			return 'Uitkering in het kader van de Wet op de arbeidsongeschiktheidsverzekering (wao) en particuliere verzekering ziekte, invaliditeit en ongeval'
		case 33:
			return 'Uitkering in het kader van de Nieuwe Werkloosheidswet (nww)'
		case 34:
			return 'Uitkering in het kader van de Wet inkomensvoorziening oudere en gedeeltelijk arbeidsongeschikte werkloze werknemers (ioaw)'
		case 35:
			return 'Vervolguitkering in het kader van de Nieuwe Werkloosheidswet (nww)'
		case 36:
			return 'Uitkering in het kader van de Wet arbeidsongeschiktheidsverzekering zelfstandigen (Waz)'
		case 37:
			return 'Wet arbeidsongeschiktheidsvoorziening jonggehandicapten (Wajong)'
		case 38:
			return 'Samenloop (gelijktijdig of volgtijdelijk) van uitkeringen van Wet Wajong met Waz, wao/iva of wga'
		case 39:
			return 'Uitkering in het kader van de Regeling inkomensvoorziening volledig arbeidsongeschikten (iva)'
		case 40:
			return 'Uitkering in het kader van de Regeling werkhervatting gedeeltelijk arbeidsgeschikten (wga)'
		case 42:
			return 'Uitkering in het kader van bijstandsbesluit Zelfstandigen (Bbz)'
		case 43:
			return 'Uitkering in het kader van de Participatiewet (voorheen Wwb)'
		case 45:
			return 'Uitkering in het kader van de Wet inkomensvoorziening oudere en gedeeltelijk arbeidsongeschikte gewezen zelfstandigen (ioaz)'
		case 46:
			return 'Uitkering uit hoofde van de Toeslagenwet'
		case 50:
			return 'Uitkeringen in het kader van overige sociale verzekeringswetten; hieronder vallen ook de Ongevallenwet 1921, de Land- en tuinbouwongevallenwet 1922 en de Zeeongevallenwet 1919 (niet 22, 24, 31 tot en met 45 of 52)'
		case 52:
			return 'Uitkering in het kader van de Wet inkomensvoorziening oudere werklozen (iow)'
		case 53:
			return 'Uitkeringen in het kader van vervroegde uittreding'
		case 54:
			return 'Opname levenslooptegoed door een werknemer die op 1 januari 61 jaar of ouder is'
		case 55:
			return 'Uitkering in het kader van de Algemene Pensioenwet Politieke Ambtsdragers (APPA)'
		case 56:
			return 'Ouderdomspensioen dat via de werkgever is opgebouwd of ouderdomspensioen opgebouwd via een verplichte beroepspensioenregeling/bedrijfstakpensioenregeling'
		case 57:
			return 'Nabestaandenpensioen dat via de werkgever is opgebouwd of nabestaandenpensioen opgebouwd via een verplichte beroepspensioenregeling/bedrijfstakpensioenregeling'
		case 58:
			return 'Arbeidsongeschiktheidspensioen dat via de werkgever is opgebouwd of arbeidsongeschiktheidspensioen opgebouwd via een verplichte beroepspensioenregeling/bedrijfstakpensioenregeling'
		case 59:
			return 'Lijfrenten die zijn afgesloten in het kader van een individuele of collectieve arbeidsovereenkomst'
		case 60:
			return 'Lijfrenten die niet zijn afgesloten in het kader van een individuele of collectieve arbeidsovereenkomst'
		case 61:
			return 'Aanvulling van de werkgever aan een werknemer op een uitkering werknemersverzekeringen, terwijl de dienstbetrekking is beëindigd'
		case 62:
			return 'Ontslagvergoeding/transitievergoeding'
		case 63:
			return 'Overige, niet hiervoor aangegeven, pensioenen of samenloop van meerdere pensioenen/lijfrenten of een betaling op grond van een afspraak na einde dienstbetrekking'
	}

	return 'Overige'
}
