import { useContext, useMemo } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { useNavigate } from 'react-router'
import { yupResolver } from '@hookform/resolvers/yup'

import { Button, InfoBoxText, SecondaryButton, Title } from '../components'
import { ChevronRightIcon, TrashIcon } from '../assets/icons'
import { EnergyData, EnergyType } from '../types/api'
import { useSubmitEnergyFormData } from '../hooks/useSubmitEnergyFormData'
import InnerForm from './EnergyDataForm/InnerForm'
import {
	EnergyDataFormFields,
	EnergyDataFormSupplierDataFields,
	energyDataFormSchema,
} from './EnergyDataForm/energyDataFormSchema'
import { AppContext } from '../context/app-context'
import { tContext } from '../utils/cmsTranslations'
import { getInverseEnergyType } from '../utils/energyUtil'
import { getRoute } from '../utils/routeUtils'
import { AvailableStep } from '../utils/stepUtil'

const t = tContext('energy.input')

interface EnergyDataFormProps {
	supplierData: EnergyData
	secondarySupplierData?: EnergyData
	period: string
	periodLabel: string
	isPrimary: boolean
}

function getFirstIfOnlyOne(array: string[], fallback: string) {
	return array.length === 1 ? array[0] : fallback
}

export default function EnergyDataForm({
	supplierData,
	secondarySupplierData,
	period,
}: EnergyDataFormProps) {
	const { householdData, energyProviders: energyProvidersFromContext } =
		useContext(AppContext)
	const { submit } = useSubmitEnergyFormData()
	const navigate = useNavigate()

	// prepare data for form fields
	const contractHolders = useMemo(
		() => householdData.personen.map((p) => p.volledigeNaam),
		[householdData]
	)

	const initialContractHolderName = useMemo(() => {
		const name =
			supplierData.selectedContractHolder?.volledigeNaam ||
			getFirstIfOnlyOne(contractHolders, '')
		const person = householdData.personen.find(
			({ volledigeNaam }) => volledigeNaam === name
		)

		return person?.volledigeNaam || ''
	}, [supplierData, contractHolders])

	const favoriteEnergyProviders = useMemo(
		() => energyProvidersFromContext.favorites,
		[energyProvidersFromContext.favorites]
	)

	const energyProviders = useMemo(
		() => Object.values(energyProvidersFromContext.suppliers),
		[energyProvidersFromContext.suppliers]
	)

	// set up React hook form
	const methods = useForm<EnergyDataFormFields>({
		mode: 'onChange',
		resolver: yupResolver(energyDataFormSchema),
		defaultValues: {
			primary: {
				energyType: supplierData.energyType,
				contractHolderName:
					supplierData.selectedContractHolder?.volledigeNaam ||
					initialContractHolderName,
				supplier: supplierData.supplier,
				clientNumber: supplierData.clientNumber,
				advanceAdvice: supplierData.advanceAdvice
					? String(supplierData.advanceAdvice)
					: '',
				actualAdvance: supplierData.actualAdvance
					? String(supplierData.actualAdvance)
					: '',
			},
			secondary: secondarySupplierData
				? {
						energyType: secondarySupplierData.energyType,
						contractHolderName:
							secondarySupplierData.selectedContractHolder?.volledigeNaam ||
							initialContractHolderName,
						supplier: secondarySupplierData.supplier,
						clientNumber: secondarySupplierData.clientNumber,
						advanceAdvice: secondarySupplierData.advanceAdvice
							? String(secondarySupplierData.advanceAdvice)
							: '',
						actualAdvance: supplierData.actualAdvance
							? String(secondarySupplierData.actualAdvance)
							: '',
				  }
				: null,
			isSecondaryAdded: !!secondarySupplierData,
		},
	})

	const { handleSubmit, getValues, setValue } = methods

	const addSecondaryEnergyData = () => {
		if (!getValues('isSecondaryAdded')) {
			setValue('primary.energyType', EnergyType.ELEKTRA, {
				shouldValidate: true,
			})

			setValue(
				'secondary',
				{
					contractHolderName: initialContractHolderName,
					energyType: getInverseEnergyType(
						getValues('primary.energyType') as EnergyType
					),
					supplier: '',
					clientNumber: '',
					advanceAdvice: '',
					actualAdvance: '',
				},
				{ shouldValidate: false }
			)
			setValue('isSecondaryAdded', true, { shouldValidate: true })

			setTimeout(() => {
				document.getElementById('secondSupplier')?.scrollIntoView()
			}, 150)
		}
	}

	const onRemoveSecondarySupplier = () => {
		if (confirm('Wilt je de tweede leverancier toch verwijderen?')) {
			setValue('secondary', null, { shouldValidate: true })
			setValue('isSecondaryAdded', false, { shouldValidate: true })
			setValue('primary.energyType', EnergyType.ELEKTRA_EN_GAS_OF_WARMTE, {
				shouldValidate: true,
			})
		}
	}

	const onSubmit = async (fields: EnergyDataFormFields) => {
		try {
			const convertToEnergyData = (
				data: EnergyDataFormSupplierDataFields,
				isPrimary: boolean
			): EnergyData => {
				return {
					selectedContractHolder: householdData.personen.find(
						({ volledigeNaam }) => volledigeNaam === data.contractHolderName
					),
					energyType: data.energyType,
					supplier: data.supplier,
					clientNumber: data.clientNumber,
					advanceAdvice: data.advanceAdvice,
					actualAdvance: data.actualAdvance,
					paymentAgreement: '',
					isPrimary,
				}
			}

			const primary = convertToEnergyData(fields.primary, true)
			const secondary = fields.secondary
				? convertToEnergyData(fields.secondary, false)
				: undefined

			await submit(period, primary, secondary)

			navigate(getRoute(AvailableStep.overview))
		} catch (err) {
			// @todo do we want to display a custom error?
			console.error(err)
		}
	}

	return (
		<div className="flex flex-col items-start w-full h-full">
			<FormProvider {...methods}>
				<form
					className="w-full"
					onSubmit={handleSubmit(onSubmit)}
					autoComplete="off"
				>
					<Title classNameOverride="mb-4">{t('title')}</Title>

					<InfoBoxText
						title={t('notice.title')}
						text={t('notice.text')}
						classNameOverride="mb-6"
					/>

					<InnerForm
						isPrimary={true}
						isBothActive={getValues('isSecondaryAdded')}
						favoriteEnergyProviders={favoriteEnergyProviders}
						energyProviders={energyProviders}
						contractHolders={contractHolders}
						addSecondaryEnergyData={
							!getValues('isSecondaryAdded')
								? addSecondaryEnergyData
								: undefined
						}
					/>

					{getValues('isSecondaryAdded') && (
						<div id="secondSupplier">
							<div className="flex align-center justify-center mb-8">
								<div className="flex-1">
									<Title>{t('secondSupplierPart.title')}</Title>
								</div>

								<div className="max-w-[45%]">
									<SecondaryButton
										icon={<TrashIcon color="currentColor" />}
										variant="small"
										onClick={onRemoveSecondarySupplier}
										title={t('secondSupplierPart.buttonRemove.title')}
									/>
								</div>
							</div>

							<InnerForm
								isPrimary={false}
								isBothActive={true}
								favoriteEnergyProviders={favoriteEnergyProviders}
								energyProviders={energyProviders}
								contractHolders={contractHolders}
							/>
						</div>
					)}

					<Button
						title={t('buttonSubmit.title')}
						icon={<ChevronRightIcon />}
						buttonProps={{ type: 'submit' }}
					/>
				</form>
			</FormProvider>
		</div>
	)
}
