import React, { createContext, useEffect, useRef, useState } from 'react'
import {
	EnergyProvidersResponse,
	HouseholdData,
	IncomeData,
	PeriodEnergyData,
} from '../types/api'
import { AppError } from '../components/modal/ErrorModal'
import { http } from '../utils/http'
import { setGemachtigdeText } from '../utils/text-transform'
import {
	SubStepConfig,
	getSubStepConfigByMainStepAndOverallStep,
} from '../utils/stepUtil'
import { IS_DEVELOPMENT_ENV } from '../utils/utils'
import { AqopiSession } from '../types/aqopi'

type BackFunction = () => void

type ExtraClientFields = {
	name: string
	email: string
	phoneNumber: string
}

type AppCtxType = {
	subStepConfig: SubStepConfig
	activeStep: number
	setActiveStep: React.Dispatch<React.SetStateAction<number>>
	mainStep: number
	setMainStep: React.Dispatch<React.SetStateAction<number>>
	backStep: null | BackFunction
	setBackStep: React.Dispatch<React.SetStateAction<BackFunction | null>>
	householdData: HouseholdData
	setHouseholdData: React.Dispatch<React.SetStateAction<HouseholdData>>
	ticket: string
	setTicket: React.Dispatch<React.SetStateAction<string>>
	setEnergyData: (energyData: PeriodEnergyData[]) => void
	energyData: PeriodEnergyData[]
	email: string
	setEmail: React.Dispatch<React.SetStateAction<string>>
	phoneNumber: string
	setPhoneNumber: React.Dispatch<React.SetStateAction<string>>
	savedIncomeData: IncomeData[]
	setSavedIncomeData: React.Dispatch<React.SetStateAction<IncomeData[]>>
	appLoaded: boolean
	setAppLoaded: React.Dispatch<React.SetStateAction<boolean>>
	energyProviders: EnergyProvidersResponse
	setEnergyProviders: React.Dispatch<
		React.SetStateAction<EnergyProvidersResponse>
	>
	gemachtigdeClientFields: null | ExtraClientFields
	setGemachtigdeClientFields: React.Dispatch<
		React.SetStateAction<null | ExtraClientFields>
	>
	appError?: AppError
	setAppError: React.Dispatch<React.SetStateAction<AppError | undefined>>
	aqopiSession: AqopiSession | null
	setAqopiSession: React.Dispatch<React.SetStateAction<AqopiSession | null>>
	isGemachtigd: boolean
	setIsGemachtigd: React.Dispatch<React.SetStateAction<boolean>>
	isPrecheckDone: boolean
	setPrecheckDone: React.Dispatch<React.SetStateAction<boolean>>
	wasAutomaticallySignedOut: boolean
	setWasAutomaticallySignedOut: React.Dispatch<React.SetStateAction<boolean>>
	isFailed: any
	setIsFailed: React.Dispatch<React.SetStateAction<any>>
	supportCode: string | null
	setSupportCode: React.Dispatch<React.SetStateAction<string | null>>
}

export const AppContext = createContext<AppCtxType>({} as AppCtxType)

type Props = {
	children: React.ReactNode
}

const INITIAL_IS_FAILED = { available: true }

const INITIAL_HOUSEHOLD_DATA: HouseholdData = {
	postcode: '',
	huisnummerMetToevoeging: '',
	mijnHuishoudenParseResult: null,
	personen: [],
}

const initialSubStepConfig: SubStepConfig =
	getSubStepConfigByMainStepAndOverallStep(0, 0, false)

const getExpireDateTime = () => {
	const TIMEOUT_IN_MS = 60 * 1000 * 15 // 15 minutes
	return new Date().getTime() + TIMEOUT_IN_MS
}

export const AppContextProvider: React.FC<Props> = ({ children }) => {
	const expireDate = useRef(getExpireDateTime())
	const [supportCode, setSupportCode] = useState<string | null>(null)
	const [energyProviders, setEnergyProviders] =
		useState<EnergyProvidersResponse>({ favorites: [], suppliers: {} })

	const [householdData, setHouseholdData] = useState<HouseholdData>(
		INITIAL_HOUSEHOLD_DATA
	)
	const [energyData, setEnergyData] = useState<PeriodEnergyData[]>([])

	const [ticket, setTicket] = useState('')
	const [email, setEmail] = useState(
		IS_DEVELOPMENT_ENV ? 'test-user@iwize.nl' : ''
	)

	const [phoneNumber, setPhoneNumber] = useState('')

	const [savedIncomeData, setSavedIncomeData] = useState<IncomeData[]>([])

	const [isFailed, setIsFailed] = useState<any>(INITIAL_IS_FAILED)

	const [wasAutomaticallySignedOut, setWasAutomaticallySignedOut] =
		useState(false)

	const [appLoaded, setAppLoaded] = useState(false)

	const [backStep, setBackStep] = useState<BackFunction | null>(null)

	const [appError, setAppError] = useState<AppError | undefined>()

	const [aqopiSession, setAqopiSession] = useState<AqopiSession | null>(null)

	const [isGemachtigd, setIsGemachtigd] = useState(false)
	const [gemachtigdeClientFields, setGemachtigdeClientFields] =
		useState<null | ExtraClientFields>(null)

	const [activeStep, setActiveStep] = useState(-1)
	const [mainStep, setMainStep] = useState(-1)
	const [subStepConfig, setSubStepConfig] =
		useState<SubStepConfig>(initialSubStepConfig)
	const [isPrecheckDone, setPrecheckDone] = useState(true)

	useEffect(() => {
		setAppLoaded(true)
		http.setErrorHandler(setAppError)
	}, [])

	useEffect(() => {
		setGemachtigdeText(isGemachtigd)
	}, [isGemachtigd])

	useEffect(() => {
		setSubStepConfig(
			getSubStepConfigByMainStepAndOverallStep(
				mainStep,
				activeStep,
				isGemachtigd
			)
		)
	}, [mainStep, activeStep, isGemachtigd])

	useEffect(() => {
		const updateExpireDate = () => {
			expireDate.current = getExpireDateTime()
		}

		// these event types reset the expire time
		document.body.addEventListener('mousemove', updateExpireDate)
		document.body.addEventListener('keydown', updateExpireDate)
		document.body.addEventListener('click', updateExpireDate)
		window.addEventListener('blur', updateExpireDate)
		window.addEventListener('focus', updateExpireDate)

		return () => {
			document.body.removeEventListener('mousemove', updateExpireDate)
			document.body.removeEventListener('keydown', updateExpireDate)
			document.body.removeEventListener('click', updateExpireDate)
			window.removeEventListener('blur', updateExpireDate)
			window.removeEventListener('focus', updateExpireDate)
		}
	}, [])

	useEffect(() => {
		const intervalId = setInterval(() => {
			// debug with this log (should change on all above mentioned events):
			// console.log('[check expireDate]', expireDate.current)

			if (expireDate.current < new Date().getTime()) {
				expireDate.current = getExpireDateTime()

				setTicket('')
				setAqopiSession(null)
				setActiveStep(-1)
				setMainStep(-1)
				setBackStep(null)
				setSubStepConfig(initialSubStepConfig)
				setIsFailed(INITIAL_IS_FAILED)
				setSavedIncomeData([])
				setPhoneNumber('')
				setGemachtigdeClientFields(null)
				setEmail('')
				setTicket('')
				setSupportCode(null)
				setHouseholdData(INITIAL_HOUSEHOLD_DATA)

				location.href =
					'/?' +
					(isGemachtigd ? 'machtiging=true&' : '') +
					'timeoutMelding=true'
			}
			// we check every five seconds if we are still within the expire limit
		}, 5000)

		return () => {
			clearInterval(intervalId)
		}
	}, [])

	const value = {
		wasAutomaticallySignedOut,
		setWasAutomaticallySignedOut,
		supportCode,
		setSupportCode,
		subStepConfig,
		activeStep,
		setActiveStep,
		backStep,
		setBackStep,
		mainStep,
		setMainStep,
		householdData: householdData,
		setHouseholdData: setHouseholdData,
		energyData,
		setEnergyData,
		ticket: ticket,
		setTicket: setTicket,
		email,
		setEmail,
		phoneNumber,
		setPhoneNumber,
		savedIncomeData,
		setSavedIncomeData,
		appLoaded,
		setAppLoaded,
		energyProviders,
		setEnergyProviders,
		appError,
		setAppError,
		aqopiSession,
		setAqopiSession,
		gemachtigdeClientFields,
		setGemachtigdeClientFields,
		isGemachtigd,
		setIsGemachtigd,
		isPrecheckDone,
		setPrecheckDone,
		isFailed,
		setIsFailed,
	}

	return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}
