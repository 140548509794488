import React from 'react'
import { useFocusRef } from '../../hooks/useFocusRef'

type Props = {
	children: React.ReactNode
	classNameOverride?: string
	focusOnRender?: boolean
}

const Title = ({
	children,
	classNameOverride = '',
	focusOnRender = true,
}: Props) => {
	const ref = useFocusRef(children)

	return (
		<h2
			ref={focusOnRender ? ref : undefined}
			className={`font-semibold text-text text-[22px] ${classNameOverride}`}
		>
			{children}
		</h2>
	)
}
export { Title }
