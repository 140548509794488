const setLoggerUrl = (env?: string) => {
	switch (env) {
		case 'development':
			return 'https://logging.service.iwize.nl/env/dev/api/v1'
		case 'acct':
			return 'https://logging.service.iwize.nl/env/acct/api/v1'
		case 'test':
			return 'https://logging.service.iwize.nl/env/test/api/v1'
		case 'production':
			return 'https://logging.service.iwize.nl/api/v1'
		default:
			return 'https://logging.service.iwize.nl/env/dev/api/v1'
	}
}

const SKIP_LOGGING = process.env.REACT_APP_SKIP_LOGGING === 'true'

export function logFunc() {
	const loggerUrl = setLoggerUrl(process.env.REACT_APP_ENV)

	function log(type: string, value: string, ticket: string) {
		if (SKIP_LOGGING) return
		if (!ticket) return

		try {
			const timestamp = new Date().toISOString()
			fetch(`${loggerUrl}/log/event`, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json', ticket },
				mode: 'no-cors',
				body: JSON.stringify({
					timestamp,
					type: `web-${type}`,
					value: `${value}`,
					origin: 'noodfonds-web',
				}),
			})
		} catch (error: any) {
			console.log(error)
		}
	}

	function logError(error: { name: string; message: string }, ticket: string) {
		if (SKIP_LOGGING) return
		if (!ticket) return

		try {
			const timestamp = new Date().toISOString()
			fetch(`${loggerUrl}/log/event`, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json', ticket },
				body: JSON.stringify({
					timestamp,
					type: 'web-error',
					error,
					origin: 'noodfonds-web',
				}),
			})
		} catch (error: any) {
			console.log(error)
		}
	}

	return {
		log,
		logError,
	}
}

export function useLogger() {
	const loggerUrl = setLoggerUrl(process.env.REACT_APP_ENV)

	function log(type: string, value: string, ticket: string) {
		if (SKIP_LOGGING) return
		if (!ticket) return

		try {
			const timestamp = new Date().toISOString()
			fetch(`${loggerUrl}/log/event`, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json', ticket },
				body: JSON.stringify({
					timestamp,
					type: `web-${type}`,
					value: `${value}`,
					origin: 'noodfonds-web',
				}),
			})
		} catch (error: any) {
			console.log(error)
		}
	}

	function logError(error: { name: string; message: string }, ticket: string) {
		if (SKIP_LOGGING) return
		if (!ticket) return

		try {
			const timestamp = new Date().toISOString()
			fetch(`${loggerUrl}/log/event`, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json', ticket },
				body: JSON.stringify({
					timestamp,
					type: 'web-error',
					error,
					origin: 'noodfonds-web',
				}),
			})
		} catch (error: any) {
			console.log(error)
		}
	}

	return {
		log,
		logError,
	}
}
