import { ActivityIndicatorIcon } from '../../assets/icons'
import { MarkdownText } from '../../components'
import { t } from '../../utils/cmsTranslations'

export const FetchingProgress = () => (
	<div className="flex items-center w-full h-full">
		<div className="flex flex-col items-center w-full my-24">
			<MarkdownText text={t('collect.status.textMtsProgress')} />

			<ActivityIndicatorIcon
				color="currentColor"
				className="animate-spin text-indigo-blue-800 mb-3 mt-[52px]"
			/>

			<div
				role="region"
				aria-live="polite"
				aria-busy="true"
				className="sr-only"
			>
				<MarkdownText text={t('collect.status.textMtsProgress')} />
			</div>
		</div>
	</div>
)
