import { useContext, useEffect, useState } from 'react'
import { AppContext } from '../context/app-context'
import { EnergyProvidersResponse } from '../types/api'

export function useEnergyProviders() {
	const { energyProviders, setEnergyProviders } = useContext(AppContext)
	const [loading, setLoading] = useState(false)

	async function fetchProviders() {
		setLoading(true)
		try {
			const fetchResult = await fetch(
				`${String(process.env.REACT_APP_ENERGY_PROVIDER_LIST)}?${Date.now()}`
			)
			const data = await fetchResult.json()
			const newEnergyProviders = data as EnergyProvidersResponse

			if (!!newEnergyProviders.favorites && !!newEnergyProviders.suppliers) {
				setEnergyProviders({
					favorites: newEnergyProviders.favorites.map(
						(favorite) => newEnergyProviders.suppliers[favorite] || favorite
					),
					suppliers: newEnergyProviders.suppliers,
				})
			} else if (data.eneco) {
				setEnergyProviders({
					favorites: [],
					suppliers:
						newEnergyProviders as unknown as EnergyProvidersResponse['suppliers'],
				})
			} else {
				console.error(
					'Unexpected or missing data in energyprovider response',
					data
				)
			}
		} catch (e) {
			console.error('Error fetching energy suppliers', e)
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		if (Object.keys(energyProviders.suppliers).length === 0) {
			fetchProviders()
		}
	}, [])

	return { fetchProviders, loading }
}
