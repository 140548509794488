
let flags: Record<string,boolean> = {}

export async function initFeatureFlags() {
	const response = await fetch(process.env.REACT_APP_ENERGY_SERVICE + '/features')
	flags = await response.json()
}

export function isFeatureFlagEnabled(flag: string): boolean {
	return flags[flag] || false
}