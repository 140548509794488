import { HTMLProps, forwardRef } from 'react'
import clsx from 'clsx'
import InfoPopover from '../info-popover/info-popover'
import { WarningIcon } from '../../assets/icons'

const colorVariants = {
	error: 'text-red-900',
	warning: 'text-[#00F]',
}

type Props = {
	title: string
	name: string
	icon?: React.ReactNode
	classNameOverride?: string
	type?: React.HTMLInputTypeAttribute
	isMoney?: boolean
	hint?: boolean | string
	hintVariant?: keyof typeof colorVariants
	inputProps?: any
	tooltip?: string
	isInvalid?: boolean
}

// eslint-disable-next-line react/display-name
export const TextInputControlled = forwardRef<
	HTMLInputElement,
	Props & HTMLProps<HTMLInputElement>
>(
	(
		{
			title,
			name,
			onChange,
			value,
			tooltip,
			placeholder,
			classNameOverride,
			type,
			isMoney,
			hint,
			hintVariant,
			inputProps,
			...restProps
		},
		ref
	) => {
		if (!hintVariant) hintVariant = 'error'

		const isHintPresent = typeof hint === 'string'
		const invalidId =
			isHintPresent && hintVariant === 'error'
				? name + '-errormessage'
				: undefined

		return (
			<div className={`flex flex-col w-full items-start ${classNameOverride}`}>
				{tooltip ? (
					<div className="flex justify-between w-full mb-2">
						<label
							htmlFor={name}
							className={'font-semibold text-base'}
							style={{ cursor: 'help' }}
						>
							{title}{' '}
						</label>

						<InfoPopover text={tooltip} />
					</div>
				) : (
					<label
						htmlFor={name}
						className={clsx(
							'font-normal text-base mb-2',
							isHintPresent && 'text-red-900'
						)}
					>
						<span className="font-semibold mr-1">{title}</span>
						{restProps['aria-required'] && (
							<span className="font-normal">(verplicht)</span>
						)}
					</label>
				)}

				<div
					className={clsx(
						'relative border rounded-md bg-white flex flex-row items-center w-full',
						isHintPresent && 'border-red-900',
						!isHintPresent && 'border-gray-500'
					)}
				>
					<input
						ref={ref}
						id={name}
						name={name}
						data-testid={name}
						placeholder={placeholder}
						type={type}
						className={clsx(
							'h-full w-full px-3 py-3 text-sm rounded-md shadow-[inset_0_2px_2px_0_rgb(0_0_0_/_0.15)]',
							'focus:outline-none focus:ring-4 focus:ring-indigo-blue-500 focus:ring-offset-0 focus:ring-offset-white'
						)}
						onChange={onChange}
						value={value}
						aria-invalid={isHintPresent && hintVariant === 'error'}
						aria-describedby={
							isHintPresent && hintVariant !== 'error'
								? `${name}-hint`
								: undefined
						}
						aria-errormessage={invalidId}
						{...inputProps}
						{...restProps}
					/>

					{isMoney && (
						<span className="absolute right-0 top-0 text-[#b3b3b3] text-sm font-normal ml-2 pr-3 py-3">
							€
						</span>
					)}
				</div>

				{isHintPresent ? (
					<span
						id={invalidId || `${name}-hint`}
						className={clsx(
							'grid text-sm mt-2 overflow-hidden w-full',
							colorVariants[hintVariant],
							{
								'grid-cols-[32px_1fr]': hintVariant === 'error',
							}
						)}
					>
						{hintVariant === 'error' && (
							<WarningIcon
								className={`${colorVariants[hintVariant]} inline h-[24px] w-[24px] mx-1`}
								color="currentColor"
								aria-hidden="true"
							/>
						)}
						<span className="block min-h-[24px] leading-6">{hint}</span>
					</span>
				) : null}
			</div>
		)
	}
)
