import { useEffect } from 'react'

export function useDisableBackButton() {
	useEffect(() => {
		history.pushState(null, document.title, location.href)

		window.addEventListener('popstate', () => {
			history.pushState(null, document.title, location.href)
		})
	}, [])
}
