
let isGemachtigde = false

export function setGemachtigdeText(gemachtigd: boolean) {
	isGemachtigde = gemachtigd
}

// Helper function voor gemachtigide text transformation
export function _g(gemachtigdeText: string, nietGemachtigdeText: string) {
	return isGemachtigde ? gemachtigdeText : nietGemachtigdeText
}