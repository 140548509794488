import React from 'react'

type Props = React.SVGProps<SVGSVGElement> & { color?: string }

export function LightbulbIcon({ color, ...props }: Props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			fill="none"
			{...props}
		>
			<path
				fill={color ?? '#000'}
				fillRule="evenodd"
				d="M12 2a1 1 0 0 1 1 1v1a1 1 0 1 1-2 0V3a1 1 0 0 1 1-1ZM4.893 4.893a1 1 0 0 1 1.414 0l.7.7a1 1 0 0 1-1.414 1.414l-.7-.7a1 1 0 0 1 0-1.414Zm14.214 0a1 1 0 0 1 0 1.414l-.7.7a1 1 0 1 1-1.414-1.414l.7-.7a1 1 0 0 1 1.414 0ZM12 8a4 4 0 0 0-2.4 7.2c.037.027.071.057.104.09.219.216.414.455.584.71h3.424a4.5 4.5 0 0 1 .585-.71 1 1 0 0 1 .103-.09A4 4 0 0 0 12 8Zm3.164 9.504a.994.994 0 0 0 .076-.161 2.5 2.5 0 0 1 .422-.59 6 6 0 1 0-7.324 0 2.5 2.5 0 0 1 .422.59c.02.056.046.11.077.162a2.496 2.496 0 0 1 .174 1.349A1 1 0 0 0 9 19a3 3 0 1 0 6 0 .998.998 0 0 0-.01-.146 2.5 2.5 0 0 1 .174-1.35ZM12.989 18H11.01c.038.35.035.705-.01 1.057a1 1 0 0 0 1.997 0 4.506 4.506 0 0 1-.01-1.057ZM2 12a1 1 0 0 1 1-1h1a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1Zm17 0a1 1 0 0 1 1-1h1a1 1 0 1 1 0 2h-1a1 1 0 0 1-1-1Z"
				clipRule="evenodd"
			/>
		</svg>
	)
}
