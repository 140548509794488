import { useContext, useMemo } from 'react'
import { AppContext } from '../../context/app-context'
import { PersonState } from '../../types/api'
import {
	Button,
	CompletedText,
	MarkdownText,
	MultilineButton,
	Title,
} from '../../components'
import { t } from '../../utils/cmsTranslations'
import { ChevronRightIcon, LockClosedIcon } from '../../assets/icons'

type Props = {
	hasFailed: boolean
	selectedPersonId: string
	onNextClick: () => void
	onPersonClick: (personId: string) => void
}

export const AllPersonsListing = ({
	hasFailed,
	selectedPersonId,
	onNextClick,
	onPersonClick,
}: Props) => {
	const { householdData } = useContext(AppContext)

	const isAllPersonsCompleted = useMemo(
		() => householdData.personen.every((p) => p.state === PersonState.SUCCESS),
		[householdData.personen]
	)

	return (
		<>
			{isAllPersonsCompleted && (
				<Title classNameOverride="mb-4">{t('income.collected.title')}</Title>
			)}
			{!isAllPersonsCompleted && (
				<>
					<Title classNameOverride="mb-2">
						{t('income.personSelection.title')}
					</Title>
					<MarkdownText
						className="mb-6"
						text={t('income.personSelection.text')}
					/>
				</>
			)}

			<div className="w-full">
				{householdData.personen.map((p) => (
					<div
						key={p.id}
						className="w-full mb-[17px]"
						role="region"
						aria-live="polite"
						aria-busy="false"
					>
						{p.state === PersonState.SUCCESS ? (
							<CompletedText
								line1={p.volledigeNaam}
								line2={t('generic.resourceIdentifierPerson.completeLabel')}
								classNameOverride={'mb-4'}
							/>
						) : (
							<MultilineButton
								line1={p.volledigeNaam}
								line2={t('generic.resourceIdentifierPerson.getLabel')}
								accessibilityLabel={t(
									'generic.resourceIdentifierPerson.accessibilityLabel'
								)}
								icon={<LockClosedIcon color="white" />}
								key={p.id}
								classNameOverride={`mb-4 ${
									hasFailed && selectedPersonId == p.id
										? 'border border-issue'
										: ''
								}`}
								onClick={() => onPersonClick(p.id)}
							/>
						)}
					</div>
				))}
			</div>

			{isAllPersonsCompleted && (
				<Button
					title={t('income.collected.buttonNext.title')}
					icon={<ChevronRightIcon />}
					onClick={onNextClick}
				/>
			)}
		</>
	)
}
