import React, { forwardRef } from 'react'
import clsx from 'clsx'

type Props = {
	title: string
	icon?: React.ReactNode
	classNameOverride?: string
	disabled?: boolean
	onClick?: () => void
	variant?: 'small' | 'default'
	id?: string
	buttonProps?: any
}

const Button = forwardRef<HTMLButtonElement, Props>(
	(
		{
			title,
			icon,
			classNameOverride,
			disabled,
			onClick,
			variant = 'default',
			id,
			buttonProps,
			...rest
		},
		ref
	) => (
		<button
			ref={ref}
			id={id}
			disabled={disabled}
			onClick={onClick}
			className={clsx(
				'bg-indigo-blue-800 rounded-[0.75rem] px-5 py-3 text-white font-semibold drop-shadow-lg flex items-center justify-center',
				'disabled:bg-gray-300 disabled:text-gray-500',
				'hover:bg-indigo-blue-900',
				'active:bg-indigo-blue-950 active:shadow-[inset_0_0_0_2px_rgba(115,86,153)]',
				'focus:outline-none focus:ring-4 focus:ring-indigo-blue-950 focus:ring-offset-2 focus:ring-offset-white',
				{
					'text-[18px] leading-8': variant === 'default',
					'text-[16px] leading-6': variant === 'small',
				},
				classNameOverride
			)}
			{...buttonProps}
			{...rest}
		>
			{title}

			{icon ? (
				<div className="ml-2" aria-hidden="true">
					{icon}
				</div>
			) : null}
		</button>
	)
)

Button.displayName = 'Button'

export { Button }
