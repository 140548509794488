import React from 'react'

type Props = React.SVGProps<SVGSVGElement> & { color?: string }

export function StarEmptyIcon({ color, ...props }: Props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="32"
			height="32"
			fill="none"
			{...props}
		>
			<path
				fill={color ?? '#B73E17'}
				fillRule="evenodd"
				d="M15.99 1.336c.508 0 .972.288 1.196.743l3.805 7.709 8.506 1.232a1.333 1.333 0 0 1 .738 2.276l-6.164 6 1.454 8.472a1.333 1.333 0 0 1-1.936 1.405l-7.59-4-7.608 4a1.333 1.333 0 0 1-1.935-1.405l1.454-8.473-6.164-6a1.333 1.333 0 0 1 .739-2.274l8.506-1.233 3.804-7.709a1.333 1.333 0 0 1 1.196-.743Zm0 4.346-2.918 5.915c-.195.394-.57.666-1.005.73l-6.523.945 4.729 4.602c.314.306.458.748.384 1.18L9.54 25.557l5.838-3.07a1.333 1.333 0 0 1 1.243.001l5.817 3.066-1.115-6.498c-.074-.433.07-.875.385-1.181l4.728-4.602-6.523-.946a1.333 1.333 0 0 1-1.004-.73l-2.92-5.914Z"
				clipRule="evenodd"
			/>
		</svg>
	)
}
