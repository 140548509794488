import { useLocation } from 'react-router'
import { WarningIcon } from '../../assets/icons'
import { TextLight, Title } from '../../components'
import { AvailableStep, useSetActiveStep } from '../../utils/stepUtil'

export default function NotAvailablePage() {
	useSetActiveStep(AvailableStep.none)

	const { state } = useLocation()
	const { title, msg } = state as { title: string; msg: string }

	return (
		<div className="flex w-full h-full items-center justify-center">
			<div className="flex flex-col items-center w-[70%] h-full">
				<div className="flex flex-row items-center mb-4 self-start">
					<WarningIcon />
					<Title classNameOverride="ml-2">{title}</Title>
				</div>
				<TextLight>{msg}</TextLight>
			</div>
		</div>
	)
}
