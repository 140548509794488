import { PlusIcon } from '../../assets/icons'
import { SecondaryButton } from '../../components'
import { tContext } from '../../utils/cmsTranslations'

const t = tContext('energy.input')

export default function AddSecondaryFormInfoText({
	onClick,
}: {
	onClick: () => void
}) {
	return (
		<SecondaryButton
			title={t('buttonSecondSupplier.title')}
			onClick={onClick}
			variant="small"
			icon={<PlusIcon color="currentColor" />}
		/>
	)
}
