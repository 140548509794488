import { Aqopi } from '@iwize-aqopi/aqopi-types'

export enum EnergyType {
	ELEKTRA_EN_GAS_OF_WARMTE = 'ELEKTRA_EN_GAS_OF_WARMTE',
	ELEKTRA = 'ELEKTRA',
	GAS_OF_WARMTE = 'GAS_OF_WARMTE',
}

export enum PersonState {
	SUCCESS = 'SUCCESS',
	FAILED = 'FAILED',
	TODO = 'TODO',
}

export type TypeRelatie =
	| 'zelf'
	| 'partner'
	| 'toeslagpartner'
	| 'huisgenoot'
	| 'kind'

export type Person = {
	id: string // randomly created uuid upon creation of persoon
	initialen: string // Af te leiden door uit de volledige naam alle substrings van lengte 1 te plukken, beginnend links, stoppend bij eerste substring van lengte > 1. Het kan zijn dat iemand geen voorletters heeft.
	achternaam: string // De volledige naam exclusief de initialen.
	volledigeNaam: string // De volledige naam overgenomen uit het parseResult. Beschikbaar houden in geval van logging, tracing, en evt wijzigende requirements
	laatsteDrieCijfersBsn: string // Overnemen uit parseResult
	isInitiator: boolean // Is dit de persoon die is ingelogd bij toeslagen.
	isContracthouder: boolean // default false
	state: PersonState // UI state
	isChecked: boolean
	typeRelatie: TypeRelatie
}

export type HouseholdData = {
	postcode: string // Overnemen uit parseResult
	huisnummerMetToevoeging: string // Overnemen uit parseResult
	personen: Person[] // Overnemen uit parseResult,
	backupPersonen?: Person[] // na selectie van personen, originele personen blijven in personen
	mijnHuishoudenParseResult: any
}

export interface IncomeData {
	persoonId: string // reference to persoon from household.
	manuallyProvidedIncome?: number
	uwvData?: UwvData
	viaData?: ViaData
	//ibriData?: IbriData
	klantbeeldData?: MbdKlantbeeldData
	crossCheckResult?: SignedData<CrossCheckResult>
}

export interface UwvData {
	periodes: UwvPeriode[] // alle UWV periodes tot een jaar terug voor huidige werkgevers
	geboortedatum: string
	blobPayloadId: string
	blobPayloadHash: string
	blob?: Aqopi.Blob
}

export interface ViaData {
	sivInkomens: SivInkomen[]
	geboortedatum: string
}

/**
 * @deprecated
 */
export interface IbriData {
	jaar: number
	bedrag: number
	naam: string
	laatsteDrieCijfersBsn: string
	blobPayloadHash: string
}

export interface MbdKlantbeeldData {
	jaar: number
	bedrag?: number
	blobUnicityHash: string
}

export interface UwvPeriode {
	startdatum: string
	einddatum: string
	bedrag: number
	aantalUren: number
}

export type SignedData<T> = {
	data: T
	signature: Signature
}

export interface Signature {
	signature: string
	version: string
}

export interface SivInkomen {
	sivCode: number
	bedrag: number
	jaar: number
}

export interface CrossCheckResult {
	parseResults: any[]
	validationStatus: ValidationStatus
	CrossCheckStatus?: ValidationStatus
}

export interface IncomeUI {
	currentIncomes?: (number | Date)[][]
	grossIncome?: number | string
	grossIncomeYear?: number | string
	sivIncomes?: number[][]
	birthDate?: Date
}

export enum ApplicationStatus {
	GOEDGEKEURD = 'GOEDGEKEURD',
	AFGEKEURD = 'AFGEKEURD',
	ONBEKEND = 'ONBEKEND',
	MIXED = 'MIXED',
	FOUT = 'FOUT',
}

export interface ApplicationCheckResult {
	applicationId: string
	status: ApplicationStatus
	toetsingId: string
	toelichtingBijToetsingsresultaat?: string
	berekendBrutoHuishoudinkomen?: number
	berekendNormbedrag?: number
	berekendVoorschot?: number
	toegekendBedrag: number
	gemachtigd: boolean
}

export type SubmitData = ApplicationCheckResult & { email: string }

export type DataObject = Partial<{
	householdData: HouseholdData
	incomeData: IncomeData[]
	contractData: ContractData[]
	applicationCheckResult: ApplicationCheckResult
	applicationSent: boolean
}>

export interface ContractData {
	energiemaatschappij: EnergyProviderData
	klantnummer: number | string
	contracthouderId: string
	type?: string
	huidigVoorschot: number
	adviesVoorschot?: number
	betalingsregelingMaandbedrag?: number
}

export interface EnergyProviderData {
	code: string
	naam: string
}

export interface PeriodEnergyData {
	period: Period
	data: EnergyData[]
}

export interface Period {
	val: string
	label: string
}

export type EnergyData = {
	energyType: EnergyType
	clientNumber: string
	supplier: string
	advanceAdvice?: string | number
	actualAdvance: string | number
	paymentAgreement: string | number
	selectedContractHolder: Person | undefined
	isPrimary: boolean
}

export type EnergyProvidersResponse = {
	favorites: string[]
	suppliers: Record<string, string>
}

type ValidationStatus = 'invalid' | 'undecided' | 'valid' | 'failed'

export type ApiNavigationStep =
	| 'Registratie - Overzicht'
	| 'Registratie - Uitleg'
	| 'Registratie - Email'
	| 'Registratie - Email verificatie'
	| 'Registratie - Telefoonnummer'
	| 'Registratie - Telefoonnummer verificatie'
	| 'Registratie - Overeenkomst'
	| 'Huishouden - Overzicht'
	| 'Huishouden - Uitleg'
	| 'Huishouden - Toeslagen ophalen'
	| 'Huishouden - Personen selectie'
	| 'Huishouden - Verzamelde gegevens'
	| 'Inkomen - Overzicht'
	| 'Inkomen - Uitleg'
	| 'Inkomen - Inkomens ophalen'
	| 'Inkomen - Overeenkomst'
	| 'Inkomen - Controleer inkomen'
	| 'Inkomen - Persoon overzicht'
	| 'Inkomen - Inkomen verzameld'
	| 'Energie - Overzicht'
	| 'Energie - Uitleg'
	| 'Energie - Formulier'
	| 'Versturen - Overzicht'
	| 'Versturen - Controle'
	| 'Versturen - Resultaat'
