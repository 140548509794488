import React from 'react'

type Props = React.SVGProps<SVGSVGElement> & { color?: string }

export function ThumbsDownIcon({ color, ...props }: Props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			fill="none"
			{...props}
		>
			<path
				fill={color ?? '#36369D'}
				fillRule="evenodd"
				d="M16.963 3c.76-.05 1.449.274 1.967.792.52.52.867 1.228 1.044 1.98l.007.032 1 5c.012.064.019.13.019.196a3 3 0 0 1-3 3h-2v4a3 3 0 0 1-6 0v-1a3 3 0 0 0-3-3H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h2a2 2 0 0 1 1.6.8A4 4 0 0 1 10 3h6.963ZM6 5H4v7h2V5Zm2 7.101V7a2 2 0 0 1 2-2h7a1 1 0 0 0 .085-.004c.087-.007.238.018.43.21.2.2.396.54.508 1.007l.974 4.87A1 1 0 0 1 18 12h-3a1 1 0 0 0-1 1v5a1 1 0 1 1-2 0v-1a5 5 0 0 0-4-4.899Z"
				clipRule="evenodd"
			/>
		</svg>
	)
}
