import React from 'react'

type Props = React.SVGProps<SVGSVGElement> & { color?: string }

export const ActivityIndicatorIcon: React.FC<Props> = ({ color, ...props }) => (
	<svg
		width="62"
		height="62"
		viewBox="0 0 62 62"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M58.0384 15.8316C56.8107 13.647 54.0463 12.8724 51.8592 14.1001C49.6745 15.3278 48.9024 18.0922 50.1276 20.2768C51.9553 23.5296 52.9223 27.2381 52.9223 31C52.9223 43.09 43.085 52.9273 30.995 52.9273C18.9102 52.9273 9.07286 43.09 9.07286 31C9.07286 18.91 18.9102 9.07267 31.0002 9.07267C33.6608 9.07267 36.2605 9.54351 38.7262 10.47C41.0754 11.3535 43.6878 10.1662 44.5688 7.82213C45.4522 5.47801 44.2675 2.86055 41.9209 1.97959C38.4301 0.668331 34.7541 0 31 0C13.9077 0 0 13.9077 0 31C0 48.0923 13.9052 62 31 62C48.0923 62 62 48.0948 62 31C62 25.684 60.628 20.4388 58.0383 15.8318L58.0384 15.8316Z"
			fill={color ?? '#EC6E26'}
		/>
	</svg>
)
