import { ChevronRightIcon } from '../../assets/icons'
import { Button, MarkdownText, SecondaryButton } from '..'
import { TableRow } from '../info-table/TableRow'
import { tContext } from '../../utils/cmsTranslations'

const t = tContext('agreement')

export default function AgreementContent({
	onAccept,
	onCancel,
}: {
	onAccept: () => void
	onCancel: () => void
}) {
	return (
		<>
			<MarkdownText text={t('textAgreementShortWeb')} />

			<div className="mb-8">
				<TableRow title={t('agreementFullLabel')} showBorder showTopBorder>
					<MarkdownText className="py-6" text={t('text')} />
				</TableRow>

				<TableRow title={t('privacyStatementLabel')} showBorder>
					<MarkdownText className="py-6" text={t('textPrivacyStatement')} />
				</TableRow>
			</div>

			<div className="flex flex-row">
				<Button
					title={t('buttonConfirm.title')}
					icon={<ChevronRightIcon />}
					onClick={onAccept}
					classNameOverride="mr-4"
				/>

				<SecondaryButton title={t('buttonCancel.title')} onClick={onCancel} />
			</div>
		</>
	)
}
