import React from 'react'

type Props = React.SVGProps<SVGSVGElement> & {
	color?: string
	inverseColor?: boolean
}

const CIRCLE_COLOR = '#ffffff'
const CHECK_COLOR = '#166534'

export const CheckRoundedIcon: React.FC<Props> = ({
	color,
	inverseColor = false,
	...props
}) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="48"
		height="48"
		fill="none"
		{...props}
	>
		<circle
			cx="23"
			cy="24"
			r="23"
			fill={inverseColor ? color ?? CHECK_COLOR : '#ffffff'}
		/>
		<path
			stroke="none"
			fill={inverseColor ? CIRCLE_COLOR : color ?? '#166534'}
			d="M33.674 17.33c.44.441.44 1.154 0 1.59L21.3 31.3a1.12 1.12 0 0 1-1.589 0l-6.38-6.376a1.124 1.124 0 0 1 1.589-1.589l5.579 5.578L32.08 17.331a1.12 1.12 0 0 1 1.59 0h.004Z"
		/>
	</svg>
)
