export const numbersOnlyRegex = /^[0-9]*$/

// mobile phone numbers are allowed in multiple formats
const mobilePhoneNumberRegex = /^(06|316|0031|\+31)[0-9]*$/

// 01, 02, 03, 04, 05, 07 are all 'regionale nummers' or 'vaste nummers' (regional numbers)
// we allow these numbers to be entered without the country code
const dutchRegionalPhoneNumberRegexp = /^(01|02|03|04|05|07)\d{8}$/

// we also allow business numbers to be entered without the country code
const dutchBusinessRegionalPhoneNumberRegexp = /^(088|085)\d{7}$/

export const isDutchRegionalNumber = (phoneNumber: string) => {
	return (
		dutchRegionalPhoneNumberRegexp.test(phoneNumber) ||
		dutchBusinessRegionalPhoneNumberRegexp.test(phoneNumber)
	)
}

export const isConsideredDutchNumber = (phoneNumber: string) => {
	return (
		mobilePhoneNumberRegex.test(phoneNumber) ||
		isDutchRegionalNumber(phoneNumber)
	)
}
