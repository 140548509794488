import React from 'react'

type Props = React.SVGProps<SVGSVGElement> & { color?: string }

export function MessageIcon({ color, ...props }: Props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="25"
			fill="none"
			{...props}
		>
			<path
				fill={color ?? '#323439'}
				fillRule="evenodd"
				d="M6.479 7.042c3.158-2.24 7.716-2.05 10.617.425L6.479 7.042Zm10.617.425c2.854 2.435 3.213 6.305.906 9.104-2.352 2.854-6.792 3.764-10.365 2.03a1 1 0 0 0-.645-.079l-2.99.636.747-2.242a1 1 0 0 0-.12-.876c-2-2.956-1.264-6.789 1.85-8.998M5.32 5.41c3.894-2.761 9.464-2.542 13.073.535 3.656 3.12 4.177 8.227 1.152 11.898-2.908 3.529-8.175 4.613-12.464 2.705l-4.374.93a1 1 0 0 1-1.157-1.294l1.15-3.45c-2.299-3.85-1.177-8.63 2.62-11.323Z"
				clipRule="evenodd"
			/>
		</svg>
	)
}
