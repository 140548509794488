import React from 'react'

type Props = React.SVGProps<SVGSVGElement> & { color?: string }

export const LicenseIcon: React.FC<Props> = ({ color, ...props }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		fill="none"
		{...props}
	>
		<path
			fill={color ?? '#000'}
			fillRule="evenodd"
			d="M20 4a1 1 0 0 0-1 1v14a3 3 0 0 1-3 3H7a4 4 0 0 1-4-4v-1a1 1 0 0 1 1-1h1V6a4 4 0 0 1 4-4h11a.999.999 0 1 1 0 2Zm-2.828 0H9a2 2 0 0 0-2 2v10h7a1 1 0 0 1 1 1v2a1 1 0 0 0 2 0V5c0-.342.059-.68.172-1Zm-4 16A3 3 0 0 1 13 19v-1H5a2 2 0 0 0 2 2h6.172Z"
			clipRule="evenodd"
		/>
		<path
			fill={color ?? '#000'}
			fillRule="evenodd"
			d="M9 7a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1ZM9 11a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1Z"
			clipRule="evenodd"
		/>
	</svg>
)
