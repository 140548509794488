import React from 'react'

type Props = React.SVGProps<SVGSVGElement> & { color?: string }

export const WarningIcon: React.FC<Props> = ({ color, ...props }) => (
	<svg
		width="52"
		height="47"
		viewBox="0 0 52 47"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M7.65765 46.3665H43.3862C49.2662 46.3665 52.9633 39.9265 50.0491 34.8865L32.1291 3.86366C29.2175 -1.28789 21.8262 -1.28789 18.912 3.86366L0.991969 34.8865C-1.86274 39.9834 1.77726 46.3665 7.65709 46.3665H7.65765ZM25.5777 39.4802C24.0092 39.4802 22.7208 38.1918 22.7208 36.6233C22.7208 35.0549 24.0092 33.7664 25.5777 33.7664C27.1461 33.7664 28.4345 35.0549 28.3776 36.678C28.4345 38.1917 27.0892 39.4802 25.5777 39.4802V39.4802ZM24.8492 13.7202C26.1923 13.3834 27.5923 14.0571 28.1523 15.3433C28.3207 15.7918 28.4323 16.2402 28.4323 16.7433C28.3754 18.1433 28.2639 19.6002 28.1523 21.0002C28.0407 23.1833 27.8723 25.4233 27.7607 27.6088C27.7039 28.3372 27.7039 28.9519 27.7039 29.6803C27.647 30.8572 26.7523 31.7519 25.5754 31.7519C24.3985 31.7519 23.5039 30.9119 23.447 29.735C23.2785 26.3181 23.0554 22.9036 22.887 19.4864C22.8301 18.5895 22.7754 17.6949 22.7185 16.7433C22.666 15.3433 23.506 14.1118 24.8492 13.7202H24.8492Z"
			fill={color ?? '#FE0000'}
		/>
	</svg>
)
