import clsx from 'clsx'
import { LockClosedIcon } from '../../assets/icons'
import { CompletedText, MultilineButton } from '../../components'
import { tContext } from '../../utils/cmsTranslations'
import { Config } from '../../types/aqopi'

const tGeneric = tContext('generic')

type IncomeButtonProps = {
	isAccepted: boolean
	isSuccess: boolean
	disable?: boolean
	config: Config
	onFetchData: () => void
}

export const IncomeButton = ({
	config,
	isAccepted,
	isSuccess,
	disable,
	onFetchData,
}: IncomeButtonProps) => {
	const resourceIdentifier = config.id.includes('uwv')
		? 'resourceIdentifierUwv'
		: 'resourceIdentifierBelastingdienst'

	const isDisabled = !isSuccess && (!isAccepted || disable)

	return (
		<div
			className="flex flex-col mb-4 w-full"
			role="region"
			aria-live="polite"
			aria-busy={isDisabled ? 'true' : 'false'}
		>
			{isSuccess ? (
				<CompletedText
					line1={tGeneric(`${resourceIdentifier}.resource`)}
					line2={tGeneric(`${resourceIdentifier}.completeLabel`)}
					classNameOverride={'mr-5'}
				/>
			) : (
				<MultilineButton
					line1={tGeneric(`${resourceIdentifier}.resource`)}
					line2={tGeneric(`${resourceIdentifier}.getLabel`)}
					accessibilityLabel={tGeneric(
						`${resourceIdentifier}.accessibilityLabel`
					)}
					icon={<LockClosedIcon color="white" aria-hidden="true" />}
					classNameOverride={clsx(
						config.failureType && 'border border-issue',
						!isAccepted && 'opacity-50 cursor-not-allowed'
					)}
					onClick={onFetchData}
					disabled={isDisabled}
				/>
			)}
		</div>
	)
}
