import {
	ApplicationCheckResult,
	ApplicationStatus,
	CrossCheckResult,
	DataObject,
	IncomeData,
	IncomeUI,
	SignedData,
	UwvPeriode,
} from '../types/api'
import { formatDutchDate } from './formatDate'
import { http } from './http'

const HEADERS = {
	'Content-Type': 'application/json',
}

const parseData = async (blob: any, ticket: string) => {
	try {
		console.log('Parsing blob...')
		const parse = await http.fetch(
			`${process.env.REACT_APP_VALIDATION_SERVICE}/parse`,
			{
				method: 'POST',
				headers: {
					...HEADERS,
					ticket,
				},
				body: JSON.stringify(blob),
			}
		)

		if (!parse.ok) {
			throw `Parsing blob failed, with status ${parse.status} ${parse.statusText}`
		}

		return parse.json()
	} catch (error: any) {
		console.log(error)
		return null
	}
}

const crossCheckData = async (
	parseResults: any[],
	ticket: string
): Promise<SignedData<CrossCheckResult>> => {
	try {
		console.log('Cross checking parseResults...')
		const check = await http.fetch(
			`${process.env.REACT_APP_VALIDATION_SERVICE}/id-cross-check`,
			{
				method: 'POST',
				headers: {
					...HEADERS,
					ticket,
				},
				body: JSON.stringify({ data: parseResults }),
			}
		)

		const result = await check.json()

		if (!check.ok) {
			console.log(result)
			throw `Cross check failed, with status ${check.status} ${check.statusText}`
		}

		return result
	} catch (error: any) {
		console.log(error)
		return {
			data: {
				parseResults: [],
				validationStatus: 'failed',
			},
			signature: {
				signature: '',
				version: '',
			},
		}
	}
}

const formatProcessableIncomeData = (
	parseResults: any[]
): {
	//ibriParseResult: any
	klantbeeldParseResult: any
	uwvParseResult: any
} => {
	const processableParseResultsMap = {
		//ibriParseResult: 'mbd-verzamelinkomens',
		klantbeeldParseResult: 'mbd-klantbeeld',
		uwvParseResult: 'uwv-verzekeringsbericht',
	}
	const processableParseResults: any = {
		//ibriParseResult: null,
		klantbeeldParseResult: null,
		uwvParseResult: null,
	}

	for (const property in processableParseResultsMap) {
		processableParseResults[property] = parseResults?.find(
			(p) =>
				p.queryResult.metadata.parserId ===
				(processableParseResultsMap as any)[property]
		)
	}

	return processableParseResults
}

async function processIncome(
	personId: string,
	ticket: string,
	parseResults: {
		//ibriParseResult: any
		klantbeeldParseResult: any
		uwvParseResult: any
	}
): Promise<IncomeData | null> {
	try {
		console.log('Processing income data')
		const response = await http.fetch(
			`${process.env.REACT_APP_ENERGY_SERVICE}/process/income`,
			{
				method: 'POST',
				headers: { ...HEADERS, ticket },
				body: JSON.stringify({
					personId,
					...parseResults,
				}),
			}
		)

		const json = await response.json()

		if (!response.ok) {
			console.log(json)
			throw `Processing income data failed, with status ${response.status}  ${json.error.message}`
		}

		return json
	} catch (error: any) {
		console.warn(error)
		return null
	}
}

const hasIncomeForLastMonths = (uwvPeriodes: UwvPeriode[]): boolean => {
	const numMonths = 6
	const currentDate = new Date()
	const date = new Date(
		currentDate.setMonth(currentDate.getMonth() - numMonths)
	)

	return uwvPeriodes
		.filter((period) => {
			const endDate = formatDutchDate(period.einddatum as string)
			return endDate > date
		})
		.some((period) => period.bedrag)
}

const hasUwvData = (incomeData: IncomeData): boolean => {
	return (
		!!incomeData?.uwvData?.periodes?.length &&
		hasIncomeForLastMonths(incomeData.uwvData.periodes)
	)
}

const incomeDataToViewModel = (incomeData: IncomeData): IncomeUI => {
	const viaParseResults = incomeData.crossCheckResult?.data?.parseResults?.find(
		(parseData) =>
			parseData.queryResult.metadata.parserId ===
			'mbd-vooraf-ingevulde-gegevens'
	)

	let sivIncomes: any[] = []
	if (viaParseResults) {
		sivIncomes = [
			...viaParseResults.queryResult.data.inkomenLoondienst,
			...viaParseResults.queryResult.data.inkomenUitkering,
		]
	}

	return {
		currentIncomes: incomeData?.uwvData?.periodes.map((p) => [
			formatDutchDate(p.startdatum),
			formatDutchDate(p.einddatum),
			p.bedrag,
		]),
		grossIncome: incomeData?.klantbeeldData?.bedrag ?? '',
		grossIncomeYear: incomeData?.klantbeeldData?.jaar ?? '',
		sivIncomes: sivIncomes.map((i) => [i.sivCode, i.bedrag || i.brutoloon]),
		birthDate: incomeData?.uwvData?.geboortedatum
			? formatDutchDate(incomeData.uwvData.geboortedatum)
			: undefined,
	}
}

async function checkApplication(
	ticket: string,
	email: string,
	phoneNumber: string,
	gemachtigd: boolean,
	data: DataObject,
	gemachtigdeExtraFields?: {
		gemachtigdeEmailadres: string
		gemachtigdeNaam: string
		gemachtigdeTelefoonnummer: string
	}
): Promise<ApplicationCheckResult> {
	console.log('Checking application...')

	const extraFields = gemachtigd ? gemachtigdeExtraFields : undefined

	const body = JSON.stringify({
		mainApplicantId:
			data.householdData?.personen?.find((p) => p.isInitiator)?.id ||
			data.householdData?.personen[0].id,
		email,
		phoneNumber,
		fromNativeApp: false,
		mijnHuishoudenParseResult: data?.householdData?.mijnHuishoudenParseResult,
		contracts: data?.contractData,
		gemachtigd,
		...extraFields,
		memberData: data.incomeData?.map((income) => ({
			personId: income.persoonId,
			manuallyProvidedIncome: income.manuallyProvidedIncome,
			crossCheckResult: {
				...income.crossCheckResult,
				data: {
					parseResults: income.crossCheckResult?.data.parseResults,
					validationStatus: income.crossCheckResult?.data?.validationStatus,
				},
			},
		})),
	})

	try {
		const response = await http.fetch(
			`${process.env.REACT_APP_ENERGY_SERVICE}/application/check`,
			{
				method: 'POST',
				headers: { ...HEADERS, ticket },
				body,
			}
		)

		const json = await response.json()

		if (!response.ok) {
			throw `Checking application failed, with status ${response.status} ${json.error.message}`
		}
		return json
	} catch (error: any) {
		console.warn(error)
		return {
			applicationId: '',
			toetsingId: '',
			status: ApplicationStatus.FOUT,
			toegekendBedrag: 0,
			gemachtigd: false,
		}
	}
}

export {
	incomeDataToViewModel,
	parseData,
	crossCheckData,
	formatProcessableIncomeData,
	processIncome,
	checkApplication,
	hasUwvData,
}
