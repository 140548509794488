import { UserIcon } from '../../assets/icons'

export const PersonIndicator = ({ name }: { name: string }) => (
	<div className="flex gap-3 flex-row text-gray-900 items-center justify-start py-3 mb-2">
		<div className="flex items-center shrink-0 justify-center rounded-full w-[48px] h-[48px] bg-gray-100">
			<UserIcon color="currentColor" />
		</div>

		<div className="flex flex-col items-start justify-center">
			<p className="text-base font-semibold text-text">{name}</p>
		</div>
	</div>
)
