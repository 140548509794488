import React from 'react'
import clsx from 'clsx'
import {
	CheckRoundedIcon,
	CrossRoundedIcon,
	WarningRoundedIcon,
} from '../../assets/icons'
import { MarkdownText } from '../'

interface Props {
	classNameOverride?: string
	title?: string
	text?: string
	noticeType?: 'success' | 'info' | 'warning' | 'error'
}

const getIcon = (noticeType: Props['noticeType']) => {
	switch (noticeType) {
		case 'success':
			return (
				<CheckRoundedIcon
					color="currentColor"
					className="text-green-700"
					inverseColor={true}
				/>
			)
		case 'error':
			return <CrossRoundedIcon className="w-[48px] h-[48px]" />
		case 'warning':
			return <WarningRoundedIcon className="w-[48px] h-[48px]" />
		default:
			return null
	}
}

export const Notice: React.FC<Props> = ({
	classNameOverride = '',
	title,
	noticeType = 'warning',
	text,
}) => {
	const hasTitle = typeof title === 'string' && title.length > 0
	const hasText = typeof text === 'string' && text.length > 0

	return (
		<div
			aria-live="assertive"
			data-testid={`notice-${noticeType}`}
			className={clsx(
				'w-full rounded-[1rem] px-6 pt-6',
				hasText ? 'pb-2' : 'pb-6',
				noticeType === 'error' && 'bg-red-100',
				noticeType === 'success' && 'bg-green-100',
				noticeType === 'info' && 'bg-indigo-blue-100',
				noticeType === 'warning' && 'bg-orange-100',
				classNameOverride
			)}
		>
			<div className="grid grid-cols-[64px_1fr]">
				<div aria-hidden="true">{getIcon(noticeType)}</div>
				<div className="self-center">
					{hasTitle && (
						<p
							className={clsx(
								'font-semibold text-text text-lg',
								hasText && 'mb-1'
							)}
						>
							{title}
						</p>
					)}

					{hasText && (
						<MarkdownText
							text={text}
							className={!hasTitle ? 'mt-3 mb-6' : undefined}
						/>
					)}
				</div>
			</div>
		</div>
	)
}
