import React from 'react'

type Props = React.SVGProps<SVGSVGElement> & { color?: string }

export function DevicesIcon({ color, ...props }: Props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			fill="none"
			{...props}
		>
			<path
				fill={color ?? '#000'}
				fillRule="evenodd"
				d="M2.586 3.586A2 2 0 0 1 4 3h13a2 2 0 0 1 2 2v2h1a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-6a2 2 0 0 1-2-2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 .586-1.414ZM14 19h6V9h-1a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1h-1v10Zm3-12h-3a2 2 0 0 0-2 2v8H4V5h13v2Z"
				clipRule="evenodd"
			/>
		</svg>
	)
}
