import { t } from '../../utils/cmsTranslations'
import { _g } from '../../utils/text-transform'
import { MarkdownText } from '../MarkdownText/MarkdownText'

export type TextId =
	| 'review'
	| 'home'
	| 'people'
	| 'contractHolder'
	| 'incomeSources'
	| 'inputCustomerNumber'
	| 'inputContractHolder'
	| 'inputAdviceAdvance'
	| 'inputActualAdvance'

const getTranslatedHelp = (textPrefix: string) => {
	return {
		title: t(textPrefix + '.title'),
		content: <MarkdownText text={t(textPrefix + '.text')} />,
	}
}

const INPUT_ACTUAL_ADVANCE = getTranslatedHelp(
	'energy.input.inputActualAdvance.help'
)

const INPUT_ADVICE_ADVANCE = getTranslatedHelp(
	'energy.input.inputAdviceAdvance.help'
)

const INPUT_CONTRACT_HOLDER = getTranslatedHelp(
	'energy.input.inputContractHolder.help'
)

const INPUT_CUSTOMER_NUMBER = getTranslatedHelp(
	'energy.input.inputCustomerNumber.help'
)

const INCOME_SOURCES = getTranslatedHelp('income.check.sectionIncomeSources')

const REVIEW = {
	title: 'Haal gegevens inkomen en werk op',
	content: null,
}

const HOME = {
	title: 'Installeer extensie',
	content: (
		<div>
			<p className="mb-2">
				Koppel de aanvraag van het Noodfonds Energie veilig aan het UWV en de
				Belastingdienst.
			</p>
			<p className="mb-2">
				{`Gebruik deze extensie om ${_g(
					'de',
					'jouw'
				)} inkomensgegevens veilig op te halen bij de Belastingdienst en het UWV. Zonder deze extensie is de aanvraag niet mogelijk.`}
			</p>
			<p>
				{`Een extensie is een applicatie (app) waarmee we ${_g(
					'de',
					'je'
				)} gegevens veilig op kunnen halen. Een extensie geeft een extra functie aan je browser. Als ${_g(
					'de',
					'je'
				)} aanvraag klaar is, wordt deze extensie automatisch van je computer verwijderd. Je hoeft hier zelf niks voor te doen.`}
			</p>
		</div>
	),
}

const PEOPLE = {
	title: 'Er is niemand ouder dan 23 jaar',
	content: <p>Vink dan elke persoon aan vanaf 18 jaar.</p>,
}

export function getDataByTextId(textId: TextId): {
	title: string
	content: JSX.Element | null
} {
	switch (textId) {
		case 'incomeSources':
			return INCOME_SOURCES
		case 'review':
			return REVIEW
		case 'home':
			return HOME
		case 'people':
			return PEOPLE
		case 'inputCustomerNumber':
			return INPUT_CUSTOMER_NUMBER
		case 'inputContractHolder':
			return INPUT_CONTRACT_HOLDER
		case 'inputAdviceAdvance':
			return INPUT_ADVICE_ADVANCE
		case 'inputActualAdvance':
			return INPUT_ACTUAL_ADVANCE
	}

	// should not come here unless developer mistake
	return {
		title: '',
		content: null,
	}
}
