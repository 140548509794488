import React from 'react'
import clsx from 'clsx'
import { TextBold } from './TextBold'
import { MarkdownText } from '../'

interface Props {
	text?: string
	classNameOverride?: string
	title?: string
	messageType?: 'success' | 'info' | 'warning' | 'error'
}

export const InfoBoxText: React.FC<Props> = ({
	classNameOverride = '',
	title,
	messageType = 'warning',
	text,
}) => {
	const hasText = typeof text !== 'undefined'
	return (
		<div
			data-testid={`info-box-text-${messageType}`}
			className={clsx(
				'w-full rounded-[1rem] px-4 pt-6 pb-2 flex items-center border-l-4',
				messageType === 'error' && 'border-red-700 bg-red-100',
				messageType === 'success' && 'border-green-700 bg-green-100',
				messageType === 'info' && 'border-indigo-blue-700 bg-[#ECEDF6]',
				messageType === 'warning' && 'border-orange-600 bg-[#FDF0E9]',
				classNameOverride
			)}
		>
			<div className="ml-4">
				{title !== undefined && (
					<TextBold classNameOverride={clsx(hasText && 'mb-1')}>
						{title}
					</TextBold>
				)}

				{hasText && <MarkdownText text={text} />}
			</div>
		</div>
	)
}
