import React from 'react'

type Props = {
	color?: string
}

export const ChevronRightIcon = ({ color }: Props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none">
		<path
			fill={color ?? '#fff'}
			fillRule="evenodd"
			d="M9.293 5.793a1 1 0 0 1 1.414 0l6 6a1 1 0 0 1 0 1.414l-6 6a1 1 0 1 1-1.414-1.414l5.293-5.293-5.293-5.293a1 1 0 0 1 0-1.414Z"
			clipRule="evenodd"
		/>
	</svg>
)
